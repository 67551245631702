import React, { ReactNode, useContext } from 'react';
import Button, { ButtonProps } from '@/components/Button';
import Text from '@/components/Text';
import User from '@/components/icons/User';
import { makeCorrectCasing } from '@/utils/format';
import actions from '@/state/actions';
import { StateContext } from '@/App';

export interface PreferenceModalTriggerProps {
  buttonText: string;
  heading: string;
  rematchFilter?: string;
  modal: ReactNode;
}

const PreferenceModalTrigger = ({
  buttonText,
  heading,
  rematchFilter,
  modal,
}: PreferenceModalTriggerProps) => {
  const { dispatch } = useContext(StateContext);
  const onClick = () => {
    dispatch(
      actions.setModal({
        children: modal,
      }),
    );
  };
  const button = (isEdit: boolean) => {
    const buttonProps: Partial<ButtonProps> = isEdit
      ? {
          variant: 'primary-flat',
        }
      : {
          sizeClasses: 'w-full mb-4',
        };

    return (
      <Button onClick={onClick} {...buttonProps}>
        {isEdit ? 'Edit' : buttonText}
      </Button>
    );
  };

  return (
    <>
      <div className="border-b-1 border-grey-200 w-full mb-4"></div>
      <div className="mb-3 flex justify-between items-center">
        <Text.P.Bold>{heading}</Text.P.Bold>
        {rematchFilter && button(true)}
      </div>
      {rematchFilter ? (
        <div className="flex gap-2 mb-4">
          <User />
          <Text.P>{makeCorrectCasing(rematchFilter)}</Text.P>
        </div>
      ) : (
        button(false)
      )}
    </>
  );
};

export default PreferenceModalTrigger;
