import Button from '@/components/Button';
import SupportLink from '@/components/SupportLink';
import Text from '@/components/Text';

const AddProviderFooter = ({
  handleAddProviderClick,
}: {
  handleAddProviderClick: () => void;
}) => {
  return (
    <div
      className={`flex flex-col fixed bottom-0 left-0 right-0 p-4 gap-3 justify-center text-center bg-white border-t border-tertiary-2 md:hidden`}
    >
      <Text.Small>
        For additional assistance contact <SupportLink />
      </Text.Small>
      <Button customClasses="w-full" onClick={handleAddProviderClick}>
        + Add provider
      </Button>
    </div>
  );
};

export default AddProviderFooter;
