import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import actions from '@/state/actions';
import Text from '@/components/Text';
import { useContext, useEffect } from 'react';
import { StateContext } from '@/App';
import useData from '@/state/use-data';
import { getCareTypes } from '@/utils/provider';
import EmptyStatePage from './EmptyStatePage';
import trackEvent from '@/utils/amplitude';
import { AnalyticsEvent, TherapyType } from '@/utils/types';
import {
  therapyTypes as allTherapyTypes,
  kaiserHawaiiLink,
  kaiserSoCalLink,
} from '@/utils/constants';
import CareTeamTab from './care-team/CareTeamTab';
import Tab from '@/components/Tab';
import Button from '@/components/Button';
import Plus from '@/components/icons/Plus';
import InfoCard from '@/components/InfoCard';
import AddCareTypeModal from './care-team/AddCareTypeModal';
import SupportLink from '@/components/SupportLink';
import { isKaiserPayerExcluded } from '@/utils/tools';
import AddProviderFooter from '@/components/AddProviderFooter';
import { isBookingAllowed } from '@/utils/use-dns-flag';

type TabValue = 'care-team' | 'past-providers';
type AddableType = TherapyType | 'any';

const EmptyStateMessage = ({ activeTab }: { activeTab: TabValue }) => {
  let body: React.ReactNode;
  if (activeTab === 'care-team') {
    body = 'Click "Add provider" to add members to your care team';
  } else {
    body =
      'Members of your care team will show up here when you end care with them';
  }
  return <Text.P className="text-grey-800 max-w-[580px]">{body}</Text.P>;
};

const CareTeamV2 = () => {
  const { dispatch } = useContext(StateContext);

  const { WithData, data } = useData([
    'careTeam_v3',
    'insurance',
    'patientData',
    'appointments_v2',
    'profile',
  ]);

  const params = new URLSearchParams(useLocation().search);

  return (
    <WithData data={data}>
      {({
        careTeam_v3: careTeam,
        patientData,
        profile: { birthdate },
        insurance,
      }) => {
        const { location } = patientData;

        const { availableCareTypes, careTeamTypesArray, hidePsych } =
          getCareTypes({
            patientState: location,
            birthdate,
            insurance,
            careTeam,
          });

        const [activeTab, setActiveTab] = useState<TabValue>('care-team');
        const [addCareToolTipOpen, setAddCareToolTipOpen] = useState(false);

        // Commenting this out because we're commenting out the ShowAppointmentLocationModal for now
        // const { isAllowed } = useFlagCheck();
        // const inPersonEnabled = isAllowed({
        //   patientPortalReleaseInPersonVisits: true,
        // });

        const canBook = isBookingAllowed();

        useEffect(() => {
          const careTeamViewEvent: AnalyticsEvent = {
            product_area: 'CareTeam',
            name: 'page_view',
            trigger: 'Page load',
            metadata: {
              dns: !canBook,
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };
          trackEvent(careTeamViewEvent);

          // deeplink launch add care type flow only if patient can book
          const careTypeToAdd = params.get('addCare') as AddableType;
          if (
            careTypeToAdd &&
            [...allTherapyTypes, 'any'].includes(careTypeToAdd)
          ) {
            // if the patient can't book do not load modal
            if (!canBook) {
              trackEvent({
                product_area: 'CareTeam',
                name: 'add_care_modal_blocked',
                trigger: 'Interaction',
                metadata: {
                  reason: 'dns',
                  is_deeplink: true,
                },
              });
              return;
            }
            const isUnallowedPsych =
              careTypeToAdd === 'psychiatric' && hidePsych;
            const alreadyActiveCareType = !availableCareTypes.includes(
              careTypeToAdd as TherapyType,
            );
            const preSelected =
              isUnallowedPsych ||
              careTypeToAdd === 'any' ||
              alreadyActiveCareType
                ? undefined
                : careTypeToAdd;
            onAddCareTypeClick(preSelected);
          }
        }, []);

        const handleChangeTab = (value: TabValue) => {
          setActiveTab(value);
        };

        const onAddCareNextClick = (careType: TherapyType) => {
          const nextButtonClicked: AnalyticsEvent = {
            product_area: 'AddCareTeamModal',
            name: 'next_button_click',
            trigger: 'Interaction',
            metadata: {
              selected_care_type: careType,
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };

          trackEvent(nextButtonClicked);
        };

        const showAndTrackAddCareModal = (preSelected?: TherapyType) => {
          const providerRemoved = params.get('providerRemoved');
          dispatch(
            actions.setModal({
              children: (
                <AddCareTypeModal
                  existingCareTypes={careTeamTypesArray}
                  availableCareTypes={availableCareTypes}
                  preSelected={preSelected}
                  hidePsych={hidePsych}
                  providerPreviouslyRemoved={providerRemoved === 'true'}
                  trackNextClick={onAddCareNextClick}
                />
              ),
            }),
          );

          const addCareModalViewed: AnalyticsEvent = {
            product_area: 'CareTeam',
            name: 'add_care_modal_viewed',
            trigger: 'Interaction',
            metadata: {
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };
          if (preSelected) {
            addCareModalViewed.metadata = {
              ...addCareModalViewed.metadata,
              selected_care_types: [preSelected],
              is_deeplink: true,
            };
          }
          trackEvent(addCareModalViewed);
        };

        const onAddCareTypeClick = (preSelected?: TherapyType) => {
          // Commenting out the below logic for now, but leaving it here assuming we're likely to add it back soon
          // this logic may become more complex, if not, can remove and just use inPersonEnabled
          // const showAppointmentLocationModal = inPersonEnabled;
          // if (showAppointmentLocationModal) {
          //   dispatch(
          //     actions.setModal({
          //       children: (
          //         <ChooseAppointmentLocationModal
          //           onTelemedicineSelect={() => {
          //             showAndTrackAddCareModal(preSelected);
          //           }}
          //           appointmentLocationPath="add_care"
          //         />
          //       ),
          //     }),
          //   );
          //   return;
          // }
          showAndTrackAddCareModal(preSelected);
        };

        const onClickPastProviders = () => {
          setActiveTab('past-providers');
          firePastProvidersViewEvent();
        };

        const firePastProvidersViewEvent = () => {
          const viewPastProvidersEvent: AnalyticsEvent = {
            product_area: 'CareTeam',
            name: 'past_providers_view',
            trigger: 'Interaction',
            metadata: {
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };
          trackEvent(viewPastProvidersEvent);
        };

        const showActiveProviders = activeTab === 'care-team';
        const disableAddCareType =
          !canBook ||
          availableCareTypes.length === 0 ||
          (isKaiserPayerExcluded(insurance) &&
            careTeamTypesArray.includes('psychiatric'));
        const noCareTeam = !careTeam.active || careTeam.active.length === 0;
        const noInactiveCareTeam =
          !careTeam.inactive || careTeam.inactive.length === 0;

        const getInfoCard = () => {
          if (!canBook) {
            return (
              <InfoCard>
                Your account has been restricted from scheduling appointments.{' '}
                Contact <SupportLink />
              </InfoCard>
            );
          }
          if (
            isKaiserPayerExcluded(insurance) &&
            careTeamTypesArray.includes('psychiatric')
          ) {
            return (
              <InfoCard>
                You have already added psychiatry. To add therapy care types,
                please contact Kaiser Permanente{' '}
                <a
                  className="text-sky-50 no-underline cursor-pointer"
                  href={kaiserSoCalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Southern California
                </a>{' '}
                or{' '}
                <a
                  className="text-sky-50 no-underline cursor-pointer"
                  href={kaiserHawaiiLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hawaii
                </a>{' '}
                for a therapy referral to Rula. Once you have an active
                referral, we will reach out to get you scheduled with a
                therapist.
              </InfoCard>
            );
          }
          return (
            <InfoCard>
              You have already added all care types. Replace an existing
              provider for the care type you’re looking to change, or reach out
              to <SupportLink />
            </InfoCard>
          );
        };

        return (
          <div className="max-w-[715px]">
            <div className="mb-7">
              <Text.H1 className="mb-2">Care Team</Text.H1>
            </div>
            <div className="flex flex-col mb-4 md:mb-8 md:flex-row md:justify-between md:items-center">
              <div className="flex gap-x-5 mb-2 md:mb-0">
                <Tab
                  tab={{ name: 'Current providers', value: 'care-team' }}
                  onClick={handleChangeTab}
                  isActive={showActiveProviders}
                />
                <Tab
                  tab={{ name: 'Past providers', value: 'past-providers' }}
                  onClick={onClickPastProviders}
                  isActive={!showActiveProviders}
                />
              </div>

              <div
                className="relative"
                onMouseEnter={
                  disableAddCareType
                    ? () => setAddCareToolTipOpen(true)
                    : undefined
                }
                onMouseLeave={
                  disableAddCareType
                    ? () => setAddCareToolTipOpen(false)
                    : undefined
                }
              >
                <Button
                  customClasses="hidden md:[display:unset]"
                  variant="primary-outline"
                  state={disableAddCareType ? 'disabled' : ''}
                  width="auto"
                  size="small"
                  renderIcon={(props) => (
                    <Plus {...props} viewBox="0 0 16 16" />
                  )}
                  onClick={() => onAddCareTypeClick()}
                >
                  Add provider
                </Button>
                {addCareToolTipOpen ? (
                  <div className="absolute z-10 w-auto left-[0px] top-[45px] md:left-[-110px] md:w-[356px]">
                    {getInfoCard()}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-between pb-[200px] md:pb-[unset]">
              {/* Extra padding is so that the floating footer doesn't cover content */}
              {(noCareTeam && showActiveProviders) ||
              (noInactiveCareTeam && !showActiveProviders) ? (
                <div className="space-y-4 basis-full">
                  <EmptyStatePage
                    header={
                      showActiveProviders
                        ? 'No current care team'
                        : 'No past providers'
                    }
                    message={<EmptyStateMessage activeTab={activeTab} />}
                  />
                </div>
              ) : (
                <CareTeamTab
                  providers={
                    showActiveProviders ? careTeam.active : careTeam.inactive
                  }
                  active={showActiveProviders}
                />
              )}
            </div>
            <AddProviderFooter
              handleAddProviderClick={() => onAddCareTypeClick()}
            />
          </div>
        );
      }}
    </WithData>
  );
};

export default CareTeamV2;
