export interface ModalProps {
  children: JSX.IntrinsicElements['div']['children'];
}

const Modal = ({ children }: ModalProps) => {
  return <div>{children}</div>;
};

export const ModalBackdrop = ({
  children,
}: {
  children: JSX.IntrinsicElements['div']['children'];
}) => (
  <div className="w-full h-full bg-grey-800 bg-opacity-50 flex items-center justify-center">
    {children}
  </div>
);

Modal.Backdrop = ModalBackdrop;

export default Modal;
