import X from '@/components/icons/X';
import Text from '@/components/Text';

export type CostModalProps = {
  onClose: () => unknown;
  title: string | undefined;
  cashPayAmount: string | undefined;
};

const CostModal = ({ onClose, title, cashPayAmount }: CostModalProps) => {
  return (
    <div className="rounded p-6 space-y-6 bg-white w-[520px]">
      <div className="flex justify-between">
        <Text.H4>{title}</Text.H4>
        <X onClick={onClose} className="cursor-pointer"></X>
      </div>
      <div className="space-y-3">
        <div className="flex flex-col">
          <Text.Small>
            You only pay for any amount that isn’t covered by your insurance–we
            don't charge any fees for using Rula. Most people who have insurance
            pay between $20-50. Without insurance coverage, the cost of a
            session is {cashPayAmount}.
          </Text.Small>
          <Text.Small className="pt-2">
            We'll contact your insurer and follow up with precise cost estimate
            within 48 hours after you've booked
          </Text.Small>
        </div>
      </div>
    </div>
  );
};

export default CostModal;
