import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  isProviderDetail,
  JSONObject,
  PatientData,
  TherapyType,
  ActiveProviderShapeV3,
  JSONValue,
  WillingToSee,
} from '@/utils/types';
import { getAgeFromBirthdate } from '@/utils/dates';
import RedirectToCareTeamOnErrorV2 from '@/pages/care-team/RedirectToCareTeamOnErrorV2';
import useData from '@/state/use-data';
import { WithProvider } from '@/pages/care-team/WithProvider';
import Text from '@/components/Text';
import { ProviderLockup } from '@/pages/care-team/ProviderLockup';
import WithPageState from '@/pages/WithPageState';
import { default as CalendarIcon } from '@/components/icons/Calendar';
import dayjs from 'dayjs';
import Clock from '@/components/icons/Clock';
import Video from '@/components/icons/Video';
import Button from '@/components/Button';
import WithProviderFromCareTeamV2 from '@/pages/care-team/WithProviderFromCareTeamV2';
import fetch from '@/utils/fetch';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { StateContext } from '@/App';
import actions, { Action } from '@/state/actions';
import SupportLink from '@/components/SupportLink';
import Checkbox from '@/components/Checkbox';
import { ToastProps } from '@/components/Toast';
import Dollar from '@/components/icons/Dollar';
import { makeDollars } from '@/utils/format';
import PaymentText from '@/components/appointments/PaymentText';
import {
  therapyTypeToVisitType,
  errorsCancelingHold,
  errorCreatingHold,
  errorsFindingOldAppointment,
  errorSlotNotAvailable,
  psychiatryProviderRole,
  toastSessionNotAvailable,
  toastDidntWorkTryAgain,
  toastErrorBookingSession,
  errorNotWillingToSee,
  therapyTypeHeader,
  getWillingToSeeFromTherapyType,
} from '@/utils/constants';
import FullWidthHeader from '@/components/FullWidthHeader';
import trackEvent from '@/utils/amplitude';
import { getNextActiveAppointmentV2 } from '@/utils/dates';
import TherapyTypeText from '@/components/appointments/TherapyTypeText';

const SHARE_HISTORY_LINK = 'https://www.rula.com/med-history-consent/';

interface RematchRequestBody extends JSONObject {
  therapy_type: TherapyType;
  patient_record_uuid: string;
  new_provider_npi: number;
  slot: number;
}

const onConfirm = async (args: {
  therapyType: TherapyType;
  dispatch: Dispatch<Action>;
  navigate: ReturnType<typeof useNavigate>;
  setProcessing: (processing: boolean) => unknown;
  providerName: string;
  patientData: PatientData;
  slot: number;
  newNpi: number;
  willingToSee: WillingToSee;
}) => {
  const {
    therapyType,
    dispatch,
    navigate,
    setProcessing,
    providerName,
    patientData,
    slot,
    newNpi,
    willingToSee,
  } = args;
  const { patient_record_uuid } = patientData;
  const body: RematchRequestBody = {
    therapy_type: therapyType,
    patient_record_uuid,
    new_provider_npi: newNpi,
    slot,
    willing_to_see: willingToSee,
  };

  const therapyHeader = therapyTypeHeader[therapyType];
  const visitType = therapyTypeToVisitType[therapyType];

  const request = fetch.json('/api/add_provider', {
    method: 'POST',
    body,
  });
  setProcessing(true);
  try {
    await request;
  } catch (r) {
    let errorMessage = therapyHeader ? (
      <>
        We are unable to book your {therapyHeader.toLowerCase()} at this time.{' '}
        Please contact us at <SupportLink />.
      </>
    ) : (
      <>An error occurred.</>
    );
    let destinationRoute = '/care-team';
    let variant: ToastProps['variant'] = 'warning';
    const base = `/care-team/${therapyType}/add-new-care-type`;

    const errorResponse = r as Response;

    // in case r is ever something other than a Response
    if (errorResponse?.status) {
      const { message } = await errorResponse.json();
      if (
        errorsFindingOldAppointment.find((errorText) =>
          message.includes(errorText),
        )
      ) {
        errorMessage = <>{toastDidntWorkTryAgain}</>;
        destinationRoute = '/care-team';
      } else if (message.includes(errorSlotNotAvailable)) {
        errorMessage = <>{toastSessionNotAvailable}</>;
        destinationRoute = `${base}/${body.new_provider_npi}/choose-appointment`;
      } else if (message.includes(errorNotWillingToSee)) {
        errorMessage = (
          <>
            {providerName} does not provide {therapyType} therapy. Please choose
            a different provider.
          </>
        );
        destinationRoute = `${base}/find-new-provider`;
      } else if (message.includes(errorCreatingHold)) {
        errorMessage = <>{toastErrorBookingSession}</>;
        destinationRoute = `${base}/${body.new_provider_npi}/confirm-appointment/${body.slot}`;
      } else if (
        errorsCancelingHold.find((errorText) => message.includes(errorText))
      ) {
        errorMessage = (
          <>
            Your provider has been added.
            <br />
            Your new provider may not appear on the care team page for a short
            time. Please contact <SupportLink /> if you have any questions.
          </>
        );
        destinationRoute = '/care-team';
        variant = 'info';
      }
    }

    // Reload appt, event, and provider info in case it was stale before or the state changed during the PPB call
    dispatch(
      actions.async.setLoading({
        key: 'appointments_v2',
        loadingState: 'needed',
      }),
    );
    dispatch(
      actions.async.setLoading({
        key: 'careTeam_v3',
        loadingState: 'needed',
      }),
    );

    navigate(destinationRoute);

    dispatch(
      actions.setToast({
        text: <Text.P>{errorMessage}</Text.P>,
        variant: variant,
        onClose: () => dispatch(actions.setToast(null)),
      }),
    );
    return;
  } finally {
    setProcessing(false);
  }

  // If rematch was successful, reload appt, event, and provider info so the care team page will be accurate
  // todo, what do we actually have to refresh
  dispatch(
    actions.async.setLoading({
      key: 'appointments_v2',
      loadingState: 'needed',
    }),
  );
  dispatch(
    actions.async.setLoading({
      key: 'careTeam_v3',
      loadingState: 'needed',
    }),
  );
  dispatch(
    actions.setToast({
      text: (
        <Text.P>
          <Text.P.Inline.Bold>Success! </Text.P.Inline.Bold>You've added{' '}
          {visitType.toLowerCase()}. Your provider appointment will be added
          shortly.
        </Text.P>
      ),
      variant: 'success',
      onClose: () => dispatch(actions.setToast(null)),
    }),
  );
  const metadata: { [x: string]: JSONValue } = {
    proposed_provider_npi: newNpi,
    proposed_appointment_time_local: dayjs.unix(slot).format(),
    provider_npi: newNpi.toString(),
    appointment_type: therapyType,
  };

  trackEvent({
    product_area: 'AddCareTeamConfirmationPage',
    name: 'conversion',
    trigger: 'Interaction',
    metadata,
  });
  navigate('/care-team');
};

export default function ConfirmAppoinmentV2() {
  const {
    appointment,
    providerNpi: currentProviderNpi,
    newNpi,
    therapyType,
  } = useParams<{
    appointment: string;
    providerNpi: string;
    newNpi: string;
    therapyType: TherapyType;
  }>();
  const location = useLocation();
  const maybeProvider = isProviderDetail(location.state?.provider)
    ? location.state.provider
    : null;
  const { data, WithData } = useData([
    'patientData',
    'insurance',
    'appointments_v2',
    'selfPayRates',
    'profile',
  ]);
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const [processing, setProcessing] = useState(false);
  const [checked, setChecked] = useState(false);

  const getButtonState = () => {
    if (processing) {
      return 'waiting';
    }
    if (therapyType === 'psychiatric' && !checked) {
      return 'disabled';
    }
    return '';
  };

  if (!newNpi || !appointment) {
    return <RedirectToCareTeamOnErrorV2 />;
  }

  return (
    <WithProviderFromCareTeamV2 providerNpi={currentProviderNpi}>
      {({ provider: current }) => {
        const currentCareProvider = current as ActiveProviderShapeV3;
        if (!therapyType) {
          return <RedirectToCareTeamOnErrorV2 />;
        }

        return (
          <WithData data={data}>
            {({
              patientData,
              insurance,
              appointments_v2: appointments,
              selfPayRates,
              profile,
            }) => (
              <WithProvider
                passthrough={maybeProvider}
                providerNpi={newNpi}
                patientRecordUuid={patientData.patient_record_uuid}
              >
                {(provider) => (
                  <WithPageState page="care-team">
                    {({ pageState }) => {
                      const { carrier, payment_method, network_name } =
                        insurance;
                      const { insurances, role } = provider;
                      const slot = Number(appointment);

                      const getCashPayNumber = () => {
                        if (role === psychiatryProviderRole) {
                          return selfPayRates.data.psych_initial;
                        }
                        return therapyType === 'individual'
                          ? selfPayRates.data.individual
                          : selfPayRates.data.couples_family;
                      };

                      const cashPayAmount = makeDollars(getCashPayNumber());

                      const appointmentType =
                        therapyType && therapyTypeToVisitType[therapyType];

                      const age = getAgeFromBirthdate(profile.birthdate);
                      useEffect(() => {
                        const originalAppointmentWithProvider =
                          currentCareProvider &&
                          getNextActiveAppointmentV2(
                            appointments.rows,
                            currentCareProvider.npi,
                          );

                        const metadata: { [x: string]: JSONValue } = {
                          proposed_provider_npi: newNpi,
                          original_appointment_time_local:
                            originalAppointmentWithProvider?.start_time ?? null,
                          proposed_appointment_time_local: dayjs
                            .unix(slot)
                            .format(),
                          insurance_carrier:
                            carrier && payment_method === 'Health Insurance'
                              ? carrier
                              : 'self',
                          provider_npi: newNpi.toString(),
                        };

                        trackEvent({
                          product_area: 'AddCareTeamConfirmationPage',
                          name: 'page_view',
                          trigger: 'Page load',
                          metadata,
                        });
                      }, []);

                      return (
                        <div className="space-y-10">
                          <FullWidthHeader>
                            Confirm your appointment
                          </FullWidthHeader>
                          <div className="lg:flex relative justify-center items-start gap-x-6 text-left">
                            <div className="max-w-3xl space-y-5">
                              <div className="border border-grey-200 rounded px-6 pt-4 pb-8 w-full">
                                <ProviderLockup
                                  pageState={pageState}
                                  provider={provider}
                                />
                                <div className="flex flex-col md:flex-row md:items-center justify-start md:space-x-6 space-y-4 md:space-y-0 pt-4 pb-4 border-t border-b">
                                  <div className="flex items-center space-x-2">
                                    <CalendarIcon className="grow-0 shrink-0" />
                                    <Text.P>
                                      {dayjs
                                        .unix(Number(appointment))
                                        .format('ddd MMM D [at] h:mmA')}
                                    </Text.P>
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <Clock className="grow-0 shrink-0" />
                                    <Text.P>60 minutes</Text.P>
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <Video className="grow-0 shrink-0" />
                                    <Text.P>Video session</Text.P>
                                  </div>
                                </div>
                                <div className="pt-4 justify-center items-center space-y-4">
                                  <div className="flex gap-2">
                                    <TherapyTypeText
                                      therapyType={therapyType}
                                      appointmentType={appointmentType}
                                      isFirstAppointment={true}
                                    />
                                  </div>
                                  <div className="flex gap-2">
                                    <Dollar className="grow-0 shrink-0" />
                                    <PaymentText
                                      paymentMethod={payment_method}
                                      therapyType={therapyType}
                                      cashPayAmount={cashPayAmount}
                                      insurances={insurances}
                                      networkName={network_name}
                                      carrier={carrier}
                                    />
                                  </div>
                                </div>
                                {therapyType === 'psychiatric' ? (
                                  <div className="pt-4 border-t mt-4">
                                    <div className="flex">
                                      <Checkbox
                                        checked={checked}
                                        onCheck={() => setChecked(true)}
                                        onUncheck={() => setChecked(false)}
                                        label=""
                                      />
                                      <div>
                                        I agree to{' '}
                                        <a
                                          href={SHARE_HISTORY_LINK}
                                          className="text-sky-50 no-underline"
                                          target="_blank"
                                        >
                                          Rula’s consent to obtain and share
                                          patient medication history
                                        </a>
                                      </div>
                                    </div>
                                    <Text.P className="mt-1 text-[14px]">
                                      This consent allows Rula to obtain and
                                      share your medication history from
                                      pharmacies, health plans, and other
                                      healthcare providers to help your provider
                                      effectively treat your symptoms and avoid
                                      potentially dangerous drug interactions.
                                    </Text.P>
                                  </div>
                                ) : null}
                              </div>
                              <div className="w-full">
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    onConfirm({
                                      slot,
                                      newNpi: Number(newNpi),
                                      therapyType,
                                      patientData,
                                      dispatch,
                                      navigate,
                                      setProcessing,
                                      providerName:
                                        provider.first_name +
                                        ' ' +
                                        provider.last_name,
                                      willingToSee:
                                        getWillingToSeeFromTherapyType(
                                          therapyType,
                                          age,
                                        ),
                                    })
                                  }
                                  state={getButtonState()}
                                >
                                  Book appointment
                                </Button>
                              </div>
                              <div className="items-center text-center">
                                <Text.P>
                                  New members of your Care Team will have access{' '}
                                  to your medical record at Rula in order to{' '}
                                  provide you with informed, coordinated care.
                                </Text.P>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </WithPageState>
                )}
              </WithProvider>
            )}
          </WithData>
        );
      }}
    </WithProviderFromCareTeamV2>
  );
}
