import Text from './Text';
export interface CheckboxProps {
  checked?: boolean;
  onCheck: () => unknown;
  onUncheck: () => unknown;
  label: string;
}

const Check = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    data-testid="check-svg"
  >
    <path d="M1 4.6L3.82353 7L9 1" stroke="white" strokeWidth="1.5" />
  </svg>
);

const Checkbox = ({ checked, onCheck, onUncheck, label }: CheckboxProps) => {
  return (
    <div
      className="flex gap-[10px] items-center cursor-pointer"
      onClick={checked ? onUncheck : onCheck}
    >
      <div
        className={`w-[18px] h-[18px] rounded-sm flex justify-center items-center border-1 ${
          checked ? 'bg-sky-60 border-sky-60' : 'bg-white border-grey-400'
        }`}
      >
        {checked && <Check />}
      </div>
      <Text.P>{label}</Text.P>
    </div>
  );
};

export default Checkbox;
