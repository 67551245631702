import { Link } from 'react-router-dom';
import WithPageState from '../WithPageState';
import { ProviderLockupV2 } from '@/pages/care-team/ProviderLockupV2';
import { ProviderWithFirstAppointmentInfo, TherapyType } from '@/utils/types';

export interface ProviderCardV2Props {
  provider: ProviderWithFirstAppointmentInfo;
  active: boolean;
  therapyType: TherapyType;
}
const ProviderCardV2 = ({
  provider,
  active,
  therapyType,
}: ProviderCardV2Props) => {
  return (
    <WithPageState page="care-team">
      {({ pageState }) => {
        const { npi } = provider;

        const canBook = 'is_confirmed' in provider && provider.is_confirmed;

        return (
          <div
            className="
              py-3
              border-grey-200 border-b last:border-b-0
              md:p-4
              md:border-1 md:last:border-b-1 md:rounded
              md:min-w-[485px]
            "
          >
            <Link to={`/care-team/${npi}`} className="no-underline">
              <ProviderLockupV2
                pageState={pageState}
                provider={provider}
                active={active}
                therapyType={therapyType}
                canBook={canBook}
              />
            </Link>
          </div>
        );
      }}
    </WithPageState>
  );
};

export default ProviderCardV2;
