type Tab = 'telehealth' | 'in-person';
interface InPersonTabsProps {
  tab: Tab;
  onTabChange: (tab: Tab) => void;
}

export const InPersonTabs = (props: InPersonTabsProps) => {
  const { tab, onTabChange } = props;

  return (
    <div className="flex gap-5 mt-2">
      {(['telehealth', 'in-person'] as Tab[]).map((tabName) => (
        <div
          key={tabName}
          className={`pb-3 font-medium text-base ${
            tab === tabName
              ? 'text-grey-800 border-b-4 border-b-grey-800'
              : 'text-grey-800'
          } cursor-pointer`}
          onClick={() => onTabChange(tabName)}
        >
          {tabName === 'telehealth' ? 'Telehealth' : 'In-person'}
        </div>
      ))}
    </div>
  );
};
