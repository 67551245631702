import { useState, useContext } from 'react';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import Text from '@/components/Text';
import { StateContext } from '@/App';
import actions from '@/state/actions';

export enum ProfileTypeOption {
  SelfAccess = 'SELF_ACCESS',
  GuardianAccess = 'GUARDIAN_ACCESS',
}

const ChooseAddProfileTypeModal = ({
  onConfirm,
}: {
  onConfirm: (selected: ProfileTypeOption) => void;
}) => {
  const [selected, setSelected] = useState<ProfileTypeOption | ''>('');
  const { dispatch } = useContext(StateContext);

  const closeModal = () => {
    dispatch(actions.setModal(null));
  };

  const preventDefaultAndStopPropagation = (
    e: React.MouseEvent | React.FocusEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onContinueClick = (e: React.MouseEvent) => {
    preventDefaultAndStopPropagation(e);
    if (!selected) return;
    closeModal();
    onConfirm(selected);
  };

  const onSelect = (e: React.MouseEvent, id: ProfileTypeOption) => {
    preventDefaultAndStopPropagation(e);
    setSelected(id);
  };

  const onFocus = (e: React.FocusEvent, id: ProfileTypeOption) => {
    preventDefaultAndStopPropagation(e);
    setSelected(id);
  };

  const buttons: {
    id: ProfileTypeOption;
    name: string;
    description: string;
  }[] = [
    {
      id: ProfileTypeOption.SelfAccess,
      name: 'Personal',
      description: 'Your personal Rula account used to manage your own care.',
    },
    {
      id: ProfileTypeOption.GuardianAccess,
      name: 'Minor / Dependent',
      description:
        'A patient under the age of 18, or adult with medical guardianship.',
    },
  ];

  return (
    <div
      onClick={() => setSelected('')}
      className="flex flex-col rounded p-6 space-y-6 bg-white md:w-[650px]"
    >
      <div className="flex justify-between">
        <Text.H4>What profile type would you like to add?</Text.H4>
        <X onClick={closeModal} className="cursor-pointer"></X>
      </div>

      <Text.P className="text-grey-800 text-left">
        Please choose profile type.
      </Text.P>

      <div className="flex justify-center items-center space-x-5">
        {buttons.map(({ id, name, description }) => (
          <div
            key={id}
            className="flex flex-col justify-center items-center w-full space-y-4 rounded-lg p-8 border-grey-200 border-1 group hover:border-sky-50 hover:bg-sky-10 hover:text-sky-50 focus:border-sky-50 focus:bg-sky-10 focus:text-sky-50 focus:border-1"
            role="button"
            onClick={(e) => onSelect(e, id)}
            onFocus={(e) => onFocus(e, id)}
            tabIndex={0}
          >
            <Text.P.Bold>{name}</Text.P.Bold>
            <Text.Small className="text-grey-500">{description}</Text.Small>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full">
        <Button
          onClick={(e) => onContinueClick(e)}
          variant="primary"
          state={!selected ? 'disabled' : undefined}
          sizeClasses="w-full md:w-[210px]"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ChooseAddProfileTypeModal;
