import tw from 'tailwind-styled-components';

const Loader =
  tw.span`w-full h-full border-sky-50 border-[5px] border-b-transparent rounded-full box-border inline-block animate-spin` as any;

Loader.Container = tw.div`w-full h-full flex items-center justify-center`;

Loader.FullScreen = () => {
  return (
    <Loader.Container className="w-screen h-screen fixed top-0 left-0">
      <Loader className="w-12 h-12" />
    </Loader.Container>
  );
};
Loader.Contained = () => {
  return (
    <Loader.Container className="w-full min-h-[425px] absolute top-0 left-0 bottom-0">
      <Loader className="w-12 h-12" />
    </Loader.Container>
  );
};
export default Loader;
