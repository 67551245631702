import Dropdown from '@/components/Dropdown';
import Email from '@/components/icons/Email';
import Phone from '@/components/icons/Phone';
import Pin from '@/components/icons/Pin';
import User from '@/components/icons/User';
import TextInput from '@/components/TextInput';
import Text from '@/components/Text';
import actions from '@/state/actions';
import {
  AuthenticatedState,
  ExtractEditing,
  isEditingProfile,
  JSONValue,
  PatientData,
  ProfileDetails,
  ProfilePageState,
} from '@/utils/types';
import EditSection from './EditSection';
import ReadSection from './ReadSection';
import { useContext, useId } from 'react';
import { MobileContext, StateContext } from '@/App';
import fetch from '@/utils/fetch';
import setDefaults from '@/utils/set-defaults';
import useValidation, {
  makeErrorState,
  ValidationError,
} from '@/utils/use-validation';
import {
  phone as formatPhone,
  makeCorrectCasing,
  formatAddress,
} from '@/utils/format';
import InfoCard from '@/components/InfoCard';
import InfoCircle from '@/components/icons/InfoCircle';
import stopPropagation from '@/utils/stop-propagation';
import { usStateAbbreviations } from '@/utils/constants';
import SupportLink from '@/components/SupportLink';
import Pen from '@/components/icons/Pen';
import trackEvent from '@/utils/amplitude';

export interface DetailsProps {
  details: Extract<
    AuthenticatedState['data']['profile'],
    { loadingState: 'done' }
  >;
  patientData: PatientData;
  pageState: ProfilePageState;
}

const Read = ({
  first_name,
  last_name,
  preferred_name,
  email,
  phone,
  address,
}: ProfileDetails) => {
  const { dispatch } = useContext(StateContext);

  return (
    <ReadSection
      label="Personal details"
      onEditClick={() => dispatch(actions.profile.setDetailsEditMode('edit'))}
    >
      <ReadSection.Row
        Icon={User}
        data={`${makeCorrectCasing(first_name)} ${makeCorrectCasing(
          last_name,
        )}`}
      />
      {preferred_name ? (
        <ReadSection.Row Icon={Pen} data={makeCorrectCasing(preferred_name)} />
      ) : null}
      <ReadSection.Row Icon={Email} data={email?.toLowerCase()} />
      <ReadSection.Row Icon={Phone} data={formatPhone(phone)} />
      <ReadSection.Row Icon={Pin} data={formatAddress(address)} />
    </ReadSection>
  );
};

const Edit = ({
  editState,
  first_name,
  last_name,
  email,
  patientData,
  pageState,
}: ExtractEditing<DetailsProps['details']> & {
  patientData: PatientData;
  pageState: ProfilePageState;
}) => {
  const { dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);

  const { edited, invalid, persistence } = editState;
  const { phone, address, preferred_name } = edited;
  const rawPhone = phone.match(/\d/g)?.join('');

  const validation = useValidation(() =>
    dispatch(actions.profile.setInvalid({ key: 'profile', invalid: false })),
  )(() => {
    const errors: ValidationError[] = [];
    if (!rawPhone || rawPhone.length !== 10) {
      errors.push({
        keys: ['phone'],
        message: 'Please provide a valid phone number',
      });
    }
    const missingAddressFields = ['street', 'city', 'state', 'zip'].filter(
      (f) => !address[f],
    );
    if (missingAddressFields.length) {
      errors.push({
        keys: missingAddressFields,
        message: 'Please provide a valid address',
      });
    }
    if (!/^\d{5}$/.test(address.zip)) {
      errors.push({
        keys: ['zip'],
        message: 'Please provide a valid 5 digit zip code',
      });
    }
    return errors;
  }, [phone, address]);

  const saveProfileDetails = () => {
    () => dispatch(actions.setToast(null)); // clear any existing toasts
    if (validation.length) {
      dispatch(actions.profile.setInvalid({ key: 'profile', invalid: true }));
      return false;
    }
    dispatch(actions.profile.setDetailsPersistence('saving'));
    return fetch
      .json('/api/update_patient_details', {
        method: 'PUT',
        body: {
          patientIdentifiers: {
            patient_uuid: patientData.patient_uuid,
          },
          payload: {
            address: setDefaults(address, '') as JSONValue,
            phone: rawPhone!,
            preferred_name,
          },
        },
      })
      .then(() => {
        trackEvent({
          product_area: 'Profile',
          name: 'patient_details_updated',
          trigger: 'Interaction',
        });
        dispatch(actions.profile.setDetailsPersisted());
      })
      .catch(() => {
        dispatch(
          actions.setToast({
            text: (
              <Text.P>
                We're sorry, but that didn't work. Please try again.
              </Text.P>
            ),
            variant: 'warning',
            onClose: () => dispatch(actions.setToast(null)),
          }),
        );
        dispatch(actions.profile.setDetailsPersistence('error'));
        return Promise.reject();
      });
  };

  const errorState = makeErrorState(!!invalid, validation);
  const dropdownId = `dropdown-${useId()}`;
  const phoneInputId = `input-${useId()}`;
  const streetAddressInputId = `input-${useId()}`;
  const aptInputId = `input-${useId()}`;
  const cityInputId = `input-${useId()}`;
  const zipcodeInputId = `input-${useId()}`;
  const preferredNameInputId = `input-${useId()}`;

  return (
    <EditSection
      validationErrors={validation.map(({ message }) => message)}
      invalid={!!invalid}
      onSave={saveProfileDetails}
      onCancel={() => dispatch(actions.profile.setDetailsEditMode('read'))}
      label="Personal details"
      saving={persistence === 'saving'}
    >
      <div className="flex flex-wrap md:gap-x-2 gap-y-4">
        {/* flex basis needs to subtract the gap which is 8px */}
        <div className="basis-full space-y-1 w-full">
          <div className="flex flex-wrap lg:flex-nowrap items-center w-full gap-1 relative">
            <div className="flex gap-1 items-center">
              <Text.P>Name</Text.P>
              <div className="w-5 h-5 cursor-pointer">
                <InfoCircle.Small
                  onClick={stopPropagation(() =>
                    dispatch(
                      actions.profile.setNameTooltipOpen(
                        !pageState.nameTooltipOpen,
                      ),
                    ),
                  )}
                  onMouseEnter={
                    mobile
                      ? undefined
                      : () => dispatch(actions.profile.setNameTooltipOpen(true))
                  }
                />
              </div>
            </div>
            {pageState.nameTooltipOpen && (
              <div
                onMouseLeave={
                  mobile
                    ? undefined
                    : () => dispatch(actions.profile.setNameTooltipOpen(false))
                }
                className="flex-grow lg:absolute lg:left-[75px] lg:w-max mb-2 lg:mb-0 items-center"
              >
                <InfoCard>
                  We require your legal name for insurance purposes. This cannot
                  be edited. If this is not your legal name, please contact{' '}
                  <SupportLink /> to update it.
                </InfoCard>
              </div>
            )}
          </div>
          <TextInput
            value={`${makeCorrectCasing(first_name)} ${makeCorrectCasing(
              last_name,
            )}`}
            state="disabled"
          />
        </div>
        <div className="basis-full space-y-1">
          <div className="flex flex-wrap lg:flex-nowrap items-center w-full gap-1 relative">
            <div className="flex gap-1 items-center">
              <Text.P>Preferred first name</Text.P>
              <label className="sr-only" htmlFor={preferredNameInputId}>
                Preferred first name
              </label>
              <div className="w-5 h-5 cursor-pointer">
                <InfoCircle.Small
                  onClick={stopPropagation(() =>
                    dispatch(
                      actions.profile.setPreferredNameTooltipOpen(
                        !pageState.preferredNameTooltipOpen,
                      ),
                    ),
                  )}
                  onMouseEnter={
                    mobile
                      ? undefined
                      : () =>
                          dispatch(
                            actions.profile.setPreferredNameTooltipOpen(true),
                          )
                  }
                />
              </div>
            </div>
            {pageState.preferredNameTooltipOpen && (
              <div
                onMouseLeave={
                  mobile
                    ? undefined
                    : () =>
                        dispatch(
                          actions.profile.setPreferredNameTooltipOpen(false),
                        )
                }
                className="flex-grow lg:absolute lg:left-[175px] lg:w-max mb-2 lg:mb-0 items-center"
              >
                <InfoCard>
                  This is the name you prefer to be called. It will be used in
                  place of your legal name by your provider(s) and on your
                  profile.
                </InfoCard>
              </div>
            )}
          </div>
          <TextInput
            value={preferred_name}
            state={errorState('preferred_name')}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetail({
                  preferred_name: v,
                }),
              )
            }
            id={preferredNameInputId}
          />
        </div>
        <div className="basis-full space-y-1">
          <div className="flex flex-wrap lg:flex-nowrap items-center w-full gap-1 relative">
            <div className="flex gap-1 items-center">
              <Text.P>Email</Text.P>
              <div
                className="w-5 h-5 cursor-pointer"
                onMouseLeave={
                  mobile
                    ? undefined
                    : () => dispatch(actions.profile.setEmailTooltipOpen(false))
                }
              >
                <InfoCircle.Small
                  onClick={stopPropagation(() =>
                    dispatch(
                      actions.profile.setEmailTooltipOpen(
                        !pageState.emailTooltipOpen,
                      ),
                    ),
                  )}
                  onMouseEnter={
                    mobile
                      ? undefined
                      : () =>
                          dispatch(actions.profile.setEmailTooltipOpen(true))
                  }
                />
              </div>
            </div>
            {pageState.emailTooltipOpen && (
              <div
                onMouseLeave={
                  mobile
                    ? undefined
                    : () => dispatch(actions.profile.setEmailTooltipOpen(false))
                }
                className="flex-grow lg:absolute lg:left-[75px] lg:w-max mb-2 lg:mb-0 items-center"
              >
                <InfoCard>
                  Please contact <SupportLink /> if you need to update your
                  email.
                </InfoCard>
              </div>
            )}
          </div>
          <TextInput value={email?.toLowerCase()} state="disabled" />
        </div>
        <div className="basis-full space-y-1">
          <Text.P>Phone</Text.P>
          <label className="sr-only" htmlFor={phoneInputId}>
            Phone
          </label>
          <TextInput
            value={formatPhone(phone)}
            state={errorState('phone')}
            format={formatPhone}
            onChange={(v) =>
              dispatch(actions.profile.setEditedProfileDetail({ phone: v }))
            }
            id={phoneInputId}
          />
        </div>
        <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
          <Text.P>Street address</Text.P>
          <label className="sr-only" htmlFor={streetAddressInputId}>
            Street address
          </label>
          <TextInput
            value={address.street}
            state={errorState('street')}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetailAddress({ street: v }),
              )
            }
            id={streetAddressInputId}
          />
        </div>
        <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
          <Text.P>Apt #</Text.P>
          <label className="sr-only" htmlFor={aptInputId}>
            Apt #
          </label>
          <TextInput
            value={address.apt_suite}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetailAddress({ apt_suite: v }),
              )
            }
            id={aptInputId}
          />
        </div>
        <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
          <Text.P>City</Text.P>
          <label className="sr-only" htmlFor={cityInputId}>
            City
          </label>
          <TextInput
            value={address.city}
            state={errorState('city')}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetailAddress({ city: v }),
              )
            }
            id={cityInputId}
          />
        </div>
        <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
          <Text.P>State</Text.P>
          {/* height of this dropdown needs to subtract the Text.P height from the parent's height */}
          <div className="h-[calc(100%-24px)]">
            <label className="sr-only" htmlFor={dropdownId}>
              State
            </label>
            <Dropdown
              id={dropdownId}
              value={address.state}
              state={errorState('state')}
              onChange={(v) =>
                dispatch(
                  actions.profile.setEditedProfileDetailAddress({ state: v }),
                )
              }
              options={usStateAbbreviations.map((v) => ({ key: v, value: v }))}
            />
          </div>
        </div>
        <div className="basis-full space-y-1">
          <Text.P>Zip code</Text.P>
          <label className="sr-only" htmlFor={zipcodeInputId}>
            Zip code
          </label>
          <TextInput
            value={address.zip}
            state={errorState('zip')}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetailAddress({ zip: v }),
              )
            }
            id={zipcodeInputId}
          />
        </div>
      </div>
    </EditSection>
  );
};

const Details = ({ details, patientData, pageState }: DetailsProps) => {
  return isEditingProfile(details) ? (
    <Edit {...details} patientData={patientData} pageState={pageState} />
  ) : (
    <Read {...details} />
  );
};

export default Details;
