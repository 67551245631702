import React from 'react';
import Text from '../components/Text';
import actions, { Action } from '../state/actions';

const SessionExpired = ({ dispatch }: { dispatch: React.Dispatch<Action> }) => {
  return (
    <div className="text-center space-y-4">
      <Text.Serif.H1>Your session has expired</Text.Serif.H1>
      <Text.P className="text-grey-800">
        If you'd like to log back in,{' '}
        <a
          onClick={() => dispatch(actions.setSessionStatus('login'))}
          className="text-sky-50 no-underline"
        >
          re-submit your email.
        </a>
      </Text.P>
    </div>
  );
};
export default SessionExpired;
