import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { MobileContext } from '@/App';
import Text from '@/components/Text';
import { AnalyticsEvent } from '@/utils/types';
import trackEvent from '@/utils/amplitude';

interface PersonalDetailsBannerProps {
  detailsToEdit: ('creditCard' | 'profile' | 'insurance')[];
}

const PersonalDetailsBanner = ({
  detailsToEdit,
}: PersonalDetailsBannerProps) => {
  const mobile = useContext(MobileContext);
  const TextComponent = mobile ? Text.Small : Text.P;
  const clickPersonalDetailsBanner: AnalyticsEvent = {
    product_area: 'Billing',
    name: 'Card_status_banner_click',
    trigger: 'Interaction',
  };

  const trackAmplitudeEvent = () => {
    trackEvent(clickPersonalDetailsBanner);
  };

  const getBannerLink = (text: string, location: string, action: string) => (
    <Link
      to={`/${location}?deeplink=true&action=${action}`}
      onClick={trackAmplitudeEvent}
    >
      {text}
    </Link>
  );

  const editCcLinkShort = getBannerLink('credit card', 'profile', 'editcc');
  const addInsuranceLinkShort = getBannerLink(
    'insurance',
    'insurance',
    'addinsurance',
  );
  const editCcLink = getBannerLink(
    'update your credit card',
    'profile',
    'editcc',
  );
  const addInsuranceLink = getBannerLink(
    'update your insurance',
    'insurance',
    'addinsurance',
  );

  let bannerContent;

  if (
    detailsToEdit.includes('insurance') &&
    detailsToEdit.includes('creditCard')
  ) {
    bannerContent = (
      <>
        Your account is missing important information. Please update your{' '}
        {editCcLinkShort} and {addInsuranceLinkShort} to continue care.
      </>
    );
  } else if (detailsToEdit.includes('insurance')) {
    bannerContent = (
      <>
        Your account is missing important insurance information. Please{' '}
        {addInsuranceLink} to continue care.
      </>
    );
  } else if (detailsToEdit.includes('creditCard')) {
    bannerContent = (
      <>
        Your account is missing important payment information. Please{' '}
        {editCcLink} to continue care.
      </>
    );
  }

  return (
    <TextComponent className="text-center text-red-50 p-1">
      {bannerContent}
    </TextComponent>
  );
};
export default PersonalDetailsBanner;
