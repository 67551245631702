import { Action } from '../actions';
import produce from 'immer';
import { WritableDraft } from 'immer/src/internal.js';
import { AuthenticatedState, Data, UnauthenticatedState } from '@/utils/types';
import profilePageReducer from './profile';
import billingPageReducer from './billing';
import careTeamPageReducer from './care-team';
import { storageAvailable } from '@/utils/tools';

// calls each reducer in the array with the result from the previous reducer and the action
const composeReducers =
  <S extends State>(...reducers: Array<(_state: S, _action: Action) => S>) =>
  (state: S, action: Action) =>
    reducers.reduce((s, r) => {
      return r(s, action);
    }, state);

export const defaultState: UnauthenticatedState = {
  session: {},
  ui: {
    status: 'login',
    email: '',
    delivered: false,
    emailInvalid: false,
    modal: null,
  },
  toast: null,
};

export function isAuthenticated(state: State): state is AuthenticatedState {
  return 'email' in state.session;
}

export function hasSelectedAccount(state: State): state is AuthenticatedState {
  return 'accountSelected' in state.session;
}

export function isGuardian(state: State): boolean {
  const guardianAccessTypes = ['parent_guardian', 'medical_guardian'];
  const accounts = (state as AuthenticatedState).session.accounts || [];

  if (accounts.length === 1) {
    return (
      'access_type' in accounts[0] &&
      guardianAccessTypes.includes(accounts[0].access_type)
    );
  }

  const selectedAccount = (state as AuthenticatedState).session.accountSelected;
  const matchedAccount = accounts.find(
    ({ patient_record_uuid }) =>
      patient_record_uuid === selectedAccount?.patient_record_uuid,
  );

  if (!matchedAccount) {
    return false;
  }

  return (
    'access_type' in matchedAccount &&
    guardianAccessTypes.includes(matchedAccount.access_type)
  );
}

export function isMinor1317(state: State): state is AuthenticatedState {
  const accounts = (state as AuthenticatedState).session.accounts || [];

  return (
    accounts.length === 1 &&
    'access_type' in accounts[0] &&
    accounts[0].access_type === 'minor_13_17'
  );
}

export type State = UnauthenticatedState | AuthenticatedState;

const preAuthReducer = produce(
  (state: WritableDraft<UnauthenticatedState>, action: Action): void => {
    // may need to move this logic around
    if (action.type === 'setModal') {
      state.ui.modal = action.payload;
    }
    if (action.type === 'setToken') {
      state.token = action.payload;
    }
    if (state.ui.status === 'login') {
      if (action.type === 'login/setEmail') {
        state.ui.email = action.payload;
        state.ui.delivered = false;
      } else if (action.type === 'login/setEmailDelivered') {
        state.ui.delivered = action.payload;
      } else if (action.type === 'login/setEmailInvalid') {
        state.ui.emailInvalid = action.payload;
      }
    }
    if (
      state.ui.status === 'unverified_birthdate' ||
      state.ui.status === 'unverified_phone' ||
      state.ui.status === 'unverified'
    ) {
      if (action.type === 'verify/setDob') {
        state.ui.dob = action.payload;
      }
      if (action.type === 'verify/setPhoneNumber') {
        state.ui.phoneNumber = action.payload;
      }
      if (action.type === 'verify/pushErrorAttempt') {
        const attempts = [action.payload, ...(state.ui.attempts || [])];
        state.ui.attempts = attempts;
        state.ui.dob = '';
      }
      if (action.type === 'verify/setState') {
        state.ui.state = action.payload;
      }
    }
    if (action.type === 'setSessionStatus') {
      state.ui.status = action.payload;
    }
  },
);

const postAuthReducer = produce(
  (state: WritableDraft<AuthenticatedState>, action: Action): void => {
    if (action.type === 'careTeamV3/setCareTeam') {
      state.data.careTeam_v3 = {
        loadingState: 'done',
        ...action.payload,
      };
    } else if (action.type === 'messaging/setMessageThreads') {
      state.data.messaging = {
        loadingState: 'done',
        threads: action.payload,
      };
    } else if (action.type === 'appointments_v2/setAppointments') {
      state.data.appointments_v2 = {
        loadingState: 'done',
        rows: action.payload,
      };
    } else if (action.type === 'addUsedAvailabilitySlot') {
      if (!state.ui.usedAvailabilitySlots) {
        state.ui.usedAvailabilitySlots = {};
      }
      const { npi, slot } = action.payload;
      if (npi in state.ui.usedAvailabilitySlots) {
        state.ui.usedAvailabilitySlots[npi].push(slot);
      } else {
        state.ui.usedAvailabilitySlots[npi] = [slot];
      }
    } else if (action.type === 'async/setLoading') {
      const { key, loadingState } = action.payload;
      const group = dataGroups.find((s) => s.has(key));
      if (group) {
        group.forEach((k) => (state.data[k].loadingState = loadingState));
      } else {
        state.data[key].loadingState = loadingState;
      }
    } else if (action.type === 'closeMenus') {
      state.ui.userMenuOpen = false;
      state.ui.supportInfoOpen = false;
      state.ui.hamburgerMenuOpen = false;
    } else if (action.type === 'closeModal') {
      state.ui.modal = null;
    } else if (action.type === 'setUserMenuOpen') {
      state.ui.userMenuOpen = action.payload;
    } else if (action.type === 'setHamburgerMenuOpen') {
      state.ui.hamburgerMenuOpen = action.payload;
    } else if (action.type === 'setPage') {
      if (action.payload === 'care-team') {
        state.ui.page = {
          path: action.payload,
          root: {},
          providerProfile: {},
          rematch: {
            rematchProviders: { loadingState: 'pending' },
          },
        };
      } else {
        state.ui.page = {
          path: action.payload,
        };
      }
    } else if (action.type === 'setModal') {
      state.ui.modal = action.payload;
    } else if (action.type === 'setBanner') {
      state.ui.banner = action.payload;
    } else if (action.type === 'setProviderSpecializations') {
      state.data.providerSpecializations = {
        loadingState: 'done',
        data: action.payload,
      };
    } else if (action.type === 'setProviderModalities') {
      state.data.providerModalities = {
        loadingState: 'done',
        data: action.payload,
      };
    } else if (action.type === 'setProviderOptions') {
      state.data.providerOptions = {
        loadingState: 'done',
        data: action.payload,
      };
    } else if (action.type === 'setAcceptedInsurances') {
      state.data.acceptedInsurances = {
        loadingState: 'done',
        data: action.payload,
      };
    } else if (action.type === 'setSelfPayRates') {
      state.data.selfPayRates = {
        loadingState: 'done',
        data: action.payload,
      };
    } else if (action.type === 'setAccountSelected') {
      if (action.payload) {
        state.session.accountSelected = action.payload;
      } else {
        delete state.session.accountSelected;
      }
    } else if (action.type === 'setRelationshipAccounts') {
      state.session.accounts = action.payload;
    } else if (action.type === 'surveys/setSurveys') {
      state.data.surveys = { loadingState: 'done', data: action.payload };
    } else if (action.type === 'disclaimers/setDisclaimers') {
      state.data.disclaimers = { loadingState: 'done', data: action.payload };
    }
  },
);

// some data comes from the same request but is stored across several keys
// we need to keep the loadingState in sync for these groups
const dataGroups: Array<Set<keyof Data>> = [
  new Set(['profile', 'patientData']),
  new Set(['insurance', 'creditCard']),
];

const reducer = (state: State, action: Action): State => {
  if (action.type === 'timeoutSession') {
    return {
      ...defaultState,
      ui: {
        status: 'session_expired',
      },
    };
  }
  if (action.type === 'setToast') {
    state = {
      ...state,
      toast: action.payload,
    };
  }

  if (isAuthenticated(state)) {
    if (action.type === 'refreshSessionTime') {
      return {
        ...state,
        session: {
          ...state.session,
          expires: action.payload,
        },
      };
    }
    // logout action - state transitions from AuthenticatedState to UnauthenticatedState
    if (action.type === 'setSession') {
      if (!('email' in action.payload)) {
        return defaultState;
      } else {
        return {
          ...state,
          session: action.payload,
        };
      }
    }

    return composeReducers(
      postAuthReducer,
      profilePageReducer,
      billingPageReducer,
      careTeamPageReducer,
    )(state, action);
  } else {
    if (action.type === 'setSession' && 'email' in action.payload) {
      const newState: AuthenticatedState = {
        data: {
          carriers: {
            loadingState: 'pending',
          },
          profile: {
            loadingState: 'needed',
          },
          creditCard: {
            loadingState: 'pending',
          },
          insurance: {
            loadingState: 'pending',
          },
          upcomingInsurance: {
            loadingState: 'pending',
          },
          providerSpecializations: {
            loadingState: 'pending',
          },
          providerModalities: {
            loadingState: 'pending',
          },
          acceptedInsurances: {
            loadingState: 'pending',
          },
          selfPayRates: {
            loadingState: 'pending',
          },
          providerOptions: {
            loadingState: 'pending',
          },
          patientData: {
            loadingState: 'pending',
          },
          billing: {
            loadingState: 'pending',
          },
          appointments_v2: {
            loadingState: 'pending',
          },
          messaging: {
            loadingState: 'pending',
          },
          careTeam_v3: {
            loadingState: 'pending',
          },
          surveys: {
            loadingState: 'pending',
          },
          disclaimers: {
            loadingState: 'pending',
          },
        },
        ...state,
        session: action.payload,
        ui: {
          usedAvailabilitySlots: {},
          page: {
            path: 'profile',
          },
          modal: null,
          banner: null,
        },
      };
      return newState;
    }
    return preAuthReducer(state, action);
  }
};

export default (state: State, action: Action) => {
  if (
    storageAvailable('localStorage') &&
    localStorage?.getItem('log') === 'true'
  ) {
    console.log(Array(64).fill('*').join(''));
    console.log(state);
    console.log(action);
    const newState = reducer(state, action);
    console.log(newState);
    console.log(Array(64).fill('*').join(''));
    return newState;
  } else return reducer(state, action);
};
