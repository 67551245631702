import CheckCircle from '@/components/icons/CheckCircle';
import { BillingEstimates } from '@/utils/types';
import React from 'react';
import { InPersonTabs } from './InPersonTabs';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { getBillingEstimateValues, hasInPersonEstimates } from './util';

interface SimpleEstimateTemplateProps {
  billingEstimates: BillingEstimates;
}

export const SimpleEstimateTemplate = ({
  billingEstimates,
}: SimpleEstimateTemplateProps) => {
  const { isAllowed } = useFlagCheck();
  const isInPersonTabsEnabled = isAllowed({
    patientPortalReleaseInPersonVisits: true,
  });

  const isCopayOnly =
    billingEstimates.billing_estimate_template === 'copay_only';
  const hasTelehealth = !!billingEstimates.last_therapy_vob_completed_at;
  const hasInPerson = hasInPersonEstimates(billingEstimates);
  const [tab, setTab] = React.useState<'telehealth' | 'in-person'>(
    hasTelehealth ? 'telehealth' : 'in-person',
  );
  const isTelehealth =
    tab === 'telehealth' || !isInPersonTabsEnabled || !hasInPerson;

  const { copay, postDeductibleLow, postDeductibleHigh, coinsurancePercent } =
    getBillingEstimateValues(billingEstimates, isTelehealth);

  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-grey-800 flex items-center gap-2.5">
        <CheckCircle stroke="sky-50" />
        <div className="flex-1 md:flex">
          <div className="flex-1">Rula accepts your insurance!</div>
          <div className="text-sm font-normal text-grey-800">
            Last checked{' '}
            {isTelehealth
              ? billingEstimates.last_therapy_vob_completed_at
              : billingEstimates.last_in_person_therapy_vob_completed_at}
          </div>
        </div>
      </div>
      <div className="flex gap-6 flex-col md:flex-row">
        <div className="bg-sky-20 rounded px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-grey-800">COST ESTIMATE</div>
          {isInPersonTabsEnabled && hasTelehealth && hasInPerson && (
            <InPersonTabs tab={tab} onTabChange={setTab} />
          )}
          <div>
            <div className="text-sm text-grey-800">Appointment cost</div>
            <div
              className="text-sky-50 text-2xl font-medium"
              data-testid="appointment-cost"
            >
              {isCopayOnly
                ? `$${copay}`
                : `$${postDeductibleLow}-$${postDeductibleHigh}`}
            </div>
          </div>
        </div>
        <div className="text-grey-800 md:pl-9 md:pr-6">
          <p>
            <b className="mb-2 font-medium text-grey-800">
              What your benefits mean
            </b>
            <br />
            You do not have an annual deductible.{' '}
            {isCopayOnly
              ? `We estimate your per appointment cost to be a copay of $${copay}.`
              : `We estimate your per appointment costs to be between $${postDeductibleLow} and $${postDeductibleHigh}. This estimate reflects your plan's coinsurance percentage, where you are responsible for ${coinsurancePercent}% of the appointment rate determined by your insurer.`}
            <br />
            <br />
            <b className="mb-2 font-medium text-grey-800">
              This breakdown is just an estimate
            </b>
            <br />
            This estimate is based on the information you provided to Rula. If
            it isn't what you expect, contact your insurer for clarification and{' '}
            <a
              className="text-sky-50 no-underline"
              href="https://patientsupport.rula.com/hc/en-us/sections/27330239424411-Your-health-insurance-pre-visit"
            >
              check out our insurance FAQ
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
