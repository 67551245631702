import { useContext, useEffect, useState, useMemo } from 'react';
import { StateContext } from './App';
import Modal from './components/Modal';
import actions from './state/actions';
import getCookies from './utils/cookies';
import { ActiveRelationshipAccount } from './utils/types';
import AccountSwitchModal from './components/Modals/AccountSwitchModal';

const AccountSwitchWarning = () => {
  const { state, dispatch } = useContext(StateContext);
  const [accountSwitched, setAccountSwitched] = useState(false);
  const modal = useMemo(() => state.ui.modal, []);

  // set an interval to check every 3 seconds if the account cookie is diff from react state cookie
  useEffect(() => {
    setInterval(() => {
      const selectedAccountCookie = getCookies()['__Host-selectedAccount'];
      const { patientData } = state.data;
      if (selectedAccountCookie) {
        const selectedAccount: ActiveRelationshipAccount = JSON.parse(
          selectedAccountCookie,
        );

        if (
          patientData.loadingState === 'done' &&
          patientData.patient_record_uuid !==
            selectedAccount.patient_record_uuid
        ) {
          setAccountSwitched(true);
        }
      }
    }, 5000);
  });

  const closeAndRefresh = () => {
    dispatch(actions.setModal(null));
    window.location.reload();
  };

  return accountSwitched ? (
    <div
      className="w-full h-full flex justify-center items-center fixed top-0 z-20 bg-grey-800 bg-opacity-50 z-10"
      onClick={closeAndRefresh}
    >
      <Modal {...modal}>
        <AccountSwitchModal closeAndRefresh={closeAndRefresh} />
      </Modal>
    </div>
  ) : null;
};

export default AccountSwitchWarning;
