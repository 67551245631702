import InsuranceIcon from '@/components/icons/Insurance';
import Text from '@/components/Text';
import dayjs from 'dayjs';

export const UpcomingInsuranceSection = ({
  insuranceName,
  effectiveDate,
  isEditing = false,
  onRemove = () => {},
}: {
  insuranceName: string;
  effectiveDate: dayjs.Dayjs;
  isEditing?: boolean;
  onRemove?: () => void;
}) => (
  <div className="border-1 p-4 rounded bg-grey-100">
    {!isEditing && (
      <div className="flex items-center mb-4">
        <Text.P className="flex-1 text-sm">Upcoming insurance</Text.P>
        <span className="bg-sky-10 text-black py-1 px-3 rounded-xl text-sm">
          Starts {effectiveDate.format('MM/DD/YY')}
        </span>
      </div>
    )}
    <div className="flex items-center">
      <InsuranceIcon />
      <span className="pl-2 flex-1">{insuranceName}</span>
      {isEditing && (
        <>
          <span className="bg-sky-10 text-black py-1 px-3 rounded-xl text-sm mr-4">
            Starts {effectiveDate.format('MM/DD/YY')}
          </span>
          <Text.Small.Bold
            className="cursor-pointer text-sky-50 text-base"
            onClick={onRemove}
          >
            Remove
          </Text.Small.Bold>
        </>
      )}
    </div>
  </div>
);
