import { IconProps } from '@/components/icons/Icon';
import Text from '@/components/Text';
import Section from './Section';

const ReadSection = ({
  children,
  label,
  editLabel = 'Edit',
  onEditClick,
  fullWidth = false,
}: {
  children: JSX.Element | (JSX.Element | null)[];
  label: string;
  editLabel?: string;
  onEditClick: () => unknown;
  fullWidth?: boolean;
}) => {
  return (
    <Section fullWidth={fullWidth}>
      <div className="flex justify-between items-center">
        <Text.Serif.H3>{label}</Text.Serif.H3>
        <Text.Small.Bold
          className="cursor-pointer text-sky-50"
          onClick={onEditClick}
        >
          {editLabel}
        </Text.Small.Bold>
      </div>
      {children}
    </Section>
  );
};

export const ReadSectionRow = ({
  Icon,
  data,
}: {
  Icon: (p: IconProps) => JSX.Element;
  data?: React.ReactNode;
}) => {
  return (
    <div className="flex space-x-2">
      <Icon />
      <Text.P>{data}</Text.P>
    </div>
  );
};

ReadSection.Row = ReadSectionRow;

export default ReadSection;
