import { useContext, useState } from 'react';
import { MobileContext } from '@/App';
import InfoCard from '@/components/InfoCard';
import Text from '@/components/Text';

const WithDisabledTooltip = ({
  enabled,
  info,
  children,
}: {
  enabled: boolean;
  info: string | JSX.Element;
  children: JSX.Element;
}) => {
  const mobile = useContext(MobileContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="relative">
      <div
        onMouseEnter={() => {
          if (!mobile && enabled) {
            setTooltipOpen(true);
          }
        }}
        onMouseLeave={() => {
          if (!mobile && enabled) {
            setTooltipOpen(false);
          }
        }}
        onClick={(e) => {
          if (enabled) {
            // prevent bubbling now that we are covering the disabled button
            e.preventDefault();
            e.stopPropagation();
            if (mobile) {
              setTooltipOpen(!tooltipOpen);
            }
          }
        }}
      >
        <>
          {children}
          {enabled ? (
            <div
              className="absolute w-full h-full top-0 left-0"
              data-testid="with-disabled-tooltip-overlay"
            ></div>
          ) : null}
        </>
      </div>

      {tooltipOpen && (
        <div className="flex-grow z-10 absolute mt-2 left-[calc(-45%)] md:max-w-[356px] md:w-max max-w-prose items-center">
          <InfoCard>
            <Text.P>{info}</Text.P>
          </InfoCard>
        </div>
      )}
    </div>
  );
};

export default WithDisabledTooltip;
