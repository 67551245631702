import { AnalyticsEvent, DisclaimerNames } from '@/utils/types';
import Text from './Text';
import Button from './Button';
import actions from '@/state/actions';
import trackEvent from '@/utils/amplitude';
import fetch from '@/utils/fetch';
import { StateContext } from '@/App';
import { useContext, useEffect } from 'react';

interface MicDisclaimerModalProps {
  patientUuid: string;
}
const MicDisclaimerModal = ({ patientUuid }: MicDisclaimerModalProps) => {
  const { dispatch } = useContext(StateContext);

  const micUrl =
    'https://patientsupport.rula.com/hc/en-us/articles/23465769623323-Understanding-Measurement-Informed-Care';

  useEffect(() => {
    const viewMicDisclaimerModalEvent: AnalyticsEvent = {
      product_area: 'CareTeam',
      name: 'modal_viewed',
      trigger: 'Interaction',
      metadata: {
        shown_mic_modal: true,
        patient_uuid: patientUuid,
      },
    };
    const micDisclaimerName = DisclaimerNames.MicModal;

    fetch
      .json('/api/patient_disclaimers', {
        method: 'PUT',
        body: {
          patientIdentifiers: { patient_uuid: patientUuid },
          disclaimer_name: micDisclaimerName,
        },
      })
      .then(() => {
        dispatch(
          actions.disclaimers.setDisclaimers([
            { disclaimer_name: micDisclaimerName, patient_uuid: patientUuid },
          ]),
        );
        trackEvent(viewMicDisclaimerModalEvent);
      })
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'disclaimers',
            loadingState: 'error',
          }),
        );
      });
  }, []);

  const onClose = () => {
    trackEvent({
      product_area: 'CareTeam',
      name: 'modal_button_selected',
      trigger: 'Interaction',
      metadata: {
        button_name: 'Got it',
        patient_uuid: patientUuid,
      },
    });
    dispatch(actions.setModal(null));
  };

  const onClickLearnMore = () => {
    trackEvent({
      product_area: 'CareTeam',
      name: 'modal_button_selected',
      trigger: 'Interaction',
      metadata: {
        button_name: 'Learn more',
        patient_uuid: patientUuid,
      },
    });
  };

  return (
    <div className="flex flex-col md:flex-row h-screen w-screen overflow-auto rounded p-5 bg-white md:h-[460px] md:w-[720px] scrollbar-hide md:relative">
      <div className="flex flex-col justify-center md:w-2/5 w-full text-center pt-14 md:pt-0 md:p-5">
        <Text.P className="text-grey-500">Take control of your care</Text.P>
        <Text.H1 className="text-[30px] leading-[36px] font-[500]">
          How it works
        </Text.H1>
      </div>
      <div className="flex flex-col justify-evenly md:justify-between md:w-3/5 w-full h-4/5 md:h-full md:p-5">
        <div className="bg-sky-10 px-4 py-6 rounded space-y-3">
          <div className="flex items-center space-x-2">
            <Text.Small className="text-sky-50">01</Text.Small>
            <Text.Small.Bold className="text-sky-50">
              Tell us how you're feeling
            </Text.Small.Bold>
          </div>
          <div>
            <Text.XSmall className="text-sky-60 block leading-snug">
              Every few weeks, we'll ask you to complete a short, self check-in
              survey to understand how you've been feeling.
            </Text.XSmall>
          </div>
        </div>
        <div className="bg-amethyst-10 px-4 py-6 rounded space-y-3">
          <div className="flex items-center space-x-2">
            <Text.Small className="text-amethyst-50">02</Text.Small>
            <Text.Small.Bold className="text-amethyst-50">
              Get personalized care
            </Text.Small.Bold>
          </div>
          <div>
            <Text.XSmall className="text-amethyst-60 block leading-snug">
              We share your responses with your provider, giving them a way to
              tailor your treatment plan accordingly.
            </Text.XSmall>
          </div>
        </div>
        <div className="bg-fern-10 px-4 py-6 rounded space-y-3">
          <div className="flex items-center space-x-2">
            <Text.Small className="text-fern-50">03</Text.Small>
            <Text.Small.Bold className="text-fern-50">
              Experience better outcomes
            </Text.Small.Bold>
          </div>
          <div>
            <Text.XSmall className="text-fern-60 block leading-snug">
              Our data shows that when Rula patients regularly complete self
              check-in surveys, they reach better outcomes, faster.
            </Text.XSmall>
          </div>
        </div>
      </div>
      <div className="flex justify-evenly md:absolute md:bottom-[50px] md:left-[50px] pb-36 md:pb-0">
        <div className="md:pr-5">
          <Button sizeClasses="w-24" onClick={onClose} size="small">
            Got it
          </Button>
        </div>
        <a
          className="no-underline rounded-sm border-1 border-grey-200 hover:border-grey-300 p-2 justify-center items-center flex break-normal text-[14px] leading-[20px] font-[500] w-[96px]"
          href={micUrl}
          target="_blank"
          role="button"
          onClick={onClickLearnMore}
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default MicDisclaimerModal;
