import { useContext } from 'react';
import { MobileContext } from '@/App';
import Text from '@/components/Text';
import SupportLink from './SupportLink';

const DNSBanner = () => {
  const mobile = useContext(MobileContext);
  const TextComponent = mobile ? Text.Small : Text.P;
  return (
    <TextComponent className="text-center text-red-50 p-1">
      Your account has been restricted from scheduling appointments. Contact{' '}
      <SupportLink />
    </TextComponent>
  );
};

export default DNSBanner;
