import Text from '@/components/Text';
import InfoCircle from '@/components/icons/InfoCircle';

export const AddSecondaryInsuranceCard = () => (
  <div className="p-2 rounded flex gap-2 items-center bg-sky-10 text-sky-60">
    <InfoCircle stroke="sky-50" className="min-w-4" />
    <div className="flex flex-col gap-1">
      <Text.Small.Bold>Adding secondary insurance</Text.Small.Bold>
      <Text.Small>
        Avoid billing disruptions by emailing{' '}
        <a href="mailto:support@rula.com">support@rula.com</a> with a copy of
        your insurance card to get started.
      </Text.Small>
    </div>
  </div>
);
