import { useContext } from 'react';
import { StateContext } from '@/App';
import { TherapyType, ProviderWithFirstAppointmentInfo } from '@/utils/types';
import { providerLicenses } from '@/utils/constants';
import Text from './Text';
import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import trackEvent from '@/utils/amplitude';
import actions from '@/state/actions';
import { isOfferingInPerson } from '@/api/provider.api';
import { useFlagCheck } from '@/utils/use-feature-flags';
import ChooseAppointmentLocationModal from './ChooseAppointmentLocationModal';
import { getProviderUnavailableMessage } from '@/utils/tools';
import useData from '@/state/use-data';
import { isLoaded } from '@/utils/types';
import ProviderUnavailableNotice from './ProviderUnavailableNotice';

interface BookingModalCareProviderProps {
  provider: ProviderWithFirstAppointmentInfo;
  patientState: string;
  therapyType: TherapyType;
  isActiveProvider: boolean;
}
const BookingModalCareProvider = ({
  provider,
  patientState,
  therapyType,
  isActiveProvider,
}: BookingModalCareProviderProps) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const { data } = useData(['patientData']);
  const { patientData } = data;
  const patientUuid = isLoaded(patientData) ? patientData.patient_uuid : '';

  const providerLicense = providerLicenses[provider.licenses[0]]?.name || '';
  const isProviderLive = provider.status === 'live';
  const allowReactivation =
    !isActiveProvider &&
    provider.two_letter_state === patientState &&
    isProviderLive;
  const providerConfirmed = 'is_confirmed' in provider && provider.is_confirmed;
  const allowBook =
    (isActiveProvider && providerConfirmed) ||
    (!isActiveProvider && allowReactivation);
  const { isAllowed } = useFlagCheck();
  const inPersonEnabled = isAllowed({
    patientPortalReleaseInPersonVisits: true,
  });

  const navigateToReschedule = () => {
    navigate(
      `/appointments/schedule-follow-up/${provider.npi}/${therapyType}${
        !isActiveProvider ? '?reactivate=true' : ''
      }`,
    );
  };
  const handleBookClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!allowBook) return;
    trackEvent({
      product_area: 'BookingModal',
      name: 'book_clicked',
      trigger: 'Interaction',
      metadata: {
        therapy_type: therapyType,
        provider_npi: provider.npi,
      },
    });
    if (inPersonEnabled) {
      const offersInPerson = await isOfferingInPerson(
        provider.uuid,
        patientUuid,
      );
      if (
        offersInPerson &&
        !isActiveProvider &&
        therapyType !== 'psychiatric'
      ) {
        dispatch(
          actions.setModal({
            children: (
              <ChooseAppointmentLocationModal
                onTelemedicineSelect={() => navigateToReschedule()}
                appointmentLocationPath="reactivation"
              />
            ),
          }),
        );
        return;
      }
    }
    navigateToReschedule();
  };

  return (
    <div
      onClick={(e: React.MouseEvent) => handleBookClick(e)}
      className="
        flex flex-col
        py-3
        border-b last:border-b-0
        gap-3
        md:flex-row md:min-w-[485px] md:border-b-0"
      role={!allowBook ? undefined : 'button'}
    >
      <div className="flex flex-row flex-1 items-center gap-x-4">
        <ProviderHeadshot
          profileImage={provider.profile_image_s3_url}
          className="w-10 h-10"
        />
        <div
          className={`flex items-center flex-row w-full md:justify-between md:h-auto`}
        >
          <div className="flex flex-col md:items-start justify-center overflow-hidden pr-3 flex-1">
            <Text.P className="overflow-hidden text-ellipsis line-clamp-1">
              {provider.first_name} {provider.last_name}
            </Text.P>
            {providerLicense && (
              <Text.Small className="text-ellipsis text-grey-500 overflow-hidden whitespace-nowrap md:whitespace-normal md:mx-[unset]">
                {providerLicense}
              </Text.Small>
            )}
          </div>
        </div>
      </div>
      {isActiveProvider || allowReactivation ? (
        <div className="hidden items-center md:flex">
          <Button
            state={!allowBook ? 'disabled' : undefined}
            variant="primary-outline"
            size="small"
            width="w-[90px]"
            onClick={(e: React.MouseEvent) => handleBookClick(e)}
          >
            Book
          </Button>
        </div>
      ) : (
        <ProviderUnavailableNotice
          message={getProviderUnavailableMessage(provider, patientState)}
        ></ProviderUnavailableNotice>
      )}
    </div>
  );
};

export default BookingModalCareProvider;
