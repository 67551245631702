import Icon, { IconProps } from './Icon';

const BangCircle = ({
  fill = 'fill-[#CE1D1D]', // Defaults to red
  ...props
}: IconProps & { fill?: string }) => {
  return (
    <Icon {...props} stroke="">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8H13ZM11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12H11ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17V15ZM12.01 17C12.5623 17 13.01 16.5523 13.01 16C13.01 15.4477 12.5623 15 12.01 15V17ZM21 12C21 16.9706 16.9706 21 12 21V23C18.0751 23 23 18.0751 23 12H21ZM12 21C7.02944 21 3 16.9706 3 12H1C1 18.0751 5.92487 23 12 23V21ZM3 12C3 7.02944 7.02944 3 12 3V1C5.92487 1 1 5.92487 1 12H3ZM12 3C16.9706 3 21 7.02944 21 12H23C23 5.92487 18.0751 1 12 1V3ZM11 8V12H13V8H11ZM12 17H12.01V15H12V17Z"
        className={fill}
      />
    </Icon>
  );
};

export default BangCircle;
