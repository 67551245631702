import Text from '@/components/Text';
import {
  providerLicenses,
  providerProfileSections,
  psychiatryProviderRole,
  willingToSeeToTherapyType,
} from '@/utils/constants';
import { makeCorrectCasing } from '@/utils/format';
import SeeAllSpecialtiesButton from '@/pages/care-team/SeeAllSpecialtiesButton';
import Shield from '@/components/icons/Shield';
import Dollar from '@/components/icons/Dollar';
import Speech from '@/components/icons/Speech';
import Gender from '@/components/icons/Gender';
import Experience from '@/components/icons/Experience';
import Education from '@/components/icons/Education';
import Video from '@/components/icons/Video';
import Clock from '@/components/icons/Clock';
import { ProviderDetail } from '@/utils/types';

function useMultipartProfile(provider: ProviderDetail): boolean {
  // If provider has all required profile sections, use the multipart profile
  if (
    providerProfileSections.every(
      (section) => !section.required || provider[section.key],
    )
  ) {
    return true;
  }
  return false;
}

function toSentence(list: string[]): string {
  let output: string;
  const length = list.length;
  switch (length) {
    case 0:
      output = '';
      break;
    case 1:
      output = list[0];
      break;
    case 2:
      output = list.join(' and ');
      break;
    default:
      output =
        list.slice(0, length - 1).join(', ') + ', and ' + list[length - 1];
      break;
  }
  return output;
}

interface Props {
  provider: ProviderDetail;
}

export default function ProviderDetailSection({ provider }: Props) {
  return (
    <div className="border border-grey-200 rounded px-6 py-8 space-y-6 w-full">
      <div className="mb-6 text-grey-800">
        <Text.H3>Hi there, I'm {provider.first_name}!</Text.H3>
      </div>

      {/* Provider's bio etc. */}
      {useMultipartProfile(provider) ? (
        <div className="border-b border-grey-200 text-grey-800">
          {providerProfileSections.map(
            (section) =>
              provider[section.key] && (
                <div key={section.key}>
                  {section.label && (
                    <Text.H4 className="mb-1">{section.label}</Text.H4>
                  )}
                  <div className="mb-6">
                    <Text.P>{provider[section.key]}</Text.P>
                  </div>
                </div>
              ),
          )}
        </div>
      ) : (
        <div className="pb-6 mb-6 border-b border-grey-200 text-grey-800">
          <Text.P>{provider.profile_bio || provider.profile_summary}</Text.P>
        </div>
      )}

      {/* Specialties */}
      <div className="mb-4 text-grey-800">
        <Text.H3>Specialties</Text.H3>
        <Text.P.Bold className="pt-4">
          {provider.role === psychiatryProviderRole
            ? 'Provider Type'
            : 'Therapy types'}
        </Text.P.Bold>
        <ul className="profile-inline-list">
          {provider.role === psychiatryProviderRole ? (
            <li>Psychiatry</li>
          ) : (
            provider.willing_to_see.map((patientType) => (
              <li key={patientType}>
                {willingToSeeToTherapyType[patientType]}
              </li>
            ))
          )}
        </ul>

        <Text.P.Bold className="pt-4">Methods</Text.P.Bold>
        <ul className="profile-inline-list">
          {provider.top_modalities?.length > 0
            ? provider.top_modalities.map((modality) => (
                <li key={modality}>{makeCorrectCasing(modality)}</li>
              ))
            : provider.modalities.map((modality) => (
                <li key={modality}>{makeCorrectCasing(modality)}</li>
              ))}
        </ul>

        <Text.P.Bold className="pt-4">Focus Areas</Text.P.Bold>
        <ul className="profile-inline-list">
          {provider.top_specializations?.length > 0
            ? provider.top_specializations.map((specialization) => (
                <li key={specialization}>
                  {makeCorrectCasing(specialization)}
                </li>
              ))
            : provider.specializations.map((specialization) => (
                <li key={specialization}>
                  {makeCorrectCasing(specialization)}
                </li>
              ))}
        </ul>

        {(provider.top_modalities?.length > 0 ||
          provider.top_specializations?.length > 0) && (
          <SeeAllSpecialtiesButton provider={provider} />
        )}
      </div>

      {/* Practice */}
      <div className="mb-4 mt-5 text-grey-800">
        <Text.H3>Practice</Text.H3>
        <ul className="mb-0 mt-4 text-grey-800">
          <li className="mb-3 last:mb-0">
            <Shield className="hidden md:inline-block align-middle mr-3" />
            {toSentence(
              provider.licenses.map(
                (license) => providerLicenses[license]?.name,
              ),
            )}
          </li>

          <li className="mb-3 last:mb-0">
            <Text.P className="inline-block align-middle">
              <Dollar className="hidden md:inline-block align-middle mr-3" />
              Accepts insurance from{' '}
              {toSentence(
                provider.insurances.map((insurance) =>
                  makeCorrectCasing(insurance, true),
                ),
              )}
            </Text.P>
          </li>

          <li className="mb-3 last:mb-0">
            <Text.P className="inline-block align-middle">
              <Speech className="hidden md:inline-block align-middle mr-3" />
              {toSentence(
                provider.languages.map((language) =>
                  makeCorrectCasing(language),
                ),
              )}
            </Text.P>
          </li>

          {provider.allyships?.length > 0 &&
            provider.allyships.find(
              (allyship) => allyship.toUpperCase() == 'LGBTQIA+',
            ) && (
              <li className="mb-3 last:mb-0">
                <Text.P className="inline-block align-middle">
                  <Gender className="hidden md:inline-block align-middle mr-3" />
                  LGBTQIA+ aligned
                </Text.P>
              </li>
            )}

          {provider.start_year &&
            new Date().getFullYear() - provider.start_year >= 5 && (
              <li className="mb-3 last:mb-0">
                <Text.P className="inline-block align-middle">
                  <Experience className="hidden md:inline-block align-middle mr-3" />
                  {new Date().getFullYear() < 10
                    ? '5-10'
                    : new Date().getFullYear() - provider.start_year}{' '}
                  years of experience
                </Text.P>
              </li>
            )}

          {provider.education && provider.education.length > 0 && (
            <li className="mb-3 last:mb-0">
              <Text.P className="inline-block align-middle">
                <Education className="hidden md:inline-block align-middle mr-3" />
                {provider.education}
              </Text.P>
            </li>
          )}
        </ul>
      </div>

      {/* Sessions */}
      <div className="mb-4 text-grey-800">
        <Text.H3>Sessions</Text.H3>
        <ul className="mb-0 mt-4 text-grey-800">
          <li className="mb-3 last:mb-0">
            <Video className="hidden md:inline-block align-middle mr-3" />
            <Text.P className="inline-block align-middle">Video session</Text.P>
          </li>
          <li className="mb-3 last:mb-0">
            <Clock className="hidden md:inline-block align-middle mr-3" />
            {/* TODO - if session length ever becomes variable, we'll need to stop hard-coding it */}
            <Text.P className="inline-block align-middle">60 minutes</Text.P>
          </li>
        </ul>
      </div>
    </div>
  );
}
