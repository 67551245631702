import React, { useContext } from 'react';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import Button from '@/components/Button';
import Text from '@/components/Text';
import X from '@/components/icons/X';

export interface ModalWrapperProps {
  heading: string;
  children: React.ReactNode;
  onClear?: () => unknown;
  onClose?: () => unknown;
  onSave: (event?: React.FormEvent<EventTarget>) => unknown;
}

const ModalWrapper = ({
  children,
  heading,
  onClear,
  onClose,
  onSave,
}: ModalWrapperProps) => {
  const { dispatch } = useContext(StateContext);
  const defaultOnClose = () => dispatch(actions.setModal(null));
  const onClickSave = () => {
    onSave();
    if (onClose) {
      onClose();
    } else {
      defaultOnClose();
    }
  };

  return (
    <form className="rounded w-fit relative z-0 bg-white">
      <div className="p-6 flex justify-between items-center">
        <Text.H4>{heading}</Text.H4>
        <X
          role="img"
          onClick={onClose || defaultOnClose}
          className="cursor-pointer"
        ></X>
      </div>
      <div className="px-6 pb-0 space-y-6 overflow-y-auto">
        <div className="space-y-3 max-h-[60vh]">{children}</div>
      </div>
      <footer className="h-20 w-full bg-white p-4 flex justify-between shadow-cardBottom rounded-b">
        {onClear && (
          <Button variant="primary-flat" onClick={onClear}>
            Clear
          </Button>
        )}
        <Button variant="primary" onClick={onClickSave}>
          Save
        </Button>
      </footer>
    </form>
  );
};

export default ModalWrapper;
