import { useState } from 'react';
import AccountActionSuccess from './AccountActionSuccess';
import Text from '@/components/Text';
import RulaButton from '@pathccm/rula-ui/button';
import { RulaTextField } from '@pathccm/rula-ui/form';
import RulaLink from '@pathccm/rula-ui/link';

type ComponentState =
  | 'select-account'
  | 'transfer-account'
  | 'declare-guardian';

interface AccountTransferProps {
  patientUuid: string;
  patientName: string;
  setAccountPageView: (componentState: ComponentState) => void;
}

const AccountTransfer = ({
  patientName = 'Timbo',
  setAccountPageView,
}: AccountTransferProps) => {
  const [view, setView] = useState<'submit-email' | 'submit-email-success'>(
    'submit-email',
  );
  const [patientEmail, setPatientEmail] = useState('');
  const [confirmedPatientEmail, setConfirmedPatientEmail] = useState('');

  const isValidEmailFormat = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(confirmedPatientEmail);
  };

  const validate = () => {
    if (!isValidEmailFormat()) {
      return 'Invalid email address';
    }
    if (patientEmail !== confirmedPatientEmail) {
      return 'Emails do not match';
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      return;
    } else {
      // submit email. replace this with actual api call sending patient-uuid and captured email
      setView('submit-email-success');
    }
  };

  if (view === 'submit-email-success') {
    return (
      <AccountActionSuccess
        copy={`${patientName} (${confirmedPatientEmail}) will receive an email shortly!`}
        backToAccountPage={() => setAccountPageView('select-account')}
      />
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-8 md:flex-row md:gap-24">
      <div className="flex flex-col gap-6 w-full max-w-[305px] justify-center items-center text-center">
        <Text.H1>Account transfer</Text.H1>
        <Text.P>
          Now that {patientName} is turning 18, they will be responsible for
          managing their care through Rula. To avoid any lapse in their care,
          please enter the email they will use to access their account.
        </Text.P>
        <Text.P>
          On their 18th birthday, they’ll receive instructions for signing into
          their account, and future communications from Rula will be sent to
          their email address.
        </Text.P>
      </div>

      <div className="flex flex-col gap-7 w-full max-w-[305px] justify-center items-center text-center">
        <div className="flex flex-col gap-6 w-full">
          <RulaTextField
            label={`${patientName}'s email`}
            className="self-start w-full text-left"
            onChange={(value) => setPatientEmail(value)}
          />
          <RulaTextField
            label={`Confirm ${patientName}'s email`}
            className="self-start w-full text-left"
            onChange={(value) => setConfirmedPatientEmail(value)}
            validate={validate}
          />
          <RulaButton
            onPress={() => handleSubmit()}
            size="Large"
            className="w-full"
            style="Primary"
            isDisabled={!confirmedPatientEmail || !patientEmail}
          >
            Submit
          </RulaButton>
        </div>

        <div className="flex flex-col gap-7 w-full">
          <div className="flex justify-center items-center gap-1">
            <div className="flex-grow border-b-1" />
            <Text.Small className="text-gray-400">
              Or declare medical guardianship
            </Text.Small>
            <div className="flex-grow border-b-1" />
          </div>

          <Text.P>
            <RulaLink
              onPress={() => setAccountPageView('declare-guardian')}
              className="text-primary"
            >
              Click here
            </RulaLink>{' '}
            for medical guardianship
          </Text.P>
        </div>
      </div>
    </div>
  );
};
export default AccountTransfer;
