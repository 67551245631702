import { useContext, useEffect } from 'react';
import { StateContext } from '../App';
import Text from '../components/Text';
import useData from '../state/use-data';
import InsuranceBlock from './profile/Insurance';
import { BillingEstimates } from './insurance/BillingEstimates';
import trackEvent from '@/utils/amplitude';

const Insurance = () => {
  const { state } = useContext(StateContext);
  const { data, WithData } = useData([
    'profile',
    'insurance',
    'upcomingInsurance',
    'patientData',
    'creditCard',
    'carriers',
    'selfPayRates',
  ]);

  useEffect(() => {
    trackEvent({
      product_area: 'Insurance',
      name: 'page_view',
      trigger: 'Page load',
      metadata: {},
    });
  }, []);

  if (state.ui.page.path !== 'insurance') {
    return null;
  }

  const pageState = state.ui.page;

  return (
    <WithData data={data}>
      {({ patientData, insurance, upcomingInsurance, carriers }) => (
        <div className="space-y-4 w-full relative">
          <Text.H1>Insurance</Text.H1>
          <InsuranceBlock
            insurance={insurance}
            upcomingInsurance={upcomingInsurance}
            carriers={carriers.options}
            patientData={patientData}
            pageState={pageState}
            fullWidth
          />
          <BillingEstimates insurance={insurance} />
        </div>
      )}
    </WithData>
  );
};

export default Insurance;
