import { AuthenticatedState } from '@/utils/types';
import { CashPayTemplate } from './vob-templates/CashPayTemplate';
import { OutOfNetworkTemplate } from './vob-templates/OutOfNetworkTemplate';
import { InProgressTemplate } from './vob-templates/InProgressTemplate';
import { SimpleEstimateTemplate } from './vob-templates/SimpleEstimateTemplate';
import { DetailedBreakdownTemplate } from './vob-templates/DetailedBreakdownTemplate';
import { UnresponsiveTemplate } from './vob-templates/UnresponsiveTemplate';
import { UnknownCostTemplate } from './vob-templates/UnknownCostTemplate';

export interface BillingEstimatesProps {
  insurance: Extract<
    AuthenticatedState['data']['insurance'],
    { loadingState: 'done' }
  >;
}

export const BillingEstimates = (props: BillingEstimatesProps) => {
  const { insurance } = props;

  if (!insurance.benefits) {
    return (
      <div className="p-6 border-1 border-grey-200 rounded">
        <UnresponsiveTemplate />
      </div>
    );
  }

  const billingEstimates = { ...insurance.benefits };

  const formatNumber = (num: string | null | undefined) => {
    if (!num) return null;
    return parseInt(num).toLocaleString();
  };

  billingEstimates.annual_deductible = formatNumber(
    billingEstimates.annual_deductible,
  );
  billingEstimates.pre_deductible_estimate = formatNumber(
    billingEstimates.pre_deductible_estimate,
  ) as string;
  billingEstimates.post_deductible_high = formatNumber(
    billingEstimates.post_deductible_high,
  );
  billingEstimates.post_deductible_low = formatNumber(
    billingEstimates.post_deductible_low,
  );
  billingEstimates.coinsurance_percent = formatNumber(
    billingEstimates.coinsurance_percent,
  );
  billingEstimates.copay = formatNumber(billingEstimates.copay);

  billingEstimates.in_person_post_deductible_high = formatNumber(
    billingEstimates.in_person_post_deductible_high,
  );
  billingEstimates.in_person_post_deductible_low = formatNumber(
    billingEstimates.in_person_post_deductible_low,
  );
  billingEstimates.in_person_coinsurance_percent = formatNumber(
    billingEstimates.in_person_coinsurance_percent,
  );
  billingEstimates.in_person_copay = formatNumber(
    billingEstimates.in_person_copay,
  );

  let template = null;

  switch (billingEstimates.billing_estimate_template) {
    case 'cash_pay':
      template = <CashPayTemplate billingEstimates={billingEstimates} />;
      break;
    case 'out_of_network':
      template = <OutOfNetworkTemplate billingEstimates={billingEstimates} />;
      break;
    case 'in_progress':
      template = <InProgressTemplate />;
      break;
    case 'coinsurance_only':
    case 'copay_only':
      template = <SimpleEstimateTemplate billingEstimates={billingEstimates} />;
      break;
    case 'deductible_and_coinsurance':
    case 'deductible_and_copay':
    case 'deductible_only':
    case 'no_liability':
      template = (
        <DetailedBreakdownTemplate billingEstimates={billingEstimates} />
      );
      break;
    case 'unresponsive':
      template = <UnresponsiveTemplate />;
      break;
    case 'copay_and_coinsurance':
    case 'deductible_copay_and_coinsurance':
      template = <UnknownCostTemplate billingEstimates={billingEstimates} />;
      break;
    case 'hidden':
      return null;
  }
  return <div className="p-6 border-1 border-grey-200 rounded">{template}</div>;
};
