import { BillingEstimates } from '@/utils/types';

export const getBillingEstimateValues = (
  billingEstimates: BillingEstimates,
  isTelehealth?: boolean,
) => {
  return {
    copay: isTelehealth
      ? billingEstimates.copay
      : billingEstimates.in_person_copay,
    postDeductibleLow: isTelehealth
      ? billingEstimates.post_deductible_low
      : billingEstimates.in_person_post_deductible_low,
    postDeductibleHigh: isTelehealth
      ? billingEstimates.post_deductible_high
      : billingEstimates.in_person_post_deductible_high,
    coinsurancePercent: isTelehealth
      ? billingEstimates.coinsurance_percent
      : billingEstimates.in_person_coinsurance_percent,
  };
};

export const hasInPersonEstimates = (billingEstimates: BillingEstimates) => {
  if (!billingEstimates.last_in_person_therapy_vob_completed_at) {
    return false;
  }
  if (billingEstimates.copay && !billingEstimates.in_person_copay) {
    return false;
  }
  if (
    billingEstimates.post_deductible_low &&
    !billingEstimates.in_person_post_deductible_low
  ) {
    return false;
  }
  if (
    billingEstimates.post_deductible_high &&
    !billingEstimates.in_person_post_deductible_high
  ) {
    return false;
  }
  if (
    billingEstimates.coinsurance_percent &&
    !billingEstimates.in_person_coinsurance_percent
  ) {
    return false;
  }
  return true;
};
