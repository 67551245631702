import { Dispatch } from 'react';
import SupportLink from '../components/SupportLink';
import Text from '../components/Text';
import actions, { Action } from '../state/actions';

const MagicLinkUsed = ({ dispatch }: { dispatch: Dispatch<Action> }) => (
  <div className="text-center flex flex-col gap-10 pr-4 pl-4">
    <Text.Serif.H1>This link has already been used</Text.Serif.H1>
    <Text.P className="text-grey-800">
      If it was not you please reach out to our support team right away:{' '}
      <SupportLink />
    </Text.P>
    <Text.P className="text-grey-800">
      Didn't receive a link?{' '}
      <a
        onClick={() => dispatch(actions.setSessionStatus('login'))}
        className="text-sky-50 no-underline"
      >
        Re-submit email
      </a>
    </Text.P>
  </div>
);

export default MagicLinkUsed;
