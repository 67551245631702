import { useEffect } from 'react';
import Button from '@/components/Button';
import Text from '@/components/Text';
import TextInput from '@/components/TextInput';
import fetch from '../utils/fetch';
import { useState } from 'react';
import { changeOnlyIfInteger } from '@/utils/on-change';
import SupportLink from '@/components/SupportLink';
import actions, { Action } from '@/state/actions';
import { UnauthenticatedUi } from '@/utils/types';
import { Dispatch } from 'react';
import { useLoginAfterVerification } from '@/utils/session';
import trackEvent from '@/utils/amplitude';

export interface VerifyPhoneNumberProps {
  state: Extract<
    UnauthenticatedUi,
    { status: 'unverified' | 'unverified_phone' | 'unverified_birthdate' }
  >;
  dispatch: Dispatch<Action>;
  token: string | null;
}

const maxAttempts = 3;

const VerifyPhoneNumber = ({
  dispatch,
  state,
  token,
}: VerifyPhoneNumberProps) => {
  const { phoneNumber = '', state: successState, attempts = [] } = state;
  const [error, setError] = useState('');
  const loginAfterVerification = useLoginAfterVerification(dispatch);
  const success = successState === 'success';

  useEffect(() => {
    if (success) {
      setTimeout(loginAfterVerification, 2000);
    }
  }, [success]);

  const verify = () => {
    if (successState === 'checking') {
      return;
    }
    dispatch(actions.verify.setState('checking'));
    fetch
      .json('/session/verify_phone_number', {
        method: 'PUT',
        body: {
          phone_number: phoneNumber,
          token,
        },
      })
      .then(() => {
        dispatch(actions.verify.setState('success'));
        trackEvent({
          product_area: 'Verification',
          name: 'Patient_account_verified',
          trigger: 'Interaction',
        });
      })
      .catch((r) => {
        if ('status' in r && r.status === 401) {
          dispatch(actions.setSessionStatus('magic_link_expired'));
          return;
        }
        dispatch(actions.verify.setState());
        const attemptsLeft = maxAttempts - (attempts.length + 1);
        dispatch(actions.verify.pushErrorAttempt(phoneNumber));
        setError(
          `The phone number you entered does not match our records. You have ${attemptsLeft} more attempt${
            attemptsLeft === 1 ? '' : 's'
          }.`,
        );
        if (attemptsLeft === 0) {
          trackEvent({
            product_area: 'Verification',
            name: 'Patient_verification_failed',
            trigger: 'Interaction',
          });
        }
      });
  };

  const incompletePhoneNumber = phoneNumber.length < 10;
  const disableButton = successState === 'checking' || incompletePhoneNumber;

  /* eslint-disable no-nested-ternary */
  return (
    <div className="flex flex-col justify-center h-full">
      {attempts.length >= maxAttempts && !success ? (
        <div className="absolute w-full left-1/2 -translate-x-1/2 text-center">
          <div className="flex flex-col gap-8">
            <Text.Serif.H1 className="font-bold flex justify-center">
              Unable to verify your account
            </Text.Serif.H1>
            <Text.P.Bold>
              The phone number you entered does not match our records.
            </Text.P.Bold>
            <Text.P.Bold>
              Please contact us at <SupportLink /> to verify your account.
            </Text.P.Bold>
          </div>
        </div>
      ) : success ? (
        <div className="absolute left-1/2 -translate-x-1/2 text-center w-full">
          <Text.Serif.H1 className="font-bold flex justify-center">
            Success!
          </Text.Serif.H1>
        </div>
      ) : (
        <div className="flex w-full align-center justify-center">
          <div className="space-y-6 md:max-w-[700px]">
            <Text.Serif.H1.Light className="flex justify-center align-center text-center">
              Verify the phone number associated with this email address
            </Text.Serif.H1.Light>
            <div className="w-1/2 ml-auto mr-auto min-w-[250px]">
              <div className="flex flex-col">
                <Text.Small.Bold>Phone number</Text.Small.Bold>
              </div>
              {error && (
                <p className="rounded-sm border-red-50 border-1 bg-opacity-10 bg-red-50 text-red-50 p-3 text-center">
                  {error}
                </p>
              )}
              <TextInput
                value={phoneNumber}
                onEnter={verify}
                onChange={(value) =>
                  changeOnlyIfInteger(() =>
                    dispatch(actions.verify.setPhoneNumber(value)),
                  )(value)
                }
                maxLength={10}
              />
              <div
                className={`mt-4 ${
                  disableButton ? 'opacity-50 pointer-events-none' : ''
                }`}
              >
                <Button
                  variant="secondary"
                  onClick={verify}
                  disabled={disableButton}
                  state={
                    successState === 'checking'
                      ? 'waiting'
                      : incompletePhoneNumber
                        ? 'disabled'
                        : ''
                  }
                >
                  Verify
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
/* eslint-enable no-nested-ternary */

export default VerifyPhoneNumber;
