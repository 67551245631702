import { useContext, useEffect } from 'react';
import { StateContext } from '../App';
import Text from '../components/Text';
import CreditCardDetails from './profile/CreditCardDetails';
import EmergencyContactDetails from './profile/EmergencyContactDetails';
import Details from './profile/Details';
import Insurance from './profile/Insurance';
import actions from '../state/actions';
import useData from '../state/use-data';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { useSearchParams } from 'react-router-dom';

const Profile = () => {
  const { state, dispatch } = useContext(StateContext);
  const { isAllowed } = useFlagCheck();
  const insurancePageEnabled = isAllowed({ patientPortalInsurancePage: true });

  const { data, WithData } = useData([
    'profile',
    'insurance',
    'upcomingInsurance',
    'patientData',
    'creditCard',
    'carriers',
    'selfPayRates',
  ]);

  const [searchParams] = useSearchParams();
  const handleDeeplinks = () => {
    const isDeeplink = !!searchParams.get('deeplink');
    const deeplinkAction = searchParams.get('action');
    if (isDeeplink && deeplinkAction === 'editcc') {
      dispatch(actions.profile.setCcEditMode('edit'));
    }
  };

  useEffect(() => {
    handleDeeplinks();
  }, [searchParams]);

  if (state.ui.page.path !== 'profile') {
    return null;
  }
  const pageState = state.ui.page;

  return (
    <WithData data={data}>
      {({
        profile,
        patientData,
        insurance,
        upcomingInsurance,
        carriers,
        creditCard,
      }) => (
        <div className="space-y-5 w-full relative">
          <Text.H1>Profile</Text.H1>
          <Details
            details={profile}
            patientData={patientData}
            pageState={pageState}
          />
          <EmergencyContactDetails
            details={profile}
            patientData={patientData}
            pageState={pageState}
          />
          {!insurancePageEnabled && (
            <Insurance
              insurance={insurance}
              upcomingInsurance={upcomingInsurance}
              carriers={carriers.options}
              patientData={patientData}
              pageState={pageState}
            />
          )}
          <CreditCardDetails
            creditCard={creditCard}
            patientData={patientData}
            homeAddress={profile.address}
            setEditing={(editing: boolean) =>
              dispatch(actions.profile.setCcEditMode(editing ? 'edit' : 'read'))
            }
          />
        </div>
      )}
    </WithData>
  );
};

export default Profile;
