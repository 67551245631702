import AddAccount from './icons/AddAccount';
import Text from './Text';

export interface AvatarProps {
  initials?: string;
  color?: 'sky-50' | 'sky-50';
}

const LargeAvatar = ({ initials, color = 'sky-50' }: AvatarProps) => {
  return (
    <div
      className={`bg-${color} w-[64px] h-[64px] rounded-full text-white flex flex-shrink-0 flex-grow-0 justify-center items-center`}
    >
      {initials ? <Text.H2>{initials}</Text.H2> : <AddAccount size={36} />}
    </div>
  );
};

const Avatar = ({ initials }: AvatarProps) => {
  return (
    <div className="bg-sky-50 w-[32px] h-[32px] rounded-full text-white flex flex-shrink-0 flex-grow-0 justify-center items-center">
      <Text.P.Bold>{initials}</Text.P.Bold>
    </div>
  );
};

const SmallAvatar = ({ initials }: AvatarProps) => {
  return (
    <div className="bg-sky-50 w-6 h-6 rounded-full text-white flex flex-shrink-0 flex-grow-0 justify-center items-center">
      <Text.Small.Bold>{initials}</Text.Small.Bold>
    </div>
  );
};

Avatar.Small = SmallAvatar;
Avatar.Large = LargeAvatar;

export default Avatar;
