import RulaButton from '@pathccm/rula-ui/button';
import Text from '@/components/Text';
import Avatar from '@/components/Avatar';
import RulaBadge from '@pathccm/rula-ui/badge';
import RulaTooltip from '@pathccm/rula-ui/tooltip';
import Icon from '@pathccm/rula-ui/icon';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { Link } from 'react-router-dom';
interface AccountCardProps {
  patientRecordUuid: string;
  isExpired?: boolean;
  isExpiring?: boolean;
  accountType?: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  onClick: (patientRecordUuid: string) => void;
  onCloseExpiredClick: (patientRecordUuid: string) => void;
}

const AccountCard = ({
  patientRecordUuid,
  accountType,
  firstName,
  lastName,
  preferredName,
  onClick,
  onCloseExpiredClick,
  isExpired,
  isExpiring,
}: AccountCardProps) => {
  const { isAllowed } = useFlagCheck();
  const graduatingMinorsEnabled = isAllowed({ releaseGraduatingMinors: true });
  // this will have to be updated with some prop like hasSubmittedEmail as right now we only let guardians
  // remove (not transfer) a minor account;
  const isGraduating =
    accountType === 'Minor/dependent' && (isExpired || isExpiring);

  const getButtonText = () => {
    if (graduatingMinorsEnabled && isGraduating) {
      return 'Transfer';
    }
    return 'Sign in';
  };

  const getBadge = () => {
    // isExpired is still a valid state to produce a badge before feature graduating minors launch
    if (isGraduating) {
      // theoretically isExpiring can never be true if isExpired is true
      const useExpiring = isExpiring && !isExpired && graduatingMinorsEnabled;

      const text = useExpiring ? 'Expiring' : 'Expired';
      const tooltipText = useExpiring
        ? 'You will soon lose access as a result of this patient turning 18. Provide their email address for our records before their 18th birthday.'
        : 'Your access has expired as a result of this patient reaching the legal age of majority.';
      return (
        <RulaBadge style="Verified" className="mx-auto mt-4 md:mr-4 md:mt-0">
          <RulaTooltip icon="ExclamationCircle">
            {tooltipText}
            {useExpiring && (
              <>
                <br></br>
                <Link className="text-sky-50" to="/submit-email">
                  Submit Email
                </Link>
              </>
            )}
          </RulaTooltip>
          {text}
        </RulaBadge>
      );
    }
    return null;
  };

  const getRemoveButton = (classNames?: string) => {
    return (
      <div
        className={classNames || 'hidden md:flex'}
        role="button"
        onClick={() => onCloseExpiredClick(patientRecordUuid)}
      >
        <Icon icon="X" size="large" />
      </div>
    );
  };

  const getButton = () => {
    const button = isExpired ? (
      getRemoveButton()
    ) : (
      <RulaButton
        size="Medium"
        style="Secondary"
        onPress={() => onClick(patientRecordUuid)}
        className="w-full mt-4 md:w-[100px] md:mt-0"
      >
        {getButtonText()}
      </RulaButton>
    );
    return button;
  };

  const initials = (preferredName[0] || firstName[0]) + lastName[0];

  return (
    <div className="relative bg-grey-50 border-grey-200 border-1 rounded-[10px] w-[350px] flex flex-col px-6 py-5 items-center group md:justify-between md:flex-row md:w-[525px]">
      {isExpired &&
        getRemoveButton('absolute top-[22px] right-[22px] md:hidden')}
      <div className="flex flex-col items-center justify-center md:flex-row md:mb-0">
        <Avatar.Large initials={initials} />
        <div className="flex flex-col items-center md:items-start md:pl-3">
          <Text.P.Bold className="mt-2 text-overflow-ellipsis line-clamp-2 md:mt-0">
            {preferredName || firstName} {lastName}
          </Text.P.Bold>
          {accountType && (
            <Text.P className="text-grey-800">{accountType}</Text.P>
          )}
        </div>
      </div>

      <div className="flex flex-col justify-center w-full md:flex-row md:items-center md:w-auto">
        {getBadge()}
        {getButton()}
      </div>
    </div>
  );
};

export const NewAccountCard = ({
  onAddAccountClick,
}: {
  onAddAccountClick: () => void;
}) => {
  return (
    <div className="border-grey-200 border-1 rounded w-[350px] flex flex-col px-6 py-5 items-center bg-white md:justify-between md:flex-row md:w-[500px]">
      <div className="mb-4 flex flex-col items-center justify-center md:flex-row md:mb-0">
        <Avatar.Large />
        <div className="flex flex-col items-center md:items-start md:pl-3">
          <Text.P.Bold className="mt-2 text-overflow-ellipsis line-clamp-2 md:mt-0">
            Link a profile
          </Text.P.Bold>
        </div>
      </div>
      <RulaButton
        size="Medium"
        style="Secondary"
        onPress={onAddAccountClick}
        className="w-full md:w-[100px]"
      >
        Link
      </RulaButton>
    </div>
  );
};

export default AccountCard;
