import { MobileContext } from '@/App';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import BackNavigation from './BackNavigation';
import Text from '@/components/Text';
import { usePersonalDetails } from '@/utils/use-personal-details';

const FullWidthHeader = ({
  children,
  returnPath = '/care-team',
  returnPage = 'care team',
}: {
  children?: React.ReactNode;
  returnPath?: string;
  returnPage?: string;
}) => {
  const baseHeight = 160;
  const mobileTopPadding = 24;
  const desktopTopPadding = 36;
  const mobile = useContext(MobileContext);
  const [height, setHeight] = useState(
    baseHeight - (mobile ? mobileTopPadding : desktopTopPadding),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { personalDetailsAreValid, hidePersonalDetailsBanner } =
    usePersonalDetails(false);

  useEffect(() => {
    if (ref.current) {
      setHeight(
        ref.current.clientHeight -
          (mobile ? mobileTopPadding : desktopTopPadding),
      );
    }
  });

  // null indicates that the personal details have not loaded yet
  const bannerIsActive =
    personalDetailsAreValid !== null &&
    !personalDetailsAreValid &&
    !hidePersonalDetailsBanner;

  return (
    <div>
      <div
        className={`
        bg-cream-50 w-full min-h-[160px] fixed left-0 px-5 py-10 z-10
        sm:left-[15.625rem] sm:px-12 sm:w-[calc(100%-250px)] ${
          bannerIsActive
            ? 'top-[112px] md:top-[144px]'
            : 'top-[48px] md:top-[80px]'
        }`}
        ref={ref}
      >
        <div className="flex justify-between items-center">
          <BackNavigation />
          <div>
            <Link
              to={returnPath}
              className="no-underline hover:text-sky-50 w-fit"
            >
              <Text.P className="inline-block">Return to {returnPage}</Text.P>
            </Link>
          </div>
        </div>
        <div className="w-full mt-5 text-grey-800">
          <Text.Serif.H3>{children}</Text.Serif.H3>
        </div>
      </div>
      {/* spacing to account for fixed header div */}
      <div style={{ height: `${height}px` }}></div>
    </div>
  );
};

export default FullWidthHeader;
