import { Override } from '../../utils/types';

export type IconProps = Override<
  Omit<JSX.IntrinsicElements['svg'], 'fill' | 'xmlns'>,
  { stroke?: string }
> & { size?: number; viewBox?: string };

const Icon = ({
  stroke = 'grey-800',
  size = 24,
  viewBox = '0 0 24 24',
  ...props
}: IconProps) => {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke === '' ? '' : '#26272A'}
      {...props}
    />
  );
};

export const SmallIcon = (props: IconProps) => (
  <Icon {...props} size={20} viewBox="0 0 24 24" />
);

Icon.Small = SmallIcon;

export default Icon;
