import fetch from './fetch';
import { SelectedAccount } from './types';

const setSelfOnSelectedAccountCookie = async ({
  salesforce_contact_id,
  patient_record_uuid,
  patient_uuid,
  acting_agent_uuid,
}: SelectedAccount) => {
  await fetch.json('/api/set_selected_account', {
    method: 'POST',
    body: {
      salesforce_contact_id,
      patient_record_uuid,
      patient_uuid,
      acting_agent_uuid,
    },
  });
};

export default setSelfOnSelectedAccountCookie;
