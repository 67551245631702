import Text from '@/components/Text';
import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import { providerLicenses } from '@/utils/constants';
import Clock from '../icons/Clock';
import Video from '../icons/Video';
import { ActiveProviderShapeV3, InactiveProviderShape } from '@/utils/types';
import Calendar from '../icons/Calendar';
import dayjs from 'dayjs';

type ConfirmAppointmentCardHeaderProps = {
  provider: ActiveProviderShapeV3 | InactiveProviderShape;
  newAppointment: dayjs.Dayjs;
  durationInMinutes: number;
};

const ConfirmAppointmentCardHeader = ({
  provider,
  newAppointment,
  durationInMinutes,
}: ConfirmAppointmentCardHeaderProps) => {
  const { profile_image_s3_url, licenses, first_name, last_name } = provider;
  const providerName = `${first_name} ${last_name}`;
  return (
    <>
      <div className="h-32 flex items-center border-b-1 border-grey-200 gap-x-6 pb-4">
        <ProviderHeadshot
          profileImage={profile_image_s3_url}
          className="w-28 h-28"
        />
        <div className="h-28 flex flex-col justify-center">
          <Text.H3>{providerName}</Text.H3>
          <Text.P>{providerLicenses[licenses[0]]?.name || ''}</Text.P>
          {/* TODO: handle multiple licenses */}
        </div>
      </div>
      <div className="py-4 justify-center items-center border-b-1 border-grey-200 gap-x-6">
        <div className="flex flex-col md:flex-row md:items-center justify-start md:space-x-6 space-y-4 md:space-y-0">
          <div className="flex items-center space-x-2">
            <Calendar className="grow-0 shrink-0" />
            <Text.P>{newAppointment.format('ddd MMM D [at] h:mmA')}</Text.P>
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="grow-0 shrink-0" />
            <Text.P>{durationInMinutes} minutes</Text.P>
          </div>
          <div className="flex items-center space-x-2">
            <Video className="grow-0 shrink-0" />
            <Text.P>Video session</Text.P>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmAppointmentCardHeader;
