import { Override } from '../utils/types';
import { twMerge } from 'tailwind-merge';
import Loader from './Loader';
import IconComponent, { IconProps } from './icons/Icon';
import Text from './Text';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'flat'
  | 'primary-outline'
  | 'primary-flat'
  | 'tertiary-no-border'
  | ''
  | undefined;

export type ButtonState = 'waiting' | 'disabled' | '' | undefined;

type OverrideProps = {
  state?: ButtonState;
  variant?: ButtonVariant;
  size?: 'small' | 'medium' | 'medium-no-px' | 'large';
  children: string | JSX.Element;
  renderIcon?: (props: IconProps) => ReturnType<typeof IconComponent>;
  sizeClasses?: string;
  align?: 'left' | 'right' | 'center';
  width?: string;
  customClasses?: string;
};

export type ButtonProps = Override<
  Omit<JSX.IntrinsicElements['button'], 'className'>,
  OverrideProps
>;

const getColor = (state: ButtonState, variant: ButtonVariant) => {
  if (state === 'disabled') {
    return getDisabledColor(variant);
  }
  return getActiveColor(variant);
};

const getActiveColor = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return 'border-1 border-sky-50 bg-sky-50 group-hover:bg-sky-60 text-white';
    case 'primary-outline':
      return 'border-1 border-grey-200 hover:border-sky-60 hover:text-sky-60';
    case 'secondary':
      return 'bg-sky-50 group-hover:bg-sky-50 text-white';
    case 'primary-flat':
      return 'text-sky-50 group-hover:text-sky-60';
    case 'flat':
    case 'tertiary-no-border':
      return 'group-hover:text-sky-50';
    default:
      return 'border-1 border-grey-200 group-hover:border-grey-300';
  }
};

const getDisabledColor = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return 'border-1 border-sky-50 bg-sky-50 text-white opacity-50';
    case 'primary-outline':
      return 'border-1 border-grey-200 opacity-50';
    case 'secondary':
      return 'bg-sky-50 text-white';
    case 'primary-flat':
      return 'text-sky-50';
    case 'flat':
    case 'tertiary-no-border':
      return '';
    default:
      return 'border-1 border-grey-200';
  }
};

const Button = ({
  state,
  children,
  variant,
  size = 'large',
  renderIcon,
  sizeClasses = '',
  type = 'button',
  align = 'center',
  width = 'w-full',
  customClasses = '',
  ...props
}: ButtonProps) => {
  const color = getColor(state, variant);

  const getPaddingClasses = () => {
    switch (size) {
      case 'small':
        return 'p-2';
      case 'medium':
        return 'py-2 px-3';
      case 'medium-no-px':
        return 'py-2';
      default:
        return 'py-3 px-4';
    }
  };

  // needs to match the background for the button
  const getLoaderBorderClasses = () => {
    switch (variant) {
      case 'primary':
        return 'border-white border-b-sky-50 group-hover:border-b-sky-60';
      case 'secondary':
        return 'border-white border-b-sky-50 group-hover:border-b-sky-50';
      default:
        return '';
    }
  };

  const getAlignmentClasses = () => {
    switch (align) {
      case 'left':
        return 'justify-start';
      case 'right':
        return 'justify-end';
      default:
        return 'justify-center';
    }
  };

  const classes = twMerge(
    `${width} rounded-sm ${color} ${getPaddingClasses()} ${getAlignmentClasses()} flex break-normal ${customClasses}`,
  );

  return (
    <div className={`group ${sizeClasses}`}>
      <button
        role="button"
        className={classes}
        disabled={state === 'waiting' || state === 'disabled'}
        type={type}
        {...props}
      >
        <span className="relative">
          <span className={state === 'waiting' ? 'invisible' : ''}>
            <span
              className={
                renderIcon
                  ? `flex items-center ${
                      size === 'small' ? 'gap-x-2' : 'gap-x-[10px]'
                    }`
                  : ''
              }
            >
              {renderIcon &&
                renderIcon({
                  size: 16,
                  stroke:
                    variant === 'primary' || variant === 'secondary'
                      ? 'white'
                      : undefined,
                })}
              {size === 'small' ? (
                <Text.Small.Bold>{children}</Text.Small.Bold>
              ) : (
                <Text.P.Bold>{children}</Text.P.Bold>
              )}
            </span>
          </span>
          <Loader
            className={`${
              state === 'waiting' ? '' : 'invisible'
            } absolute border-[3px] h-6 w-6 top-0 left-[calc(50%-12px)] ${getLoaderBorderClasses()}`}
          ></Loader>
        </span>
      </button>
    </div>
  );
};

export default Button;
