import React from 'react';
import CheckCircle from '@/components/icons/CheckCircle';
import { BillingEstimates } from '@/utils/types';
import { InPersonTabs } from './InPersonTabs';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { getBillingEstimateValues, hasInPersonEstimates } from './util';

interface DetailedBreakdownTemplateProps {
  billingEstimates: BillingEstimates;
}

export const DetailedBreakdownTemplate = ({
  billingEstimates,
}: DetailedBreakdownTemplateProps) => {
  const { isAllowed } = useFlagCheck();
  const isInPersonTabsEnabled = isAllowed({
    patientPortalReleaseInPersonVisits: true,
  });

  const {
    billing_estimate_template: template,
    annual_deductible,
    pre_deductible_estimate,
  } = billingEstimates;

  const hasTelehealth = !!billingEstimates.last_therapy_vob_completed_at;
  const hasInPerson = hasInPersonEstimates(billingEstimates);
  const [tab, setTab] = React.useState<'telehealth' | 'in-person'>(
    hasTelehealth ? 'telehealth' : 'in-person',
  );
  const isTelehealth =
    tab === 'telehealth' || !isInPersonTabsEnabled || !hasInPerson;

  const { copay, postDeductibleLow, postDeductibleHigh, coinsurancePercent } =
    getBillingEstimateValues(billingEstimates, isTelehealth);

  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-grey-800 flex items-center gap-2.5">
        <CheckCircle stroke="sky-50" />
        <div className="flex-1 md:flex">
          <div className="flex-1">Rula accepts your insurance!</div>
          <div className="text-sm font-normal text-grey-800">
            Last checked{' '}
            {isTelehealth
              ? billingEstimates.last_therapy_vob_completed_at
              : billingEstimates.last_in_person_therapy_vob_completed_at}
          </div>
        </div>
      </div>
      <div className="flex gap-6 flex-col md:flex-row">
        <div className="bg-sky-20 rounded px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-grey-800">COST ESTIMATE</div>
          {isInPersonTabsEnabled && hasTelehealth && hasInPerson && (
            <InPersonTabs tab={tab} onTabChange={setTab} />
          )}
          <div className="space-y-7">
            <div>
              <div className="text-sm text-grey-800">Annual deductible</div>
              <div
                className="text-sky-50 text-2xl font-medium"
                data-testid="annual-deductible"
              >
                {template === 'no_liability' ? `$0` : `$${annual_deductible}`}
              </div>
            </div>
            <div>
              <div className="text-sm text-grey-800">Pre-deductible</div>
              <div
                className="text-sky-50 text-2xl font-medium"
                data-testid="pre-deductible"
              >
                {template === 'no_liability'
                  ? `$0`
                  : `$${pre_deductible_estimate}`}
              </div>
            </div>
            <div>
              <div className="text-sm text-grey-800">Post-deductible</div>
              <div
                className="text-sky-50 text-2xl font-medium"
                data-testid="post-deductible"
              >
                {template === 'deductible_and_coinsurance' &&
                  `$${postDeductibleLow}-$${postDeductibleHigh}`}
                {template === 'deductible_and_copay' && `$${copay}`}
                {template === 'deductible_only' && `$0`}
                {template === 'no_liability' && `$0`}
              </div>
            </div>
          </div>
        </div>
        <div className="text-grey-800 md:pl-9 md:pr-6">
          <p>
            <b className="mb-2 font-medium text-grey-800">
              What your benefits mean
            </b>
            <br />
            {template === 'deductible_and_coinsurance' &&
              `You have a $${annual_deductible} annual deductible. A deductible is a specified annual amount you must pay out of pocket, before your insurance company will pay a claim. Once you've reached that amount of out of pocket healthcare expenses, you will pay ${coinsurancePercent}% of the session rate determined by your insurer. We estimate your post-deductible costs to be between $${postDeductibleLow} and $${postDeductibleHigh} per session. Before your deductible is met, we estimate that each therapy session is approximately $${pre_deductible_estimate} but may differ based on your insurance. To verify whether your deductible has been met, please contact your insurance company.`}
            {template === 'deductible_and_copay' &&
              `You have a $${annual_deductible} annual deductible. A deductible is a specified annual amount you must pay out of pocket, before your insurance company will pay a claim. Once you've reached that amount of out of pocket healthcare expenses, your per appointment cost will be your copay of $${copay}. Before your deductible is met, we estimate that each therapy session is approximately $${pre_deductible_estimate} but may differ based on your insurance. To verify whether your deductible has been met, please contact your insurance company.`}
            {template === 'deductible_only' &&
              `You have a $${annual_deductible} annual deductible. A deductible is a specified annual amount you must pay out of pocket, before your insurance company will pay a claim. We estimate your post-deductible costs $0 per appointment. Before your deductible is met, we estimate that each therapy session is approximately $${pre_deductible_estimate} but may differ based on your insurance. To verify whether your deductible has been met, please contact your insurance company.`}
            {template === 'no_liability' &&
              `You have no annual deductible, no coinsurance cost, and no copay. We estimate your per appointment cost to be $0.`}
            <br />
            <br />
            <b className="mb-2 font-medium text-grey-800">
              This breakdown is just an estimate
            </b>
            <br />
            This estimate is based on the information you provided to Rula. If
            it isn't what you expect, contact your insurer for clarification and{' '}
            <a
              className="text-sky-50 no-underline"
              href="https://patientsupport.rula.com/hc/en-us/sections/27330239424411-Your-health-insurance-pre-visit"
            >
              check out our insurance FAQ
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
