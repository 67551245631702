import Text from '@/components/Text';
import RulaLink from '@pathccm/rula-ui/link';
import { supportEmail } from '@/utils/constants';

interface AccountActionSuccessProps {
  copy: string;
  isGuardianFlow?: boolean;
  backToAccountPage: () => void;
}

const AccountActionSuccess = ({
  copy,
  isGuardianFlow = false,
  backToAccountPage,
}: AccountActionSuccessProps) => {
  return (
    <div className="flex flex-col justify-center w-full max-w-[305px] items-center gap-6 text-center">
      <Text.H1>Success!</Text.H1>
      <Text.P>{copy}</Text.P>
      {isGuardianFlow && (
        <Text.P.Bold>
          Please send the court order for our records to{' '}
          <RulaLink href="mailto:records@rula.com">records@rula.com</RulaLink>
        </Text.P.Bold>
      )}
      <div className="flex gap-3 items-center justify-center">
        <RulaLink
          onPress={backToAccountPage}
          className="flex items-center gap-1"
        >
          &larr; Back to account
        </RulaLink>
        <>&middot;</>
        <RulaLink href={`mailto:${supportEmail}`}>support@rula.com</RulaLink>
      </div>
    </div>
  );
};

export default AccountActionSuccess;
