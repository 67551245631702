import { useState } from 'react';
import Button from './Button';

interface InlineMessageBoxProps {
  onSendMessage: (message: string) => unknown;
  placeholder?: string;
}

const InlineMessageBox = ({
  onSendMessage,
  placeholder = 'Send a message...',
}: InlineMessageBoxProps) => {
  const [message, setMessage] = useState<string>('');
  const handleSend = () => {
    setMessage('');
    onSendMessage(message);
  };
  return (
    <>
      <div className="w-full">
        <textarea
          className="border-1 border-grey-300 p-3 rounded-sm font-sans text-sm outline-none resize-none w-full placeholder:text-grey-300 placeholder:opacity-100 mb-2"
          aria-label="message input field"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={placeholder}
          rows={4}
        ></textarea>
        <Button
          variant="primary"
          onClick={handleSend}
          state={!message ? 'disabled' : undefined}
        >
          Send message
        </Button>
      </div>
    </>
  );
};

export default InlineMessageBox;
