import { useEffect, useRef, useState } from 'react';
import Button from '@/components/Button';
import Ellipsis from '@/components/icons/Ellipsis';
import stopPropagation from '@/utils/stop-propagation';

export interface PopupMenuProps {
  triggerAsButton?: boolean;
  trigger?: string | JSX.Element;
  verticalPosition?: string;
  children: string | JSX.Element | JSX.Element[];
  width: string;
}

const PopupMenu = ({
  triggerAsButton = false,
  trigger = <Ellipsis />,
  verticalPosition = 'top-0',
  width,
  children,
}: PopupMenuProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const mobileMenuOpenRef = useRef(mobileMenuOpen);
  useEffect(() => {
    mobileMenuOpenRef.current = mobileMenuOpen;
  }, [mobileMenuOpen]);

  useEffect(() => {
    const handleClick = () => {
      if (mobileMenuOpenRef.current) {
        setMobileMenuOpen(false);
      }
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  return (
    <div className="relative cursor-pointer">
      <div
        onClick={stopPropagation(() => setMobileMenuOpen(!mobileMenuOpen))}
        data-testid="popup-menu"
      >
        {triggerAsButton ? <Button size="small">{trigger}</Button> : trigger}
      </div>
      {mobileMenuOpen ? (
        <div
          className={`absolute flex flex-col cursor-pointer gap-1 p-2 right-0 ${verticalPosition} ${width} bg-white z-10 rounded shadow-lg md:right-auto`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default PopupMenu;
