import { useState, useContext } from 'react';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import Video from './icons/Video';
import Text from '@/components/Text';
import Office from './icons/Office';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import { Location, AppointmentLocationPath } from '@/utils/types';
import { IconProps } from './icons/Icon';
import useClipboard from '@/utils/use-clipboard';
import trackEvent from '@/utils/amplitude';
import { IN_PERSON_LOCATION, TELEMEDICINE_LOCATION } from '@/utils/constants';

interface ChooseAppointmentLocationModalProps {
  onTelemedicineSelect: () => void;
  appointmentLocationPath: AppointmentLocationPath;
}

const ChooseAppointmentLocationModal = ({
  onTelemedicineSelect,
  appointmentLocationPath,
}: ChooseAppointmentLocationModalProps) => {
  const [step, setStep] = useState<'chooseLocation' | 'contact'>(
    'chooseLocation',
  );
  const [selected, setSelected] = useState<Location | ''>('');
  const [writeToClipboard] = useClipboard();
  const { dispatch } = useContext(StateContext);

  const closeModal = () => {
    setSelected('');
    dispatch(actions.setModal(null));
  };

  const preventDefaultAndStopPropagation = (
    e: React.MouseEvent | React.FocusEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const trackLocationSelectionInteraction = (selected: Location | '') => {
    const location = selected === IN_PERSON_LOCATION ? 'in-person' : 'virtual';
    const pathMap: Record<AppointmentLocationPath, string> = {
      reactivation: 'reactivation',
      rematch: 'rematch',
      add_care: 'add care',
      reschedule: 'reschedule',
    };
    trackEvent({
      product_area: 'Appointments',
      name: 'location_modal_selected',
      trigger: 'Interaction',
      metadata: {
        appointment_location: location,
        button_name: location,
        path: pathMap[appointmentLocationPath],
      },
    });
  };

  const onContinueClick = (e: React.MouseEvent) => {
    preventDefaultAndStopPropagation(e);
    trackLocationSelectionInteraction(selected);
    if (selected === TELEMEDICINE_LOCATION) {
      closeModal();
      onTelemedicineSelect();
    } else if (selected === IN_PERSON_LOCATION) {
      setSelected('');
      setStep('contact');
    }
  };

  const onSelect = (e: React.MouseEvent, type: Location) => {
    preventDefaultAndStopPropagation(e);
    setSelected(type);
  };

  const onFocus = (e: React.FocusEvent, type: Location) => {
    preventDefaultAndStopPropagation(e);
    setSelected(type);
  };

  const onBackClick = () => {
    setStep('chooseLocation');
  };

  const onCopyClick = () => {
    writeToClipboard('inperson-scheduling@rula.com');
  };

  const contactSupportContent = (
    <>
      <div className="flex justify-between">
        <Text.H4>Please coordinate with support</Text.H4>
        <X onClick={closeModal} className="cursor-pointer"></X>
      </div>

      <Text.Small className="text-grey-800">
        To book an in-person appointment, please reach out to our support team.
      </Text.Small>
      <div className="flex flex-col justify-center items-center px-5 py-4 border-1 border-grey-200 rounded">
        <div className="flex justify-between items-center w-full">
          <Text.P>inperson-scheduling@rula.com</Text.P>
          <Button
            variant="primary-outline"
            size="small"
            sizeClasses="w-[90px]"
            onClick={onCopyClick}
          >
            Copy
          </Button>
        </div>
        <div className="w-full h-[1px] bg-grey-200 my-5" />
        <div className="flex justify-between items-center w-full">
          <Text.P>(213) 642-1035</Text.P>
          <a href="tel:+12136421035" className="no-underline">
            <Button
              variant="primary-outline"
              size="small"
              sizeClasses="w-[90px]"
            >
              Call
            </Button>
          </a>
        </div>
      </div>
      <div className="flex justify-start">
        <Button
          onClick={onBackClick}
          variant="primary-flat"
          customClasses="text-sky-60"
        >
          Back
        </Button>
      </div>
    </>
  );

  const locationButtons: {
    type: Location;
    icon: React.ComponentType<IconProps>;
    text: string;
  }[] = [
    {
      type: TELEMEDICINE_LOCATION,
      icon: Video,
      text: 'Telehealth',
    },
    {
      type: IN_PERSON_LOCATION,
      icon: Office,
      text: 'In-person',
    },
  ];

  const chooseLocationContent = (
    <>
      <div className="flex justify-between">
        <Text.H4>What type of appointment would you like to book?</Text.H4>
        <X onClick={closeModal} className="cursor-pointer"></X>
      </div>

      <Text.Small className="text-grey-800">
        Please choose an appointment type.
      </Text.Small>

      <div className="flex justify-center items-center space-x-5">
        {locationButtons.map(({ type, icon: Icon, text }) => (
          <div
            key={type}
            className="flex flex-col justify-center items-center w-full space-y-4 rounded-lg py-8 border-grey-200 border-1 group hover:border-sky-50 hover:bg-sky-10 hover:text-sky-50 focus:border-sky-50 focus:bg-sky-10 focus:text-sky-50 focus:border-1"
            role="button"
            onClick={(e) => onSelect(e, type)}
            onFocus={(e) => onFocus(e, type)}
            tabIndex={0}
          >
            <Icon className="group-hover:stroke-sky-50 group-focus:stroke-sky-50" />
            <Text.P.Bold>{text}</Text.P.Bold>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full">
        <Button
          onClick={(e) => onContinueClick(e)}
          variant="primary"
          state={!selected ? 'disabled' : undefined}
          sizeClasses="w-full md:w-[210px]"
        >
          Continue
        </Button>
      </div>
    </>
  );

  const getModalContent = () => {
    return step === 'chooseLocation'
      ? chooseLocationContent
      : contactSupportContent;
  };

  return (
    <div
      onClick={() => setSelected('')}
      className="flex flex-col rounded p-6 space-y-6 bg-white md:w-[564px]"
    >
      {getModalContent()}
    </div>
  );
};

export default ChooseAppointmentLocationModal;
