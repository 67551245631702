import { Dispatch } from 'react';
import Text from '../components/Text';
import actions, { Action } from '../state/actions';

const MagicLinkExpired = ({ dispatch }: { dispatch: Dispatch<Action> }) => (
  <div className="text-center flex flex-col gap-10 pr-4 pl-4">
    <Text.Serif.H1>This link has expired</Text.Serif.H1>
    <Text.P className="text-grey-800">
      If you'd like to request a new link,{' '}
      <a
        onClick={() => dispatch(actions.setSessionStatus('login'))}
        className="text-sky-50"
      >
        re-submit your email
      </a>
    </Text.P>
  </div>
);

export default MagicLinkExpired;
