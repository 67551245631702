import { parseMmDdYyyy } from '@/utils/dates';
import { JSONObject } from '@/utils/types';

interface LinkPatientToUserAccountBodyMinor extends JSONObject {
  email: string;
  birthdate: string;
  first_name: string;
  medical_guardian: boolean;
}
interface LinkPatientToUserAccountBodySelf extends JSONObject {
  email: string;
  birthdate: string;
  first_name: string;
  last_name: string;
}
/**
 * @param args.medicalGuardian Will be ignored if isAddingMinor is false.
 */
export function createLinkPatientToUserAccountBody(args: {
  isAddingMinor: boolean;
  email: string;
  dob: string;
  firstName: string;
  lastName: string;
  medicalGuardian?: boolean;
}): LinkPatientToUserAccountBodySelf | LinkPatientToUserAccountBodyMinor {
  if (args.isAddingMinor) {
    if (args.medicalGuardian === undefined) {
      throw new Error('medicalGuardian value is expected');
    }

    return {
      email: args.email,
      birthdate: parseMmDdYyyy(args.dob).format('YYYY-MM-DD'),
      first_name: args.firstName.trim(),
      last_name: args.lastName.trim(),
      medical_guardian: args.medicalGuardian,
    };
  } else {
    return {
      email: args.email,
      birthdate: parseMmDdYyyy(args.dob).format('YYYY-MM-DD'),
      first_name: args.firstName.trim(),
      last_name: args.lastName.trim(),
    };
  }
}
