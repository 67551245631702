import { StateContext } from '@/App';
import { useState, useContext } from 'react';
import { useFlagCheck } from '@/utils/use-feature-flags';
import SelectAccount from './SelectAccount';
import AccountTransfer from './AccountTransfer';
import DeclareGuardian from './DeclareGuardian';
import { ActiveOrExpiredRelationshipAccount } from '@/utils/types';

// to test, uncomment this testAccount and add
// || testAccount to L39
// && !testAccount to L35

// and flip exp flag to false

// const testAccount = {
//   patient_uuid: '123',
//   patient_preferred_name: 'Tim',
//   patient_first_name: 'Timothy',
// };

type ComponentState =
  | 'select-account'
  | 'transfer-account'
  | 'declare-guardian';

const Accounts = () => {
  const { state } = useContext(StateContext);
  const [component, setComponent] = useState<ComponentState>('select-account');
  const [selectedAccountForAction] =
    useState<ActiveOrExpiredRelationshipAccount>();
  const { isAllowed } = useFlagCheck();
  const graduatingMinorsEnabled = isAllowed({ releaseGraduatingMinors: true });

  const setAccountPageView = (componentState: ComponentState) => {
    setComponent(componentState);
  };

  const getAccountActionComponent = () => {
    if (!selectedAccountForAction) {
      return null;
    }
    const { patient_uuid, patient_preferred_name, patient_first_name } =
      selectedAccountForAction;
    const props = {
      patientUuid: patient_uuid,
      patientName: patient_preferred_name || patient_first_name,
      setAccountPageView,
    };
    switch (component) {
      case 'transfer-account':
        return <AccountTransfer {...props} />;
      case 'declare-guardian':
        return <DeclareGuardian {...props} />;
    }
  };

  const isAccountAction = () =>
    ['transfer-account', 'declare-guardian'].includes(component);

  if (graduatingMinorsEnabled && isAccountAction()) {
    return (
      <div className="h-screen w-full flex justify-center items-center bg-[url('/background-sky.svg')] bg-cover bg-center">
        <div className="w-full max-w-[920px] rounded-xl bg-white flex justify-center items-center mx-4 py-8 md:w-[64%] md:h-[578px] md:px-8">
          {getAccountActionComponent()}
        </div>
      </div>
    );
  }
  return (
    <div className={state.ui.modal ? 'overflow-y-hidden h-screen' : ''}>
      <SelectAccount />
    </div>
  );
};

export default Accounts;
