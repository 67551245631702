import Button from '@/components/Button';
import X from '@/components/icons/X';
import Text from '@/components/Text';

const AccountSwitchModal = ({
  closeAndRefresh,
}: {
  closeAndRefresh: () => void;
}) => {
  return (
    <div className="rounded p-6 space-y-6 bg-white md:w-[520px]">
      <div className="flex justify-between">
        <Text.H4>You switched accounts</Text.H4>
        <X onClick={closeAndRefresh} className="cursor-pointer"></X>
      </div>

      <div className="space-y-3">
        <Text.Small className="text-grey-800">
          You signed into another account on a separate tab. Click refresh to
          sync tabs.
        </Text.Small>
      </div>

      <div className="space-y-3">
        <div className="flex column space-x-3">
          <Button
            onClick={closeAndRefresh}
            size={'small'}
            sizeClasses="w-full"
            variant="primary-outline"
          >
            Refresh Page
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountSwitchModal;
