import InsuranceIcon from '@/components/icons/Insurance';
import Text from '@/components/Text';

export const CurrentInsuranceSection = ({
  insuranceName,
  isEditing = false,
}: {
  insuranceName: string;
  isEditing?: boolean;
}) => (
  <div className="border-1 p-4 rounded">
    {!isEditing && (
      <div className="flex items-center mb-4">
        <Text.P className="flex-1 text-sm">Current coverage</Text.P>
      </div>
    )}
    <div className="flex items-center">
      <InsuranceIcon />
      <span className="pl-2">{insuranceName}</span>
    </div>
  </div>
);
