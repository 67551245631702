import BangCircle from './icons/BangCircle';
import Text from './Text';

const ProviderUnavailableNotice = ({ message }: { message: string }) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <BangCircle fill="fill-current text-grey-500" size={14} />
      <Text.Small className="text-grey-500 items-center md:text-right md:flex-1">
        {message}
      </Text.Small>
    </div>
  );
};

export default ProviderUnavailableNotice;
