import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import RulaBadge from '@pathccm/rula-ui/badge';
import dayjs from 'dayjs';
import fetch from '@/utils/fetch';
import Button from './Button';
import Text from './Text';
import PopupMenu from './PopupMenu';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import {
  AnalyticsEvent,
  Trigger,
  TherapyType,
  SeriesType,
  Survey,
  Location,
  Address,
} from '@/utils/types';
import CancelModal from '@/pages/appointments/CancelModal';
import {
  getAppointmentLengthAndEndTime,
  getAppointmentTimeInfo,
} from '@/utils/dates';
import { constructGoogleMapsUrl, formatAddress } from '@/utils/format';
import RescheduleFeeWarningModal from '@/pages/appointments/reschedule/RescheduleFeeWarningModal';
import trackEvent from '@/utils/amplitude';
import { useFlagCheck } from '../utils/use-feature-flags';
import CheckInBanner from './CheckInBanner';
import { IN_PERSON_LOCATION } from '@/utils/constants';

export interface AppointmentCardProps {
  providerName: string;
  isFirstAppointment?: boolean;
  appointmentType?: string;
  appointmentUUID: string;
  patientRecordUUID: string;
  handleCopyZoomLink: (inviteUrl: string) => void;
  zoomMeetingId?: string;
  isHold: boolean;
  providerNpi: number;
  providerEmail?: string;
  cancellationFee?: string;
  patientRef: string;
  providerRef: string;
  startTime: string;
  endTime: string;
  joinUrl?: string;
  inviteUrl?: string;
  seriesType?: SeriesType;
  therapyType?: TherapyType;
  surveys: Survey[];
  location: Location;
  address: Address | null;
}

const getSurveyStatus = async (
  appointmentUUID: string,
  surveys: Survey[],
  patientUUID: string,
): Promise<{ complete: boolean; required: boolean }> => {
  const surveyForAppointment = surveys.find(
    ({ appointment_uuid }) => appointment_uuid === appointmentUUID,
  );

  if (surveyForAppointment) {
    return {
      complete: !!surveyForAppointment.completed_at,
      required: !surveyForAppointment.completed_at,
    };
  }

  // If the survey does not get returned initially, then we need to check if one is required for this appointment
  try {
    const response = await fetch.json('/api/survey_required', {
      method: 'POST',
      body: {
        patient_uuid: patientUUID,
        appointment_uuid: appointmentUUID,
      },
    });

    return {
      complete: false,
      required: response.payload.required,
    };
  } catch (error) {
    console.error(
      `Error fetching survey required status for appointment ${appointmentUUID}:`,
      error,
    );
    return {
      complete: false,
      required: false,
    };
  }
};

const AppointmentCard = ({
  appointmentType,
  appointmentUUID,
  patientRecordUUID,
  providerName,
  isFirstAppointment,
  inviteUrl,
  handleCopyZoomLink,
  isHold,
  cancellationFee,
  providerNpi,
  patientRef,
  providerRef,
  startTime,
  endTime,
  seriesType,
  therapyType,
  surveys,
  location,
  address,
}: AppointmentCardProps) => {
  const { dispatch } = useContext(StateContext);
  const [checkedIn, setCheckedIn] = useState<boolean>(false);
  const [showCheckInBanner, setShowCheckInBanner] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const onZoomLinkClick = () => {
    if (inviteUrl) {
      trackEvent({
        product_area: 'Appointments',
        name: 'copy_link_clicked',
        trigger: 'Interaction',
      });
      handleCopyZoomLink(inviteUrl);
    }
  };
  const navigate = useNavigate();
  const {
    appointmentDayMonthDate,
    appointmentDayAbbr,
    appointmentDate,
    appointmentStartTime,
    appointmentTimeZone,
    appointmentEndTime,
  } = getAppointmentTimeInfo(startTime, endTime);
  const appointmentTime = `${appointmentStartTime} - ${appointmentEndTime} (${appointmentTimeZone})`;

  const { appointmentLengthInMinutes } = getAppointmentLengthAndEndTime(
    startTime,
    endTime,
  );

  const { isAllowed } = useFlagCheck();
  const inPersonAppointmentsEnabled = isAllowed({
    patientPortalReleaseInPersonVisits: true,
  });

  const isLate = !!cancellationFee;
  const allowPsychFollowupReschedule = isLate
    ? isAllowed({ enablePatientPsychAppointmentManagement: true })
    : true;

  const allowReschedule =
    seriesType !== 'followup' ||
    (seriesType === 'followup' &&
      (therapyType !== 'psychiatric' || allowPsychFollowupReschedule));

  useEffect(() => {
    handleDeeplinks();
  }, []);

  const handleDeeplinks = () => {
    const isDeeplink = !!searchParams.get('deeplink');
    const deeplinkAction = searchParams.get('action');
    const deeplinkAppointmentUUID = searchParams.get('appointmentUUID');
    if (
      isDeeplink &&
      deeplinkAction === 'cancel' &&
      deeplinkAppointmentUUID === appointmentUUID
    ) {
      trackCancelOrRescheduleInteractions(
        'cancel_appointment_modal_viewed',
        'Deeplink',
      );
      showCancelModal();
    }
  };

  useEffect(() => {
    const isWithin48Hrs = dayjs(startTime).diff(dayjs(), 'hour') <= 48;

    if (isWithin48Hrs) {
      const fetchCheckInStatus = async () => {
        const patientUUID = patientRef.split('/')[2];
        const { complete, required } = await getSurveyStatus(
          appointmentUUID,
          surveys,
          patientUUID,
        );

        setCheckedIn(!required);

        // We should only show the check in banner if...
        // the survey is complete, because a survey action was taken
        // the survey bundle was not complete AND is required, because a survey action is required
        if (complete || (!complete && required)) {
          setShowCheckInBanner(true);
        }

        // To avoid confusion for the patient, hide the banner if the survey is not required and not complete because there was no survey action required for this appointment
        if (!complete && !required) {
          setShowCheckInBanner(false);
        }
      };

      void fetchCheckInStatus();
    }
  }, [appointmentUUID, startTime, surveys]);

  const cancelAppointment = () => {
    return fetch
      .json('/api/cancel_appointment', {
        method: 'PUT',
        body: {
          patient_record_uuid: patientRecordUUID,
          appointment_uuid: appointmentUUID,
          status: isHold ? 'deleted' : 'canceled',
          cancel_subsequent: !!isFirstAppointment,
          cancellation_metadata: {
            provider_ref: providerRef,
            appointment_date_time: dayjs(startTime).toISOString(),
            patient_ref: patientRef,
          },
        },
      })
      .then(() =>
        dispatch(
          actions.async.setLoading({
            key: 'appointments_v2',
            loadingState: 'needed',
          }),
        ),
      )
      .catch(() => Promise.reject());
  };

  const trackCancelOrRescheduleInteractions = (
    name: string,
    trigger: Trigger = 'Interaction',
  ) => {
    const metadata = {
      appointment_id: appointmentUUID,
      appointment_type: appointmentType || null,
      appointment_status: isHold ? 'hold' : 'appointment',
      appointment_start_time: startTime,
      appointment_duration_mins: appointmentLengthInMinutes,
      provider_name: providerName,
      provider_npi: providerNpi,
      treatment_type:
        appointmentType === 'Psychiatry' ? 'psychiatry' : 'therapy',
      within_late_cancel_window: !!cancellationFee,
      appointment_location:
        location === IN_PERSON_LOCATION ? 'in-person' : 'virtual',
    };
    const event: AnalyticsEvent = {
      product_area: 'Appointments',
      name,
      trigger,
      metadata,
    };
    trackEvent(event);
  };

  const showCancelModal = () => {
    dispatch(
      actions.setModal({
        children: (
          <CancelModal
            dispatch={dispatch}
            cancellationFee={cancellationFee}
            isFirstAppointment={isFirstAppointment}
            cancelAppointment={cancelAppointment}
            trackEvent={trackCancelOrRescheduleInteractions}
            providerName={providerName}
            appointmentTime={appointmentTime}
            appointmentDayMonthDate={appointmentDayMonthDate}
          />
        ),
      }),
    );
  };
  const onCancelClick = () => {
    trackCancelOrRescheduleInteractions(
      'cancel_appointment_entry_point_clicked',
    );
    showCancelModal();
  };

  const onRescheduleClick = () => {
    trackCancelOrRescheduleInteractions(
      'reschedule_appointments_entry_point_clicked',
    );
    const rescheduleURL = `/appointments/reschedule/${providerNpi}/${appointmentUUID}`;
    if (cancellationFee) {
      trackCancelOrRescheduleInteractions(
        'last_minute_reschedule_modal_viewed',
      );
      dispatch(
        actions.setModal({
          children: (
            <RescheduleFeeWarningModal
              dispatch={dispatch}
              cancellationFee={cancellationFee}
              rescheduleURL={rescheduleURL}
              trackEvent={trackCancelOrRescheduleInteractions}
            />
          ),
        }),
      );
    } else {
      navigate(rescheduleURL);
      return;
    }
  };

  const enableZoomButton =
    inviteUrl && dayjs(startTime).diff(dayjs(), 'minute') <= 30;

  const isInPerson =
    inPersonAppointmentsEnabled && location === IN_PERSON_LOCATION;

  const getPopupMenu = (classes: string) => {
    const infoText = isInPerson ? (
      <Text.Small className="text-grey-300 px-2 pb-2 cursor-default">
        To reschedule or make any additional changes to your appointment message
        your provider or contact support.
      </Text.Small>
    ) : null;
    return (
      <div className={classes}>
        <PopupMenu verticalPosition="top-6" width="w-[220px]">
          <Text.P role="button" onClick={onCancelClick} className="p-2">
            Cancel appointment
          </Text.P>
          {inviteUrl ? (
            <Text.P role="button" onClick={onZoomLinkClick} className="p-2">
              Copy appointment link
            </Text.P>
          ) : (
            <></>
          )}
          {allowReschedule ? (
            <>
              <Text.P
                role={isInPerson ? 'text' : 'button'}
                onClick={isInPerson ? null : onRescheduleClick}
                className={`p-2 ${isInPerson ? 'text-grey-300 cursor-default' : ''}`}
              >
                Reschedule appointment
              </Text.P>
              {infoText}
            </>
          ) : (
            <></>
          )}
        </PopupMenu>
      </div>
    );
  };

  return (
    <div className="m-auto flex flex-col border-grey-200 rounded border-1 max-w-[350px] md:max-w-[714px] md:m-0">
      <div
        data-testid="appointment-card"
        className="flex flex-col items-center text-grey-800 p-9 md:py-7 md:px-0 md:items-left md:flex-row"
      >
        <div className="w-full flex flex-col rounded-t items-center relative text-sky-60 md:justify-center md:rounded-none md:h-auto md:w-[106px]">
          <Text.XSmall>{appointmentDayAbbr.toLocaleUpperCase()}</Text.XSmall>
          <Text.H1 className="text-[30px]">{appointmentDate}</Text.H1>
          {getPopupMenu('absolute top-0 right-0 md:hidden')}
        </div>

        {/* vertical line */}
        <div className="w-[3px] h-[72px] py-1 bg-sky-50 rounded-lg hidden md:block" />
        {/* horizontal line */}
        <div className="w-[65px] h-[3px] bg-sky-50 my-3 rounded-lg block md:hidden" />
        <div className="flex flex-col flex-grow items-center text-grey-800 gap-y-[3px] md:gap-y-[2px] md:py-1 md:pl-6 md:items-start">
          <div className="flex flex-col items-center gap-x-1 md:flex-row">
            <Text.H3>{appointmentType || 'Therapy'}</Text.H3>
            {isFirstAppointment ? (
              <RulaBadge style="Default" size="small">
                First appointment
              </RulaBadge>
            ) : null}
          </div>
          <Text.Small>{appointmentTime}</Text.Small>
          <Text.Small>{providerName}</Text.Small>
          {isInPerson && address && (
            <Link
              className="cursor-pointer text-sky-50 no-underline md:underline"
              target="_blank"
              to={constructGoogleMapsUrl(address)}
            >
              <Text.Small className="whitespace-pre-line no-skip-ink block text-center md:text-left md:leading-tight">
                {formatAddress(address, true)}
              </Text.Small>
            </Link>
          )}
        </div>

        <div className="w-full h-[1px] bg-grey-200 my-7 block md:hidden" />

        <div className="w-full flex flex-col items-center md:gap-x-4 md:flex-row md:px-9 md:w-auto">
          {!isInPerson && (
            <Button
              width="w-full md:w-[94px]"
              state={!enableZoomButton ? 'disabled' : undefined}
              variant="primary"
              size="medium"
              sizeClasses="w-full"
              onClick={() => {
                trackEvent({
                  product_area: 'Appointments',
                  name: 'start_appointment_clicked',
                  trigger: 'Interaction',
                });
                window.open(inviteUrl, '_blank');
              }}
            >
              Join
            </Button>
          )}
          {getPopupMenu(
            'hidden justify-between gap-5 items-center w-full md:flex',
          )}
        </div>
      </div>
      {showCheckInBanner ? (
        <CheckInBanner
          extraStyles="rounded-b"
          checkedIn={checkedIn}
          appointmentUuid={appointmentUUID}
          appointmentTime={startTime}
          providerNpi={providerNpi}
        />
      ) : null}
    </div>
  );
};

export default AppointmentCard;
