import { useContext, useEffect } from 'react';
import Text from '@/components/Text';
import actions from '@/state/actions';
import AccountCard, { NewAccountCard } from './AccountCard';
import VerifyPatientLink from './VerifyPatientLink';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { Logo } from '@/components/assets';
import Loader from '@/components/Loader';
import Toast from '@/components/Toast';
import fetch from '@/utils/fetch';
import { useLogout, useRefreshSession } from '../utils/session';
import { StateContext } from '@/App';
import {
  AccessType,
  ActiveRelationshipAccount,
  RelationshipAccounts,
} from '@/utils/types';
import ChooseAddProfileTypeModal, {
  ProfileTypeOption,
} from '@/login/ChooseAddProfileTypeModal';

const SelectAccount = () => {
  const { state, dispatch } = useContext(StateContext);
  const { accounts } = state.session;
  const logout = useLogout(dispatch);
  const refreshSession = useRefreshSession(dispatch);

  useEffect(() => {
    refreshSession();
  }, []);

  const openLinkAccountModal = () => {
    const selfAccount = accounts?.find(
      (a) => !a.expired && a.access_type === 'self',
    );

    if (selfAccount) {
      dispatch(
        actions.setModal({
          children: (
            <VerifyPatientLink
              state={state}
              dispatch={dispatch}
              isAddingMinor
            />
          ),
        }),
      );
    } else {
      const handleProfileTypeSelected = (selection: ProfileTypeOption) => {
        dispatch(
          actions.setModal({
            children: (
              <VerifyPatientLink
                state={state}
                dispatch={dispatch}
                isAddingMinor={selection === ProfileTypeOption.GuardianAccess}
              />
            ),
          }),
        );
      };

      dispatch(
        actions.setModal({
          children: (
            <ChooseAddProfileTypeModal onConfirm={handleProfileTypeSelected} />
          ),
        }),
      );
    }
  };

  const onAccountSelect = (patientRecordUuid: string) => {
    const selectedAccount = accounts?.find(
      (a) => a.patient_record_uuid === patientRecordUuid,
    );
    if (selectedAccount && !selectedAccount.expired) {
      const {
        salesforce_contact_id,
        patient_record_uuid,
        patient_uuid,
        acting_agent_uuid,
        access_type,
      } = selectedAccount;
      fetch
        .json('/api/set_selected_account', {
          method: 'POST',
          body: {
            salesforce_contact_id,
            patient_record_uuid,
            patient_uuid,
            acting_agent_uuid,
            access_type,
          },
        })
        .then(() => {
          dispatch(actions.setAccountSelected(selectedAccount));
          window.location.href = window.location.pathname || '/';
        })
        .catch(() => {
          dispatch(
            actions.setToast({
              text: <Text.P>Failed to select account.</Text.P>,
              variant: 'warning',
              onClose: () => dispatch(actions.setToast(null)),
            }),
          );
        });
    }
  };

  const onCloseExpiredClick = (patientRecordUuid: string) => {
    if (accounts) {
      const expiredAccount = accounts?.find(
        (a) => a.patient_record_uuid === patientRecordUuid,
      );

      if (expiredAccount?.expired) {
        const { patient_record_uuid } = expiredAccount;
        fetch
          .json('/api/remove_patient_relationship', {
            method: 'POST',
            body: {
              patient_record_uuid,
            },
          })
          .then(() => {
            dispatch(
              actions.setRelationshipAccounts(
                accounts.filter(
                  (acc) => acc.patient_record_uuid !== patient_record_uuid,
                ),
              ),
            );
          })
          .catch(() => {
            dispatch(
              actions.setToast({
                text: <Text.P>Failed to remove patient account.</Text.P>,
                variant: 'warning',
                onClose: () => dispatch(actions.setToast(null)),
              }),
            );
          });
      }
    }
  };

  // still loading accounts
  if (typeof accounts === 'undefined') {
    return <Loader.Contained />;
  }

  const sortedAccounts = [...accounts].sort((a) => {
    return !a.expired && a.access_type === 'self' ? -1 : 1;
  });

  const isMinor = (accessType: AccessType) =>
    ['parent_guardian', 'medical_guardian'].includes(accessType);

  const hasSelfAccount = (accounts: RelationshipAccounts) =>
    accounts.some(
      (account) => !account.expired && account.access_type === 'self',
    );

  const hasMinorDependent = (accounts: RelationshipAccounts) =>
    accounts.some(
      (account) => !account.expired && isMinor(account.access_type),
    );

  const getAccountType = (
    account: ActiveRelationshipAccount,
    accounts: RelationshipAccounts,
  ) => {
    if (isMinor(account.access_type)) {
      return 'Minor/dependent';
    }
    if (hasSelfAccount(accounts) && hasMinorDependent(accounts)) {
      return 'Guardian';
    }
    if (hasSelfAccount(accounts) && !hasMinorDependent(accounts)) {
      return undefined;
    }
    return 'Patient';
  };

  return (
    <>
      <div className="flex justify-between items-center mt-5 mx-5 md:mt-8 md:ml-8">
        <Logo />
        <div className="w-[100px] inline-flex" onClick={logout}>
          <Button variant="primary-outline">Log out</Button>
        </div>
      </div>
      <div className="text-center flex flex-col gap-10 pr-4 pl-4 items-center mt-16 md:mt-[125px]">
        <Text.H1>Choose a profile</Text.H1>
        {sortedAccounts?.length ? (
          <>
            <div className="flex flex-col justify-center gap-[10px]">
              {sortedAccounts.map((account, idx) => {
                const {
                  patient_first_name,
                  patient_last_name,
                  patient_record_uuid,
                  expired,
                  patient_preferred_name,
                } = account;
                const accountType = expired
                  ? undefined
                  : getAccountType(account, accounts);
                return (
                  <AccountCard
                    key={idx}
                    patientRecordUuid={patient_record_uuid}
                    accountType={accountType}
                    firstName={patient_first_name}
                    lastName={patient_last_name}
                    preferredName={patient_preferred_name || ''}
                    isExpired={expired}
                    onClick={onAccountSelect}
                    onCloseExpiredClick={onCloseExpiredClick}
                  />
                );
              })}
            </div>
            <Text.P className="text-grey-800 pb-4">
              Need to add someone? <br></br>
              <span
                className="text-sky-50 underline cursor-pointer mb-12"
                onClick={openLinkAccountModal}
              >
                + Add a profile
              </span>
            </Text.P>
          </>
        ) : (
          <NewAccountCard onAddAccountClick={openLinkAccountModal} />
        )}
        {state.ui.modal && (
          <div className="w-full fixed h-screen top-0 left-0 z-10 flex justify-center items-center">
            <div
              className="absolute w-full h-full top-0 left-0 bg-grey-800 bg-opacity-50 z-0"
              onClick={() => dispatch(actions.setModal(null))}
            ></div>
            <div className="z-10">
              <Modal {...state.ui.modal} />
            </div>
          </div>
        )}
      </div>
      {state.toast && (
        <div className="w-screen flex justify-center fixed top-12 mb-4 z-20">
          <Toast {...state.toast} />
        </div>
      )}
    </>
  );
};

export default SelectAccount;
