import { useContext, useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { MobileContext, StateContext } from '@/App';
import EmptyStatePage from '../EmptyStatePage';
import Text from '@/components/Text';
import WithDisabledTooltip from '@/components/WithDisabledTooltip';
import useData from '@/state/use-data';
import {
  appointmentIsWithin24Hours,
  groupAppointmentsByMonthAndYear,
} from '@/utils/dates';
import {
  AnalyticsEvent,
  AppointmentParticipant,
  Disclaimer,
  DisclaimerNames,
} from '@/utils/types';
import AppointmentCard from '@/components/AppointmentCard';
import { therapyTypeToVisitType } from '@/utils/constants';
import { ProviderDetail, Loaded, SelfPayRates } from '@/utils/types';
import useClipboard from '@/utils/use-clipboard';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getPatientFromParticipants } from '@/utils/appointments';
import { Link, useSearchParams } from 'react-router-dom';
import trackEvent from '@/utils/amplitude';
import { getCancellationFee } from '@/utils/billing';
import Button from '@/components/Button';
import CalendarPlus from '@/components/icons/CalendarPlus';
import actions from '@/state/actions';
import BookingModal from '@/components/BookingModal';
import MicDisclaimerModal from '@/components/MicDisclaimerModal';
import { isBookingAllowed } from '@/utils/use-dns-flag';
import SupportLink from '@/components/SupportLink';

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

const EmptyStateMessage = () => (
  <Text.P className="text-grey-800 max-w-[580px]">
    Contact your care team to schedule your next appointment. You can also{' '}
    <Link
      to="/care-team?addCare=any"
      className="cursor-pointer no-underline text-sky-50"
    >
      expand your care team
    </Link>
    .
  </Text.P>
);

const EmptyState = () => (
  <div className="mt-32">
    <EmptyStatePage
      header="No upcoming appointments"
      message={<EmptyStateMessage />}
    />
  </div>
);

const Appointments = () => {
  const { state, dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);
  const { WithData, data } = useData([
    'selfPayRates',
    'careTeam_v3',
    'appointments_v2',
    'patientData',
    'surveys',
    'profile',
    'insurance',
    'disclaimers',
  ]);
  const [writeToClipboard] = useClipboard();
  const [searchParams] = useSearchParams();

  const page = state.ui.page;

  if (page.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({
        careTeam_v3: careTeam,
        selfPayRates,
        appointments_v2,
        patientData,
        surveys: { data: upcomingSurveys },
        profile,
        insurance,
        disclaimers,
      }) => {
        const { patient_record_uuid, patient_uuid } = patientData;
        // filter out past appointments, then grp into months and year
        const futureAppointments = appointments_v2.rows
          .filter(({ end_time }) => {
            if (!end_time) return false;

            const now = dayjs();
            const endTime = dayjs(end_time);

            return endTime.isSameOrAfter(now);
          })
          .sort(
            (a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix(),
          );
        const futureAppointmentsByMonthAndYear =
          groupAppointmentsByMonthAndYear(futureAppointments);
        const monthYears = Object.keys(futureAppointmentsByMonthAndYear);

        const canBook = isBookingAllowed();

        useEffect(() => {
          const micDisclaimerName = DisclaimerNames.MicModal;
          const hasSeenMicDisclaimerModal = disclaimers?.data?.some(
            (disclaimer: Disclaimer) =>
              disclaimer.disclaimer_name === micDisclaimerName,
          );
          const num_appointments_v2 = appointments_v2.rows.length;
          const now = dayjs();
          const last_appointment =
            num_appointments_v2 > 0
              ? appointments_v2.rows[num_appointments_v2 - 1]
              : null;

          // Booking modal deeplink
          const isDeeplink = !!searchParams.get('deeplink');
          const deeplinkAction = searchParams.get('action');

          if (isDeeplink && deeplinkAction == 'book') {
            onBookClick();
          }

          if (!isDeeplink && !hasSeenMicDisclaimerModal) {
            dispatch(
              actions.setModal({
                children: <MicDisclaimerModal patientUuid={patient_uuid} />,
              }),
            );
          }

          const eventBody: AnalyticsEvent = {
            product_area: 'Appointments',
            name: 'page_view',
            trigger: 'Page load',
            metadata: {
              dns: !canBook,
              patient_app_linked_from_email: isDeeplink,
              num_appointments_v2: num_appointments_v2,
              num_future_appointments: futureAppointments.length,
              user_local_time: now.format(),
              last_appointment_end_time: last_appointment
                ? last_appointment.end_time
                : null,
              appointment_parsing_error_old: dayjs(
                '2024-01-01 00:00:00+00',
              ).isValid(),
              appointments_missing_providers: !futureAppointments.every(
                (appt) => careTeam.active.find((p) => p.npi === appt.npi),
              ),
            },
          };
          trackEvent(eventBody);
        }, []);

        const getFeeCallback = useCallback(
          (
            provider: ProviderDetail,
            selfPayRates: Loaded<{ data: SelfPayRates }>,
          ) => {
            return getCancellationFee(provider, selfPayRates);
          },
          [],
        );

        const copyToClipboard = (inviteUrl: string) => {
          writeToClipboard(inviteUrl, 'Success! Your link');
        };

        const onBookClick = () => {
          // TODO Do we want to track the deeplink click as an interaction?
          trackEvent({
            product_area: 'Appointments',
            name: 'book_appointment_clicked',
            trigger: 'Interaction',
          });
          dispatch(
            actions.setModal({
              children: (
                <BookingModal
                  careTeam={careTeam}
                  patientState={profile.location}
                  birthdate={profile.birthdate}
                  insurance={insurance}
                />
              ),
            }),
          );
        };

        const headerRow = (
          <div className="flex justify-between items-center">
            <Text.H1>Appointments</Text.H1>
            <WithDisabledTooltip
              enabled={!canBook}
              info={
                <span>
                  Your account has been restricted from scheduling appointments.{' '}
                  Contact <SupportLink />
                </span>
              }
            >
              <Button
                size={mobile ? 'small' : 'medium'}
                variant="primary-outline"
                renderIcon={() => <CalendarPlus />}
                onClick={onBookClick}
                state={!canBook ? 'disabled' : undefined}
              >
                {mobile ? 'Book' : 'Book appointment'}
              </Button>
            </WithDisabledTooltip>
          </div>
        );

        return (
          <div className="max-w-[715px]">
            {headerRow}
            {monthYears.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="text-grey-800">
                {monthYears.map((monthYear) => {
                  const appointmentsInMonthAndYear =
                    futureAppointmentsByMonthAndYear[monthYear];
                  const date = dayjs(monthYear, 'MMMM YYYY');
                  const month = date.format('MMMM');
                  return (
                    <div key={monthYear}>
                      <Text.H2 className="my-6">{month}</Text.H2>
                      {appointmentsInMonthAndYear.map((appointment, idx) => {
                        const {
                          ref,
                          join_url,
                          invite_url,
                          status,
                          series_type,
                          therapy_type,
                          provider_ref,
                          participants,
                          start_time,
                          end_time,
                          location,
                          address,
                        } = appointment;
                        const primaryParticipant =
                          getPatientFromParticipants(participants);
                        const appointmentUUID = ref.split('/')[2];
                        const provider = careTeam.active.find(
                          (p) => p.npi === appointment.npi,
                        );

                        const appointmentInNextDay =
                          appointmentIsWithin24Hours(start_time);

                        if (!provider) {
                          Sentry.captureMessage(
                            `could not locate provider on appointments page with appointment npi: ${appointment.npi}`,
                          );
                          return null;
                        }

                        const {
                          approved_client_email,
                          approved_client_facing_email,
                          first_name,
                          last_name,
                          npi,
                        } = provider;
                        const providerName = `${first_name} ${last_name}`;
                        const cancellationFee = getFeeCallback(
                          provider,
                          selfPayRates,
                        );

                        const cardMargin =
                          idx === appointmentsInMonthAndYear.length - 1
                            ? ''
                            : 'mb-3';
                        return (
                          <div key={ref} className={cardMargin}>
                            <AppointmentCard
                              providerName={providerName}
                              isFirstAppointment={series_type === 'initial'}
                              appointmentType={
                                therapy_type &&
                                therapyTypeToVisitType[therapy_type]
                              }
                              handleCopyZoomLink={copyToClipboard}
                              joinUrl={join_url}
                              inviteUrl={invite_url}
                              isHold={status === 'held'}
                              providerEmail={
                                approved_client_facing_email
                                  ? approved_client_email
                                  : undefined
                              }
                              providerNpi={npi}
                              cancellationFee={
                                appointmentInNextDay
                                  ? cancellationFee
                                  : undefined
                              }
                              patientRecordUUID={patient_record_uuid}
                              appointmentUUID={appointmentUUID}
                              patientRef={
                                (primaryParticipant as AppointmentParticipant)
                                  .ref
                              }
                              providerRef={provider_ref}
                              startTime={start_time}
                              endTime={end_time}
                              seriesType={series_type}
                              therapyType={therapy_type}
                              surveys={upcomingSurveys}
                              location={location}
                              address={address}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    </WithData>
  );
};

export default Appointments;
