import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import RulaBadge from '@pathccm/rula-ui/badge';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import X from '@/components/icons/X';
import Text from '@/components/Text';
import { TherapyType } from '@/utils/types';
import {
  therapyTypeToVisitType,
  therapyTypeToDescription,
  therapyTypes,
  whenToSeePsychLink,
  typesOfTherapyLink,
} from '@/utils/constants';
import RadioButton from '@/components/RadioButton';

interface AddCareTypeModalProps {
  availableCareTypes: TherapyType[];
  existingCareTypes: TherapyType[];
  onClose?: () => void;
  preSelected?: TherapyType;
  hidePsych: boolean;
  providerPreviouslyRemoved?: boolean;
  trackNextClick: (therapyType: TherapyType) => void;
}

const SelectCareCopy = ({
  hidePsych,
  providerPreviouslyRemoved,
}: {
  hidePsych: boolean;
  providerPreviouslyRemoved: boolean;
}) => {
  if (providerPreviouslyRemoved) {
    return (
      <span>
        Your provider has already been removed from your care team. Select a
        care type to explore new providers.
      </span>
    );
  }
  return hidePsych ? (
    <span>
      Find a provider who specializes in the right{' '}
      <a
        target="_blank"
        className="text-sky-50 no-underline"
        href={typesOfTherapyLink}
      >
        type of therapy
      </a>{' '}
      for you.
    </span>
  ) : (
    <span>
      Find a provider who specializes in{' '}
      <a
        target="_blank"
        className="text-sky-50 no-underline"
        href={whenToSeePsychLink}
      >
        psychiatry
      </a>{' '}
      (medication management) or other{' '}
      <a
        target="_blank"
        className="text-sky-50 no-underline"
        href={typesOfTherapyLink}
      >
        types of therapy
      </a>
    </span>
  );
};

const RadioContent = ({
  type,
  canAdd,
  hasCareType,
}: {
  type: TherapyType;
  canAdd: boolean;
  hasCareType: boolean;
}) => {
  return (
    <div className={canAdd ? '' : 'text-grey-200'}>
      <div className="flex justify-between">
        <Text.P.Bold>{therapyTypeToVisitType[type]}</Text.P.Bold>
        {hasCareType ? (
          <RulaBadge style="Default" size="small">
            Active
          </RulaBadge>
        ) : null}
      </div>
      <Text.P className="text-grey-500">
        {therapyTypeToDescription[type]}
      </Text.P>
    </div>
  );
};

const AddCareTypeModal = ({
  availableCareTypes,
  existingCareTypes,
  onClose,
  preSelected,
  hidePsych = false,
  providerPreviouslyRemoved = false,
  trackNextClick,
}: AddCareTypeModalProps) => {
  const [selectedCareType, setSelectedCareType] = useState<TherapyType | ''>(
    preSelected || '',
  );
  const { dispatch } = useContext(StateContext);

  const navigate = useNavigate();

  const trackAndNavigateToProviderSearch = () => {
    trackNextClick(selectedCareType as TherapyType);
    gotoProviderSearch();
  };
  const gotoProviderSearch = () => {
    if (selectedCareType) {
      navigate(
        `/care-team/${selectedCareType}/add-new-care-type/find-new-provider`,
      );
    }
  };

  const defaultOnClose = () => dispatch(actions.setModal(null));

  return (
    <div className="max-w-[320px] overflow-auto max-h-[calc(100svh-48px)] rounded p-6 bg-white md:max-w-[564px]">
      <div className="flex flex-col justify-between align-top space-y-6">
        <div className="flex justify-between items-center">
          <Text.H4>
            {providerPreviouslyRemoved
              ? 'Select a care type to find a new provider'
              : 'Add a new care type'}
          </Text.H4>
          <X
            role="img"
            onClick={onClose || defaultOnClose}
            className="cursor-pointer"
          ></X>
        </div>
        <Text.P>
          <SelectCareCopy
            hidePsych={hidePsych}
            providerPreviouslyRemoved={providerPreviouslyRemoved}
          />
        </Text.P>
        <div className="space-y-2">
          {therapyTypes.map((type) => {
            if (type === 'psychiatric' && hidePsych) return null;
            const canAdd = availableCareTypes.includes(type);
            const hasCareType = existingCareTypes.includes(type);
            const radioContent = (
              <RadioContent
                type={type}
                canAdd={canAdd}
                hasCareType={hasCareType}
              />
            );
            const isActive = selectedCareType === type;
            const borderColor = isActive ? 'border-sky-50' : 'border-grey-200';

            return (
              <div
                key={type}
                className={`p-[10px] border rounded ${borderColor}`}
              >
                <RadioButton
                  disabled={!canAdd}
                  key={type}
                  name={type}
                  value={type}
                  label={therapyTypeToVisitType[type]}
                  onCheck={() => setSelectedCareType(type)}
                  selectedValue={selectedCareType}
                  contentPositionClass="items-left"
                  radioContent={radioContent}
                />
              </div>
            );
          })}
        </div>
        <div className="flex justify-end">
          <div className="basis-[50%]">
            <Button
              variant="primary"
              state={selectedCareType === '' ? 'disabled' : ''}
              onClick={() => trackAndNavigateToProviderSearch()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCareTypeModal;
