import { Dispatch, useState } from 'react';
import actions, { Action } from '@/state/actions';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import Text from '@/components/Text';
import Clock from '@/components/icons/Clock';
import Calendar from '@/components/icons/Calendar';

export type CancelModalProps = {
  cancellationFee?: string;
  isFirstAppointment?: boolean;
  dispatch: Dispatch<Action>;
  cancelAppointment: () => Promise<void>;
  trackEvent?: (name: string) => void;
  appointmentTime: string;
  appointmentDayMonthDate: string;
  providerName: string;
};

const CancelModal = ({
  cancellationFee,
  isFirstAppointment,
  dispatch,
  cancelAppointment,
  trackEvent,
  appointmentTime,
  appointmentDayMonthDate,
  providerName,
}: CancelModalProps) => {
  const [cancelStatus, setCancelStatus] = useState<'' | 'waiting'>('');

  const closeModal = () => {
    dispatch(actions.setModal(null));
  };

  const onComplete = (success = true) => {
    const text = success ? (
      <Text.P>
        Your appointment has been canceled, and your provider has been notified.
      </Text.P>
    ) : (
      <Text.P>Something went wrong</Text.P>
    );
    const variant = success ? 'success' : 'warning';

    setCancelStatus('');
    closeModal();
    dispatch(
      actions.setToast({
        text,
        variant,
        onClose: () => dispatch(actions.setToast(null)),
      }),
    );
  };

  const onCancelClick = () => {
    trackEvent?.('confirm_cancel_appointment_button_clicked');
    setCancelStatus('waiting');
    cancelAppointment()
      .then(() => {
        trackEvent?.('cancel_appointment_successful');
        onComplete();
      })
      .catch(() => onComplete(false));
  };

  const iconRowClasses = 'flex gap-x-2 items-center text-grey-800 md:gap-x-4';
  return (
    <div className="max-w-[320px] rounded p-6 space-y-6 bg-white md:max-w-[520px]">
      <div className="flex justify-between align-top">
        <Text.H4.Bold className="text-[15px] md:text-[18px]">
          Confirm appointment cancellation
        </Text.H4.Bold>
        <X onClick={closeModal} className="cursor-pointer"></X>
      </div>
      <div className="space-y-2 text-grey-800 whitespace-pre-wrap md:space-y-3">
        {cancellationFee ? (
          <Text.P.Bold>{`Your appointment is less than 24 hours away so you'll be charged a ${cancellationFee} cancellation fee.`}</Text.P.Bold>
        ) : null}
        {isFirstAppointment ? (
          <Text.P.Bold>
            Cancelling an initial appointment will also cancel any followup
            appointments with this provider.
          </Text.P.Bold>
        ) : null}
        <Text.P>Are you sure you'd like to cancel this appointment?</Text.P>
      </div>
      <div className="flex flex-col gap-y-2 px-5 py-6 text-grey-800 border-grey-200 rounded border-1 md:px-9">
        <Text.H4.Bold>{providerName}</Text.H4.Bold>
        <div className={iconRowClasses}>
          <Calendar size={20} />
          <Text.P>{appointmentDayMonthDate}</Text.P>
        </div>
        <div className={iconRowClasses}>
          <Clock size={20} />
          <Text.P>{appointmentTime}</Text.P>
        </div>
      </div>
      <div className="flex flex-col space-y-2 max-w-screen-sm md:flex-row md:space-x-2 md:space-y-0">
        <Button
          onClick={closeModal}
          sizeClasses="w-full md:w-[230px]"
          size="large"
        >
          No
        </Button>
        <Button
          onClick={onCancelClick}
          variant="primary"
          size="large"
          sizeClasses="w-full md:w-[230px]"
          state={cancelStatus}
        >
          <span className="whitespace-nowrap">Yes, cancel appointment</span>
        </Button>
      </div>
    </div>
  );
};

export default CancelModal;
