import { Dispatch, useEffect, useState } from 'react';
import RadioButton from '@/components/RadioButton';
import Text from '@/components/Text';
import X from '@/components/icons/X';
import actions from '@/state/actions';
import TextInput from '@/components/TextInput';
import DateInput from '@/components/DateInput';
import { isMmDdYyyy } from '../utils/dates';
import Button, { ButtonState } from '@/components/Button';
import { Action } from '@/state/actions';
import { AuthenticatedState } from '@/utils/types';
import dayjs from 'dayjs';
import fetch from '../utils/fetch';
import SupportLink from '@/components/SupportLink';
import MailToLink from '@/components/MailToLink';
import trackEvent from '@/utils/amplitude';
import { createLinkPatientToUserAccountBody } from './utils/createLinkPatientToUserAccountBody';

export interface VerifyPatientProps {
  state: AuthenticatedState;
  dispatch: Dispatch<Action>;
  isAddingMinor: boolean;
}

const maxAttempts = 3;

const VerifyPatientLink = ({
  state,
  dispatch,
  isAddingMinor,
}: VerifyPatientProps) => {
  const [formatError, setFormatError] = useState<string | undefined>('');
  const [error, setError] = useState<string | undefined>('');
  const [firstName, setFirstName] = useState('');
  const [medicalGuardian, setMedicalGuardian] = useState<'yes' | 'no' | ''>('');

  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [successState, setSuccessState] = useState<
    undefined | 'checking' | 'success'
  >(undefined);

  const success = successState === 'success';
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        window.location.reload(); // refresh the page
      }, 2000); // wait for 2 seconds
    }
  }, [success]);

  useEffect(() => {
    if (isMmDdYyyy(dob) && formatError) {
      setFormatError(undefined);
      setError(undefined);
    }
  }, [dob]);

  const errorMessage = formatError || error;
  const onClose = () => {
    dispatch(actions.setModal(null));
  };

  const verify = () => {
    if (successState === 'checking') {
      return;
    }
    if (!isMmDdYyyy(dob)) {
      setFormatError(
        `Please enter your date of birth in mm/dd/yyyy format, like ${dayjs().format(
          'MM/DD/YYYY',
        )}.`,
      );
      return;
    }
    setSuccessState('checking');

    const body = createLinkPatientToUserAccountBody({
      isAddingMinor,
      email: state.session.email,
      dob,
      firstName,
      lastName,
      medicalGuardian: isAddingMinor ? medicalGuardian === 'yes' : undefined,
    });

    fetch
      .json('/api/link_patient_to_user_account', {
        method: 'POST',
        body,
      })
      .then(() => {
        setSuccessState('success');
        trackEvent({
          product_area: 'Verification',
          name: 'Patient_relationship_verified',
          trigger: 'Interaction',
        });
      })
      .catch((e) => {
        setSuccessState(undefined);
        e.json().then(({ message }: { message: any }) => {
          if (
            message.includes('This patient relationship is already verified.')
          ) {
            setError('This patient relationship is already verified.');
          } else {
            setAttempts(attempts + 1);
            const attemptsLeft = maxAttempts - (attempts + 1);
            setError(
              `The information you entered does not match our records. You have ${attemptsLeft} more attempt${
                attemptsLeft === 1 ? '' : 's'
              }.`,
            );
            if (attemptsLeft === 0) {
              trackEvent({
                product_area: 'Verification',
                name: 'Patient_relationship_verification_failed',
                trigger: 'Interaction',
              });
            }
          }
        });
      });
  };

  const getVerifyButtonState = (): ButtonState => {
    if (successState === 'checking') {
      return 'waiting';
    }
    if (incompleteInfo) {
      return 'disabled';
    }
    return '';
  };

  const incompleteInfo =
    !dob ||
    !firstName ||
    !lastName ||
    (isAddingMinor && medicalGuardian === '');
  const disableButton = successState === 'checking' || incompleteInfo;

  const renderForm = () => {
    return (
      <>
        <div className="flex justify-between text-left">
          <Text.H4>Link profile</Text.H4>
          <X onClick={onClose} className="cursor-pointer"></X>
        </div>
        <div className="text-left">
          <Text.P>
            Please enter the name and date of birth of the person whose
            treatment you manage.
          </Text.P>
        </div>
        <div className="flex space-x-8">
          <div>
            <Text.P.Bold className="text-left">First name</Text.P.Bold>
            <TextInput
              value={firstName}
              onChange={(value) => setFirstName(value)}
              label="First name"
              placeholder="First name"
            />
          </div>
          <div>
            <Text.P.Bold className="text-left">Last name</Text.P.Bold>
            <TextInput
              value={lastName}
              onChange={(value) => setLastName(value)}
              label="Last name"
              placeholder="Last name"
            />
          </div>
        </div>
        <div>
          <Text.P.Bold className="text-left">Date of birth</Text.P.Bold>
          <DateInput
            value={dob}
            placeholder={'MM/DD/YYYY'}
            onEnter={verify}
            onChange={(dob) => setDob(dob)}
          />
        </div>
        {isAddingMinor && (
          <div className="text-left space-y-4">
            <Text.P>
              Will your minor child have a court-appointed Medical Guardian
              after turning 18?
            </Text.P>
            <RadioButton
              name="No"
              value="no"
              label="No"
              onCheck={() => setMedicalGuardian('no')}
              selectedValue={medicalGuardian}
            />
            <RadioButton
              name="Yes"
              value="yes"
              label="Yes"
              onCheck={() => setMedicalGuardian('yes')}
              selectedValue={medicalGuardian}
            />
            {medicalGuardian === 'yes' && (
              <Text.P className="px-6 py-3 bg-sky-10 rounded">
                Please send the court order for our records to{' '}
                <MailToLink
                  email="records@rula.com"
                  noUnderline={false}
                  linkColor="text-black"
                />
              </Text.P>
            )}
          </div>
        )}
        {errorMessage && (
          <>
            <p className="text-left text-red-50 text-sm mt-0">{errorMessage}</p>
            <p className="text-center text-sm mt-0">
              If issues persist, please contact <SupportLink />.
            </p>
          </>
        )}
        <div
          className={`mt-4 ${
            disableButton ? 'opacity-50 pointer-events-none' : ''
          }`}
        >
          <Button
            variant="primary"
            onClick={verify}
            disabled={disableButton}
            state={getVerifyButtonState()}
          >
            <Text.P.Bold>Verify</Text.P.Bold>
          </Button>
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (attempts >= maxAttempts && !success) {
      return (
        <div className="bg-white w-[350px] rounded absolute left-1/2 -translate-x-1/2 text-center p-6">
          <div className="lex flex-col gap-8">
            <Text.H4 className="flex top-0 absolute">
              Unable to link profile
            </Text.H4>
            <X
              onClick={onClose}
              className="cursor-pointer absolute right-[22px] top-0"
            ></X>
            <Text.P className="flex text-left text-grey-800 py-4">
              Please contact our support team for assistance:
            </Text.P>
            <Text.P className="flex text-left">
              <SupportLink />
            </Text.P>
          </div>
        </div>
      );
    }

    if (success) {
      return (
        <div className="bg-white w-[350px] rounded absolute left-1/2 -translate-x-1/2 p-6">
          <Text.H4 className="flex absolute top-0">Success</Text.H4>
          <X
            onClick={() => window.location.reload()}
            className="cursor-pointer absolute right-[22px] top-0"
          ></X>
          <Text.P className="text-grey-800 mt-5 text-left">
            This page will now reload automatically. If it doesn't, click the
            refresh button on your browser.
          </Text.P>
        </div>
      );
    }

    return renderForm();
  };

  return (
    <div className="flex flex-col rounded p-6 space-y-6 bg-white w-[350px]">
      {renderContent()}
    </div>
  );
};

export default VerifyPatientLink;
