import { StateContext } from '@/App';
import { isLoaded } from '@/utils/types';
import { useContext } from 'react';
import { useFlagCheck } from './use-feature-flags';

export const isEnabled = () => {
  const { isAllowed } = useFlagCheck();
  const enableDNS = isAllowed({
    patientPortalEnableDns: true,
  });

  return enableDNS;
};

export const isBookingAllowed = () => {
  const enableDNS = isEnabled();

  if (!enableDNS) {
    return true;
  }

  const { state } = useContext(StateContext);
  const { profile } = state.data;

  if (isLoaded(profile)) {
    return profile.dns_flag !== 'scheduling_restricted_future_visits';
  }
  // Default to not block booking for now
  return true;
};
