import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InfoCard,
  MessageBubble,
  MessageHeader,
  MessageInput,
  MessageThread,
  NoMessages,
  ParticipantList,
  Toast,
} from '@pathccm/path-components-messaging';

import { MobileContext, StateContext } from '@/App';
import Button from '@/components/Button';
import actions from '@/state/actions';
import useData from '@/state/use-data';
import {
  getAllThreads,
  getMessageThread,
  sendMessage,
  setThreadAsRead,
} from '@/utils/api';
import { appointmentIsBefore24HoursFromNow } from '@/utils/dates';
import {
  cannotMessageReason,
  getProviderFromCareTeam,
  getInfoCardProps,
  mapProviderInfo,
} from '@/utils/tools';
import trackEvent from '@/utils/amplitude';
import { AnalyticsEvent, JSONValue, Trigger } from '@/utils/types';
import { isGuardian } from '@/state/reducer';
import NoProviders from '@/pages/messaging/NoProviders';
import MessagingLoader from '@/pages/messaging/MessagingLoader';
import { stateToOfficeKey } from '@/utils/constants';

const Messaging = () => {
  const { WithData, data } = useData([
    'messaging',
    'careTeam_v3',
    'appointments_v2',
    'patientData',
  ]);
  const mobile = useContext(MobileContext);
  const { state, dispatch } = useContext(StateContext);
  const page = state.ui.page;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastStatus, setToastStatus] = useState<'sent' | 'error'>('sent');
  const patientUuid = state.session.accountSelected?.patient_uuid ?? '';
  const activeUserUuid = state.session.accountSelected?.acting_agent_uuid ?? '';
  const [mobileView, setMobileView] = useState<
    'participantList' | 'participantDetail'
  >('participantList');
  const [activeThread, setActiveThread] = useState<MessageThread | undefined>(
    undefined,
  );
  const [activeParticipantUuid, setActiveParticipantUuid] = useState('');
  const [scrollToPreviousMessage, setScrollToPreviousMessage] = useState(false);
  const [messagesScrolled, setMessagesScrolled] = useState(false);
  const messagesRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollToPreviousMessage) {
      messagesRef.current?.scrollIntoView(false);
    }
  }, [activeThread, activeThread?.messages, scrollToPreviousMessage]);

  const scrollToMessage = (offset: number) => {
    if (
      messagesRef.current?.children &&
      messagesRef.current?.children[offset]
    ) {
      setScrollToPreviousMessage(true);
      messagesRef.current?.children[offset].scrollIntoView(false);
    }
  };

  if (page.path !== 'messages') {
    return null;
  }

  return (
    <WithData data={data}>
      {({
        messaging,
        careTeam_v3: careTeam,
        appointments_v2: appointmentsV2,
        patientData,
      }) => {
        const [message, setMessage] = useState('');
        const { providerUuid } = useParams();
        const { threads } = messaging;
        const { location } = patientData;
        const nextApptWithActiveParticipant = appointmentsV2.rows.filter(
          ({ provider_ref: providerRef, status }) =>
            providerRef.includes(activeParticipantUuid ?? '') &&
            status !== 'canceled' &&
            status !== 'deleted',
        )[0];

        const providers = mapProviderInfo(careTeam.active, threads);
        const pastProviders = mapProviderInfo(
          careTeam.inactive,
          threads,
        ).filter(({ uuid, deactivationEvents }) => {
          // If we have messaged the provider and the provider is not
          // also in the active provider list put them in the pastProviders;
          // if we have not messaged the provider and the most recent deactivation
          // event type is 'not_seen', put the provider in the pastProviders
          return (
            threads.find(
              ({ providerUuid }) =>
                providerUuid === uuid &&
                !careTeam.active.find(
                  ({ uuid: providerUuid }) => providerUuid === uuid,
                ),
            ) ||
            (!!deactivationEvents.length &&
              deactivationEvents[0].event_type === 'not_seen')
          );
        });
        const allProviders = [...providers, ...pastProviders];
        const participant = allProviders.find(
          ({ uuid }) => uuid === activeParticipantUuid,
        );
        const earliestAppointmentIsBefore24HoursFromNow =
          (participant &&
            participant.earliestActiveAppointmentStart &&
            appointmentIsBefore24HoursFromNow(
              participant.earliestActiveAppointmentStart,
            )) ||
          false;

        const handleScroll = (e: SyntheticEvent) => {
          const target = e.target as HTMLElement;
          if (!messagesScrolled && target.scrollTop > 0) {
            setMessagesScrolled(true);
          } else if (messagesScrolled && target.scrollTop === 0) {
            setMessagesScrolled(false);
          }
        };

        const getMessagingEvent = (
          participantUuid: string,
          name: string,
          trigger: Trigger,
          rest: Record<string, JSONValue> = {},
        ): AnalyticsEvent => {
          const participant = getProviderFromCareTeam(
            careTeam,
            participantUuid,
          );
          return {
            product_area: 'Messages',
            name,
            trigger,
            metadata: {
              participant_type: isGuardian(state)
                ? 'parent_guardian'
                : 'patient',
              provider_name: `${participant?.first_name} ${participant?.last_name}`,
              provider_npi: participant?.npi || '',
              treatment_type: nextApptWithActiveParticipant?.therapy_type || '',
              ...rest,
            },
          };
        };

        const getAndUpdateThreads = async () => {
          const threads = await getAllThreads(patientUuid);
          dispatch(actions.messaging.setMessageThreads(threads));
        };

        const onGoToMessages = async (
          messageThread: MessageThread | undefined,
          participantUuid: string,
          triggerMarkAsRead = true,
        ) => {
          setLoading(true);
          let thread;
          if (messageThread && messageThread.uuid) {
            try {
              thread = await getMessageThread(messageThread.uuid, patientUuid);

              if (triggerMarkAsRead) {
                await setThreadAsRead(messageThread.uuid, patientUuid);
                void getAndUpdateThreads();
              }
            } catch (e) {
              // some error handling here with the notification component?
              console.log(e);
            }
          } else {
            thread = {
              uuid: '',
              providerUuid: participantUuid,
              patientUuid: patientUuid,
              messages: [],
            };
          }
          setLoading(false);
          setActiveThread(thread);
          setActiveParticipantUuid(participantUuid);

          if (!mobile && participantUuid) {
            navigate('/messages');
          }

          trackEvent(
            getMessagingEvent(
              participantUuid,
              'Messages_Thread_Viewed',
              'Interaction',
              {
                messages_read_count: messageThread?.unreadCount ?? 0,
                cannot_message_reason: cannotMessageReason(
                  careTeam,
                  participantUuid,
                ),
              },
            ),
          );
        };

        const getMoreMessages = async () => {
          if (
            activeThread &&
            activeThread.hasMore &&
            activeThread.nextTimestamp &&
            activeThread.uuid
          ) {
            try {
              const response = await getMessageThread(
                activeThread.uuid,
                patientUuid,
                activeThread.nextTimestamp.toISOString(),
              );
              const messagesLength = response.messages.length;

              setActiveThread({
                ...response,
                ...{
                  messages: [
                    ...(response.messages || []),
                    ...(activeThread.messages || []),
                  ],
                },
              });

              scrollToMessage(messagesLength ? messagesLength - 1 : 0);
            } catch (e) {
              console.error(e);
            }
          }
        };

        const onInput = async (messageText: string) => {
          const thread: {
            message: string;
            patient_uuid: string;
            provider_uuid?: string;
            thread_id?: string;
          } = {
            message: messageText,
            patient_uuid: patientUuid,
            provider_uuid: activeParticipantUuid,
            thread_id: activeThread?.uuid,
          };

          trackEvent(
            getMessagingEvent(
              activeParticipantUuid,
              'Send_Button_Clicked',
              'Interaction',
            ),
          );

          try {
            const res = await sendMessage(thread);

            await getAndUpdateThreads();
            setScrollToPreviousMessage(false);
            setToastOpen(true);
            setToastStatus('sent');

            if (!res.messageThreadUuid) {
              throw new Error(
                'Error getting message thread uuid from response',
              );
            }

            const updatedThread = await getMessageThread(
              res.messageThreadUuid,
              patientUuid,
            );

            setActiveThread(updatedThread);

            trackEvent(
              getMessagingEvent(
                activeParticipantUuid,
                'Message_Send_Successful',
                'Automation',
                {
                  message_before_initial_appointment:
                    earliestAppointmentIsBefore24HoursFromNow,
                },
              ),
            );
          } catch (e) {
            setToastOpen(true);
            setToastStatus('error');
            console.error(e);
          }
        };

        useEffect(() => {
          trackEvent({
            product_area: 'Messages',
            name: 'Messages_Tab_Viewed',
            trigger: 'Page load',
          });
        }, []);

        useEffect(() => {
          let initialThread: MessageThread | undefined;

          const handleDefaultProvider = () => {
            const { uuid } = providers[0];
            initialThread = threads.find((t) => t.providerUuid === uuid);
            setActiveParticipantUuid(uuid);
          };

          const handleInvalidProvider = () => {
            navigate('/messages');
            if (!mobile) {
              handleDefaultProvider();
            }
          };

          if (providerUuid) {
            const isValidProvider = getProviderFromCareTeam(
              careTeam,
              providerUuid,
            );

            if (!isValidProvider) {
              handleInvalidProvider();
            } else {
              initialThread = threads.find(
                (t) => t.providerUuid === providerUuid,
              );
              setActiveParticipantUuid(providerUuid);
            }
          } else if (providers.length && !mobile) {
            handleDefaultProvider();
          }

          if (initialThread) {
            void onGoToMessages(
              initialThread,
              initialThread.providerUuid,
              !mobile,
            );
          }
        }, [mobile]);

        // We only care about providerUuid parameter changes for mobile view
        // because we utilize the different component layouts as pseudo pages
        useEffect(() => {
          if (mobile) {
            if (!providerUuid) {
              setMobileView('participantList');
              setActiveParticipantUuid('');
            } else {
              setMobileView('participantDetail');

              const initialThread = threads.find(
                (t) => t.providerUuid === providerUuid,
              );

              void onGoToMessages(initialThread, providerUuid);
            }
          }
        }, [mobile, providerUuid]);

        if (
          !careTeam ||
          (!careTeam.active.length && !careTeam.inactive.length)
        ) {
          return <NoProviders fullScreen />;
        }

        const participantInCareTeam = [
          ...careTeam.active,
          ...careTeam.inactive,
        ].find(({ uuid: providerRef }) =>
          providerRef.includes(activeParticipantUuid ?? ''),
        );

        const isPsych =
          (participantInCareTeam &&
            participantInCareTeam.role === 'PRESCRIBER') ||
          false;

        // Show Info Card if provider does not have a portal account or provider is psych
        const showInfoCard =
          participantInCareTeam &&
          (!participantInCareTeam?.portal_account_active || isPsych);

        const isInactiveProvider =
          pastProviders.findIndex(
            ({ uuid }) => uuid === activeParticipantUuid,
          ) > -1;

        const useShadowsInChatBox = messagesScrolled && mobile;

        const participantListView = (
          <div className="w-full md:max-w-[350px] overflow-auto">
            <ParticipantList
              isPatientUi
              participants={providers}
              inactiveParticipants={pastProviders}
              currentMessageThreadUuid={activeThread?.uuid ?? ''}
              messageThreads={messaging.threads}
              goToMessages={({ messageThread, participantUuid }) => {
                if (mobile) {
                  setMobileView('participantDetail');
                  navigate(`/messages/${participantUuid}`);
                } else {
                  void onGoToMessages(messageThread, participantUuid);
                }
              }}
              activeUuid={activeParticipantUuid}
            />
          </div>
        );

        const participantDetailView =
          loading && (!participant || !activeThread?.messages?.length) ? (
            <MessagingLoader />
          ) : (
            <div className="flex flex-col flex-grow justify-center relative min-w-0 w-full">
              {participant ? (
                <>
                  {showInfoCard && !mobile ? null : (
                    <MessageHeader
                      customClass={`border-t-0 ${
                        useShadowsInChatBox ? 'shadow-cardBottom' : ''
                      }`}
                      participant={participant}
                      isPatientUi={true}
                      isInactive={isInactiveProvider}
                      gotoMessageHome={() => {
                        if (mobile) {
                          navigate('/messages');
                        }
                      }}
                    />
                  )}
                  {showInfoCard ? (
                    <div className="bg-cream-50 basis-full flex items-center">
                      <InfoCard
                        hideParticipantInfo={mobile}
                        format="vertical"
                        size="large"
                        {...participant}
                        {...getInfoCardProps({
                          provider: participantInCareTeam,
                          isPsych,
                          officeKey: stateToOfficeKey[location],
                        })}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className="bg-cream-50 p-4 basis-full overflow-auto"
                        onScroll={handleScroll}
                      >
                        {activeThread?.messages?.length ? (
                          <div ref={messagesRef} className="scroll-mb-8">
                            {activeThread?.hasMore && (
                              <div className="flex justify-center mb-6">
                                <Button
                                  sizeClasses="bg-white rounded-sm border-1 border-grey-200"
                                  variant="flat"
                                  onClick={getMoreMessages}
                                >
                                  Load previous messages
                                </Button>
                              </div>
                            )}
                            {activeThread.messages.map((message, idx) => (
                              <MessageBubble
                                key={idx}
                                {...message}
                                providerName={participantInCareTeam?.first_name}
                                activeUserUuid={activeUserUuid}
                                messageThreadPatientUuid={
                                  activeThread.patientUuid
                                }
                                messageThreadProviderUuid={
                                  activeThread.providerUuid
                                }
                                isPatientUi
                              />
                            ))}
                          </div>
                        ) : (
                          <NoMessages participantType="patient" />
                        )}
                      </div>
                      {toastOpen && (
                        <div
                          className="flex justify-center absolute left-0 w-full"
                          style={{ bottom: mobile ? '170px' : '260px' }}
                        >
                          <Toast
                            open={toastOpen}
                            setToastOpen={setToastOpen}
                            messageTo="provider"
                            status={toastStatus}
                          />
                        </div>
                      )}
                      <div
                        className={`${
                          useShadowsInChatBox ? 'shadow-cardTop' : ''
                        }`}
                      >
                        <MessageInput
                          name={
                            participantInCareTeam
                              ? `${participantInCareTeam.first_name} ${participantInCareTeam.last_name}`
                              : ''
                          }
                          onInput={onInput}
                          showEmergencyText={true}
                          autoFocus={!mobile}
                          message={message}
                          setMessage={setMessage}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <NoProviders />
              )}
            </div>
          );

        const getMobileView = () =>
          mobileView === 'participantList'
            ? participantListView
            : participantDetailView;

        const getDesktopView = () => (
          <>
            {participantListView}
            {participantDetailView}
          </>
        );

        return (
          <div className="flex h-[calc(100svh-48px)] -m-5 md:-my-9 md:-mx-12 md:h-[calc(100svh-80px)]">
            {mobile ? getMobileView() : getDesktopView()}
          </div>
        );
      }}
    </WithData>
  );
};

export default Messaging;
