import { useState } from 'react';
import AccountActionSuccess from './AccountActionSuccess';
import Text from '@/components/Text';
import RulaButton from '@pathccm/rula-ui/button';

type ComponentState =
  | 'select-account'
  | 'transfer-account'
  | 'declare-guardian';

interface DeclareGuardianProps {
  patientUuid: string;
  patientName: string;
  setAccountPageView: (componentState: ComponentState) => void;
}

const DeclareGuardian = ({
  patientName,
  setAccountPageView,
}: DeclareGuardianProps) => {
  const [view, setView] = useState<
    'declare-guardian' | 'declare-guardian-success'
  >('declare-guardian');

  const handleSubmit = () => {
    // todo: actually submit the guardian declaration to api call
    setView('declare-guardian-success');
  };

  if (view === 'declare-guardian-success') {
    return (
      <AccountActionSuccess
        copy="Your decision to declare medical guardianship has been recorded."
        backToAccountPage={() => setAccountPageView('select-account')}
        isGuardianFlow
      />
    );
  }

  return (
    <div className="flex flex-col justify-center w-full max-w-[305px] items-center gap-6 text-center">
      <Text.H1>Medical guardianship</Text.H1>
      <Text.P>
        You’ve chosen to declare medical guardianship of {patientName} beyond
        their 18th birthday. If this is correct, please confirm your decision
        and follow the instructions on the following page.
      </Text.P>
      <RulaButton
        onPress={handleSubmit}
        size="Large"
        className="w-full"
        style="Primary"
      >
        Confirm
      </RulaButton>
      <RulaButton
        style="Link"
        size="Medium"
        onPress={() => setAccountPageView('transfer-account')}
      >
        &larr; Back
      </RulaButton>
    </div>
  );
};
export default DeclareGuardian;
