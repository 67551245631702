import { useContext, useEffect, useMemo } from 'react';
import { StateContext } from '@/App';
import {
  AnalyticsEvent,
  CreditCardDetails,
  Editable,
  EditableCreditCardDetails,
  EditableInsuranceDetails,
  EditableProfileDetails,
  InsuranceDetails,
  isLoaded,
  isPendingLoad,
  ProfileDetails,
  ServerData,
} from '@/utils/types';
import actions from '@/state/actions';
import dayjs from 'dayjs';
import trackEvent from './amplitude';
import { useFlagCheck } from './use-feature-flags';
import PersonalDetailsBanner from '@/components/PersonalDetailsBanner';
import { isBookingAllowed } from '@/utils/use-dns-flag';
import DNSBanner from '@/components/DNSBanner';

export const checkCreditCardValid = (
  creditCard: ServerData<
    Editable<CreditCardDetails, EditableCreditCardDetails>
  >,
) => {
  const currentDate = dayjs();
  if (isLoaded(creditCard)) {
    if (
      Object.keys(creditCard).length &&
      creditCard.expiration_year !== undefined &&
      creditCard.expiration_month !== undefined
    ) {
      const cardExpirationDate = dayjs()
        .year(parseInt(`20${creditCard.expiration_year}`))
        .month(parseInt(creditCard.expiration_month) - 1);
      return (
        cardExpirationDate.isAfter(currentDate) ||
        cardExpirationDate.isSame(currentDate, 'month')
      );
    }
  }
  if (isPendingLoad(creditCard)) {
    return null;
  }
  return false;
};
export const checkProfileDetailsValid = (
  profile: ServerData<Editable<ProfileDetails, EditableProfileDetails>>,
) => {
  if (isLoaded(profile)) {
    const requiredFields: (keyof ProfileDetails)[] = [
      'first_name',
      'last_name',
      'birthdate',
      'address',
      'email',
      'location',
      'phone',
    ];

    for (const field of requiredFields) {
      if (!profile[field] || profile[field] === '') {
        return false;
      }
    }
    return true;
  }

  if (isPendingLoad(profile)) {
    return null;
  }

  return false;
};

export const checkInsuranceDetailsValid = (
  insurance: ServerData<Editable<InsuranceDetails, EditableInsuranceDetails>>,
  profile: ServerData<Editable<ProfileDetails, EditableProfileDetails>>,
) => {
  const requiredFields: (keyof InsuranceDetails)[] = ['payment_method'];
  if (isLoaded(insurance) && isLoaded(profile)) {
    if (insurance.payment_method !== 'Self Pay') {
      requiredFields.push(
        'carrier',
        'display_name',
        'network_name',
        'subscriber_id',
        'relationship_to_insured',
      );
    }

    for (const field of requiredFields) {
      if (!insurance[field] || insurance[field] === '') {
        return false;
      }
    }

    if (!profile['sex_assigned_at_birth']) {
      return false;
    }

    return true;
  }

  if (isPendingLoad(insurance) || isPendingLoad(profile)) {
    return null;
  }

  return false;
};

const trackBannerEvent = (
  reasonShown: string[],
  eventName = 'Missing_Info_Banner_viewed',
) => {
  const bannerRendered: AnalyticsEvent = {
    product_area: 'Profile',
    name: eventName,
    trigger: 'Page load',
    metadata: {
      reason_shown: reasonShown,
    },
  };
  trackEvent(bannerRendered);
};

// Custom hook to manage status banner (if applicable) and provides
// quick access for whether personal details are valid from context
export const usePersonalDetails = (manageStatusBanner = false) => {
  const { state, dispatch } = useContext(StateContext);
  const { creditCard, profile, insurance } = state.data;
  const creditCardIsValid = useMemo(
    () => checkCreditCardValid(creditCard),
    [creditCard],
  );
  const profileDetailsAreValid = useMemo(
    () => checkProfileDetailsValid(profile),
    [profile],
  );
  const insuranceDetailsAreValid = useMemo(
    () => checkInsuranceDetailsValid(insurance, profile),
    [insurance],
  );
  // null is being used to indicate that the relevant data is still loading
  let personalDetailsAreValid: null | boolean = null;
  if (
    creditCardIsValid !== null &&
    profileDetailsAreValid !== null &&
    insuranceDetailsAreValid !== null
  ) {
    personalDetailsAreValid =
      creditCardIsValid && profileDetailsAreValid && insuranceDetailsAreValid;
  }
  const detailsToEdit: ('creditCard' | 'profile' | 'insurance')[] = [];
  if (!creditCardIsValid) detailsToEdit.push('creditCard');
  if (!profileDetailsAreValid) detailsToEdit.push('profile');
  if (!insuranceDetailsAreValid) detailsToEdit.push('insurance');
  const { isAllowed } = useFlagCheck();
  const hidePersonalDetailsBanner = isAllowed({
    patientPortalHidePersonalDetailsBanner: true,
  });

  const canBook = isBookingAllowed();

  useEffect(() => {
    if (manageStatusBanner) {
      // logic for invalid credit card banner
      if (personalDetailsAreValid && canBook) {
        if (state.ui.banner) {
          setTimeout(() => {
            dispatch(actions.setBanner(null));
          }, 1000);
        }
        // We only want to check if this is false and not null (since null is loading)
      } else if (personalDetailsAreValid === false) {
        // we want to hide the personal details banner for some small percentage of test users
        // e.g. for Rula content creation team so they can record videos of the portal on sandbox
        if (!hidePersonalDetailsBanner) {
          dispatch(
            actions.setBanner({
              children: <PersonalDetailsBanner detailsToEdit={detailsToEdit} />,
            }),
          );
          trackBannerEvent(detailsToEdit);
        }
      } else if (!canBook) {
        dispatch(
          actions.setBanner({
            children: <DNSBanner />,
          }),
        );
        trackBannerEvent(['dns'], 'DNS_Banner_viewed');
      }
    }
  }, [creditCard, profile, insurance]);

  return {
    personalDetailsAreValid,
    hidePersonalDetailsBanner,
  };
};
