import InfoCircle from '@/components/icons/InfoCircle';

export const UnresponsiveTemplate = () => {
  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-grey-800 flex items-center gap-2.5">
        <InfoCircle className="min-w-5" />
        Sorry, but we're having trouble accessing your cost estimate
        information. Please try again later.
      </div>
    </div>
  );
};
