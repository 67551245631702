import React from 'react';
import PlaceholderHeadshot from '@/components/assets/PlaceholderHeadshot';
import { twMerge } from 'tailwind-merge';

export interface ProviderHeadshotProps {
  profileImage?: string | undefined | null;
  className?: string;
  verticalAlignment?: 'top' | 'middle' | 'bottom';
}

const ProviderHeadshot = ({
  profileImage,
  className,
  verticalAlignment,
}: ProviderHeadshotProps) => {
  const imgClasses = twMerge(`rounded-full object-cover`, className);
  const getAlignmentClasses = () => {
    switch (verticalAlignment) {
      case 'top':
        return 'md:self-start';
      case 'bottom':
        return 'md:self-end';
      default:
        return 'md:self-center';
    }
  };

  const divClasses = twMerge(`min-w-max`, getAlignmentClasses());
  if (profileImage) {
    return (
      <div className={divClasses}>
        <img src={profileImage} className={imgClasses} />
      </div>
    );
  }

  return <PlaceholderHeadshot className={imgClasses} />;
};

export default ProviderHeadshot;
