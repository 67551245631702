import { Dispatch } from 'react';
import actions, { Action } from '@/state/actions';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import Text from '@/components/Text';

export type CancelModalProps = {
  dispatch: Dispatch<Action>;
  onCancel?: () => void;
  onConfirm?: () => void;
  title: string;
  description: string;
  cancelText?: string;
  confirmText?: string;
};

const ConfirmModal = ({
  dispatch,
  onCancel,
  onConfirm,
  title,
  description,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}: CancelModalProps) => {
  const closeModal = () => {
    dispatch(actions.setModal(null));
  };

  const cancel = () => {
    closeModal();
    onCancel?.();
  };
  const confirm = () => {
    onConfirm?.();
    closeModal();
  };

  return (
    <>
      <div
        className="fixed left-0 top-0 w-screen h-screen flex justify-center items-center z-10"
        onClick={cancel}
      ></div>
      <div className=" w-[606px] max-w-[90vw] rounded p-6 space-y-6 bg-white relative z-50">
        <div className="flex justify-between align-top">
          <Text.H4.Bold className="text-[15px] md:text-[18px]">
            {title}
          </Text.H4.Bold>
          <X onClick={cancel} className="cursor-pointer"></X>
        </div>
        <div className="space-y-2 text-grey-800 whitespace-pre-wrap md:space-y-3">
          {description}
        </div>
        <div className="max-w-screen-sm flex flex-row justify-end space-x-2">
          <Button onClick={cancel} size="large" variant="flat">
            {cancelText}
          </Button>
          <Button onClick={confirm} variant="primary" size="large">
            <span className="whitespace-nowrap">{confirmText}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
