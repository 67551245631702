import { dayLabels, daysOfWeek, timeSlots } from '../utils/constants';
import { DayOfWeek, Availability } from '../utils/types';
import Button from './Button';
import Checkbox from './Checkbox';
import Text from './Text';

export interface AvailabilityPickerProps {
  value: Availability | undefined;
  onChange: (availability: Availability) => unknown;
  onClose: () => unknown;
}

const defaultAvailability: Availability = {
  days: 'any',
  preferredTimes: 'any',
};

const DayButton = ({
  day,
  selected,
  onAdd,
  onRemove,
}: {
  day: DayOfWeek;
  selected: boolean;
  onAdd: (day: DayOfWeek) => unknown;
  onRemove: (day: DayOfWeek) => unknown;
}) => {
  return (
    <div
      className={`w-full min-w-[1.5rem] max-w-[2rem] md:w-[2.25rem] md:min-w-[2.25rem] aspect-square flex justify-center items-center cursor-pointer rounded-full border-1 ${
        selected ? 'border-sky-50 text-sky-50 bg-sky-10' : 'border-grey-200'
      }`}
      onClick={selected ? () => onRemove(day) : () => onAdd(day)}
    >
      <Text.Small.Bold>{day}</Text.Small.Bold>
    </div>
  );
};

const SlotPicker = ({
  day,
  preferredTimes,
  onAddSlot,
  onRemoveSlot,
  onCopy,
}: {
  day: DayOfWeek;
  preferredTimes: 'any' | (keyof typeof timeSlots)[];
  onAddSlot: (slot: keyof typeof timeSlots) => unknown;
  onRemoveSlot: (slot: keyof typeof timeSlots) => unknown;
  onCopy: (day: DayOfWeek) => unknown;
}) => {
  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <Text.Small.Bold>{dayLabels[day]}</Text.Small.Bold>
        <Text.Small
          onClick={() => onCopy(day)}
          className="cursor-pointer text-sky-50 hover:text-sky-60"
        >
          Copy
        </Text.Small>
      </div>
      {(
        [
          ['Morning', 'Before 12PM'],
          ['Afternoon', '12PM to 4PM'],
          ['Evening', 'After 4PM'],
        ] as const
      ).map(([slot, label]) => (
        <div key={slot} className="flex gap-x-2">
          <Checkbox
            checked={preferredTimes === 'any' || preferredTimes.includes(slot)}
            onCheck={() => onAddSlot(slot)}
            onUncheck={() => onRemoveSlot(slot)}
            label={slot}
          />
          <Text.P className="text-grey-400">{label}</Text.P>
        </div>
      ))}
    </div>
  );
};

const AvailabilityPicker = ({
  value = defaultAvailability,
  onChange,
  onClose,
}: AvailabilityPickerProps) => {
  const { days, preferredTimes } = value;
  const addDay = (day: DayOfWeek) => {
    if (value.days === 'any') {
      return;
    }
    onChange({
      ...value,
      days: [...value.days, day],
    });
  };
  const removeDay = (day: DayOfWeek) => {
    const currentDays = value.days === 'any' ? daysOfWeek : value.days;
    onChange({
      ...value,
      days: currentDays.filter((d) => d !== day),
    });
  };
  const addSlot = (day: DayOfWeek) => (slot: keyof typeof timeSlots) => {
    if (value.preferredTimes === 'any') {
      return;
    }
    onChange({
      ...value,
      preferredTimes: {
        ...value.preferredTimes,
        [day]: [...(value.preferredTimes[day] || []), slot],
      },
    });
  };
  const removeSlot = (day: DayOfWeek) => (slot: keyof typeof timeSlots) => {
    const currentDays = value.days === 'any' ? [...daysOfWeek] : value.days;
    const currentSlots =
      value.preferredTimes === 'any'
        ? currentDays.reduce(
            (curr, d) => {
              curr[d] = ['Morning', 'Afternoon', 'Evening'];
              return curr;
            },
            {} as Partial<Record<DayOfWeek, (keyof typeof timeSlots)[]>>,
          )
        : value.preferredTimes;
    const newSlots = {
      ...currentSlots,
      [day]: currentSlots[day]?.filter((s) => s !== slot) ?? [],
    };
    onChange({
      ...value,
      preferredTimes: newSlots,
    });
  };
  const copyDayToOthers = (day: DayOfWeek) => {
    if (value.preferredTimes === 'any') {
      return;
    }
    const template = value.preferredTimes[day]!;
    const currentDays = value.days === 'any' ? [...daysOfWeek] : value.days;
    onChange({
      ...value,
      preferredTimes: currentDays.reduce(
        (o, d) => {
          o[d] = template;
          return o;
        },
        {} as Partial<Record<DayOfWeek, (keyof typeof timeSlots)[]>>,
      ),
    });
  };

  return (
    <section className="w-full rounded border-1  relative bg-white">
      <div className="h-[384px] p-4 overflow-auto">
        {/* I think this looks better with the scrollbar always present rather than changing the width when the vertical content overflows. It's a hack, and if we decide to change it remove this div. */}
        <div className="h-[353px]">
          <div className="flex justify-between items-center mb-5">
            <Text.P.Bold>Days of the week</Text.P.Bold>
            {days === 'any' ? (
              <a
                className="no-underline text-sky-50 hover:text-sky-60"
                onClick={() => {
                  onChange({ ...value, days: [] });
                }}
              >
                Clear all
              </a>
            ) : (
              <a
                className="no-underline text-sky-50 hover:text-sky-60"
                onClick={() => {
                  onChange({ ...value, days: 'any' });
                }}
              >
                Select all
              </a>
            )}
          </div>
          <div className="flex gap-2 flex-1 md:flex-0 mb-6">
            {daysOfWeek.map((day) => (
              <DayButton
                key={day}
                day={day}
                selected={days === 'any' || days.includes(day)}
                onAdd={addDay}
                onRemove={removeDay}
              />
            ))}
          </div>
          <div className="w-full border-b-1 border-grey-200 mb-6"></div>
          <div className="flex justify-between items-center mb-5">
            <Text.P.Bold>Times of day</Text.P.Bold>
            {(days === 'any' || days.length) &&
              (preferredTimes === 'any' ? (
                <a
                  className="no-underline text-sky-50 hover:text-sky-60"
                  onClick={() => {
                    onChange({ ...value, preferredTimes: {} });
                  }}
                >
                  Clear all
                </a>
              ) : (
                <a
                  className="no-underline text-sky-50 hover:text-sky-60"
                  onClick={() => {
                    onChange({ ...value, preferredTimes: 'any' });
                  }}
                >
                  Select all
                </a>
              ))}
          </div>
          <div className="space-y-3">
            {daysOfWeek.map((day) => {
              if (days === 'any' || days.includes(day)) {
                return (
                  <SlotPicker
                    key={day}
                    onAddSlot={addSlot(day)}
                    onRemoveSlot={removeSlot(day)}
                    onCopy={copyDayToOthers}
                    day={day}
                    preferredTimes={
                      preferredTimes === 'any'
                        ? 'any'
                        : (preferredTimes[day] ?? [])
                    }
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
      <footer className="h-20 w-full bg-white p-4 flex justify-end shadow-cardBottom rounded-b">
        <Button variant="primary" onClick={onClose}>
          Save
        </Button>
      </footer>
    </section>
  );
};

export default AvailabilityPicker;
