import { useContext, useEffect, useMemo, useState } from 'react';
import { MobileContext, StateContext } from '@/App';
import Dollar from '@/components/icons/Dollar';
import RadioButton from '@/components/RadioButton';
import Text from '@/components/Text';
import actions from '@/state/actions';
import {
  AuthenticatedState,
  CarrierOption,
  Data,
  ExtractEditing,
  InsuranceDetails,
  InsurancePageState,
  isEditing,
  JSONValue,
  PatientData,
  ProfilePageState,
} from '@/utils/types';
import useValidation, { ValidationError } from '@/utils/use-validation';
import fetch from '@/utils/fetch';
import EditSection from './EditSection';
import ReadSection from './ReadSection';
import setDefaults from '@/utils/set-defaults';
import dayjs from 'dayjs';
import { toBase64 } from '@/utils/file';
import tryFunc from '@/utils/try-func';
import { parseMmDdYyyy } from '@/utils/dates';
import InfoCircle from '@/components/icons/InfoCircle';
import trackEvent from '@/utils/amplitude';
import { useFlagCheck } from '@/utils/use-feature-flags';
import Button from '@/components/Button';
import { InsuranceForm } from './InsuranceForm';
import { AddInsuranceModal } from './AddInsuranceModal';
import ConfirmModal from './ConfirmModal';
import { AddSecondaryInsuranceCard } from './InsuranceCards/AddSecondaryInsuranceCard';
import { UpcomingInsuranceSection } from './InsuranceCards/UpcomingInsuranceSection';
import { CurrentInsuranceSection } from './InsuranceCards/CurrentInsuranceSection';
import { CashPaySection } from './InsuranceCards/CashPaySection';
import { useNavigate, useSearchParams } from 'react-router-dom';
export interface InsuranceProps {
  insurance: Extract<
    AuthenticatedState['data']['insurance'],
    { loadingState: 'done' }
  >;
  upcomingInsurance: Extract<
    AuthenticatedState['data']['upcomingInsurance'],
    { loadingState: 'done' }
  >;
  carriers: CarrierOption[];
  patientData: PatientData;
  pageState: ProfilePageState | InsurancePageState;
  fullWidth?: boolean;
}

const Read = ({
  payment_method,
  currentInsurance,
  upcomingInsurance,
  fullWidth,
}: {
  payment_method: string;
  currentInsurance: InsuranceDetails;
  upcomingInsurance: InsuranceDetails;
  fullWidth?: boolean;
}) => {
  const { dispatch } = useContext(StateContext);
  const { isAllowed } = useFlagCheck();
  const effectiveDatesAllowed = isAllowed({
    patientPortalEffectiveDates: true,
  });

  return (
    <ReadSection
      label={effectiveDatesAllowed ? 'Insurance' : 'Medical billing'}
      editLabel={
        payment_method === 'Health Insurance' ? 'Edit' : 'Add insurance'
      }
      onEditClick={() =>
        dispatch(
          actions.profile.setInsuranceEditMode('edit', effectiveDatesAllowed),
        )
      }
      fullWidth={fullWidth}
    >
      {effectiveDatesAllowed ? (
        <div className="flex flex-col gap-4">
          {effectiveDatesAllowed && (
            <Text.P className="text-grey-800">
              Manage your existing coverage or add a new insurance plan{' '}
            </Text.P>
          )}
          {currentInsurance.carrier ? (
            <>
              <AddSecondaryInsuranceCard />
              <CurrentInsuranceSection
                insuranceName={currentInsurance.carrier ?? ''}
              />
            </>
          ) : (
            <CashPaySection />
          )}
          {upcomingInsurance.carrier && (
            <UpcomingInsuranceSection
              insuranceName={upcomingInsurance.carrier ?? ''}
              effectiveDate={dayjs(upcomingInsurance.effective_date)}
            />
          )}
        </div>
      ) : (
        <ReadSection.Row
          Icon={Dollar}
          data={
            payment_method === 'Health Insurance'
              ? `Insurance${
                  currentInsurance.display_name || currentInsurance.carrier
                    ? `
               (${currentInsurance.display_name || currentInsurance.carrier})`
                    : ''
                }`
              : 'Cash pay'
          }
        />
      )}
    </ReadSection>
  );
};

const Edit = ({
  editState,
  carriers,
  patientData,
  pageState,
  currentInsurance,
  upcomingInsurance,
  fullWidth,
}: ExtractEditing<Data['insurance']> & {
  carriers: CarrierOption[];
  patientData: PatientData;
  pageState: ProfilePageState | InsurancePageState;
  currentInsurance: InsuranceDetails;
  upcomingInsurance: InsuranceDetails;
  fullWidth?: boolean;
}) => {
  const { isAllowed } = useFlagCheck();
  const mobile = useContext(MobileContext);
  const navigate = useNavigate();
  const effectiveDatesAllowed = isAllowed({
    patientPortalEffectiveDates: true,
  });
  const insurancePageEnabled = isAllowed({ patientPortalInsurancePage: true });
  const { dispatch } = useContext(StateContext);
  const [searchParams] = useSearchParams();

  const {
    payment_method,
    relationship_to_insured,
    subscriber,
    subscriber_id,
    carrier,
    front_of_card,
    back_of_card,
    sex_on_insurance_card,
    effective_date,
  } = editState.edited;
  const validation = useValidation(() =>
    dispatch(actions.profile.setInvalid({ key: 'insurance', invalid: false })),
  )(() => {
    const errors: ValidationError[] = [];
    if (!payment_method) {
      errors.push({
        keys: ['payment_method'],
        message: 'You must select a payment method',
      });
    }
    if (payment_method === 'Health Insurance' || effectiveDatesAllowed) {
      if (!sex_on_insurance_card) {
        errors.push({
          keys: ['sex_on_insurance_card'],
          message: 'You must select sex on your insurance card',
        });
      }
      if (!subscriber_id) {
        errors.push({
          keys: ['subscriber_id'],
          message: 'You must provide a subscriber ID',
        });
      }
      if (!carrier) {
        errors.push({
          keys: ['carrier'],
          message: 'You must select a carrier',
        });
      }
      if (!relationship_to_insured) {
        errors.push({
          keys: ['payment_method'],
          message: 'You must select your relationship to the insured',
        });
      }
      if (!front_of_card) {
        errors.push({
          keys: ['front_of_card'],
          message:
            'You must upload an image of the front of your insurance card',
        });
      }
      if (!back_of_card) {
        errors.push({
          keys: ['back_of_card'],
          message:
            'You must upload an image of the back of your insurance card',
        });
      }
      if (relationship_to_insured !== 'Self') {
        const missingFromSubscriber: string[] = (
          ['first_name', 'last_name', 'gender', 'birthdate'] as const
        ).filter((k) => !subscriber[k]);
        const missingFromSubscriberAddress: string[] = (
          ['street', 'city', 'state', 'zip'] as const
        ).filter((k) => !subscriber.address[k]);
        if (
          missingFromSubscriber.length ||
          missingFromSubscriberAddress.length
        ) {
          errors.push({
            keys: (missingFromSubscriber as string[]).concat(
              missingFromSubscriberAddress,
            ),
            message:
              'You must provide the requested information for the insured',
          });
        }
        if (
          !subscriber['birthdate'] ||
          tryFunc(
            () =>
              !parseMmDdYyyy(subscriber['birthdate']).isBefore(
                dayjs().subtract(16, 'years'),
              ),
            true,
          )
        ) {
          errors.push({
            keys: ['birthdate'],
            message: 'You must provide a valid birth date in mm/dd/yyyy format',
          });
        }
      }
      if (effectiveDatesAllowed) {
        if (
          !effective_date ||
          tryFunc(() => parseMmDdYyyy(effective_date) && false, true) // if date is in correct format, return false, if not parseMmDdYyyy throws an error and tryFunc would return true
        ) {
          errors.push({
            keys: ['effective_date'],
            message:
              'You must provide a valid coverage start date in mm/dd/yyyy format',
          });
        } else if (dayjs(effective_date).isAfter(dayjs().add(6, 'months'))) {
          errors.push({
            keys: ['effective_date'],
            message:
              'You must choose an effective date within the next 6 months',
          });
        } else if (
          dayjs(effective_date).isBefore(dayjs().subtract(3, 'years'))
        ) {
          errors.push({
            keys: ['effective_date'],
            message:
              'You must choose an effective date within the the last 3 years',
          });
        }
      }
    }
    return errors;
  }, [
    payment_method,
    relationship_to_insured,
    subscriber,
    subscriber_id,
    carrier,
    front_of_card,
    back_of_card,
    sex_on_insurance_card,
    effective_date,
    effectiveDatesAllowed,
  ]);

  const frontOfCard = useMemo(() => {
    if (front_of_card) {
      return toBase64(front_of_card);
    } else {
      void Promise.resolve();
    }
  }, [front_of_card]);

  const backOfCard = useMemo(() => {
    if (back_of_card) {
      return toBase64(back_of_card);
    } else {
      void Promise.resolve();
    }
  }, [back_of_card]);

  const [addModalOpen, setAddModalOpen] = useState(false);

  const onSave = ({
    replaceUpcoming,
    updatePayMethodOnly,
  }: {
    replaceUpcoming: boolean;
    updatePayMethodOnly: boolean;
  }) => {
    () => dispatch(actions.setToast(null)); // clear any existing toasts
    if (validation.length && !updatePayMethodOnly) {
      dispatch(actions.profile.setInvalid({ key: 'insurance', invalid: true }));
      return false;
    }
    dispatch(actions.profile.setInsurancePersistence('saving'));

    const newPaymentMethod =
      replaceUpcoming || updatePayMethodOnly
        ? payment_method
        : 'Health Insurance';

    const editingInsurance = {
      sex_on_insurance_card,
      carrier,
      subscriber_id,
      relationship_to_insured,
      subscriber:
        relationship_to_insured === 'Self'
          ? null
          : {
              ...subscriber,
              birthdate: parseMmDdYyyy(subscriber.birthdate).toISOString(),
            },
      effective_date: effective_date
        ? parseMmDdYyyy(effective_date).toISOString()
        : new Date().toISOString(),
    };

    return Promise.all([frontOfCard, backOfCard]).then(
      ([insurance_image_front_base64, insurance_image_back_base64]) => {
        const payload = setDefaults(
          {
            payment_method: newPaymentMethod,
            insurance:
              !replaceUpcoming && !updatePayMethodOnly
                ? editingInsurance
                : null,
            upcoming_insurance:
              replaceUpcoming && !updatePayMethodOnly ? editingInsurance : null,
          },
          '',
        ) as JSONValue;

        /* eslint-disable no-nested-ternary */
        const files = replaceUpcoming
          ? null
          : front_of_card && back_of_card
            ? {
                insurance_image_front_base64: insurance_image_front_base64!,
                insurance_image_back_base64: insurance_image_back_base64!,
                insurance_image_front_original_filename: front_of_card.name,
                insurance_image_back_original_filename: back_of_card.name,
              }
            : null;

        const upcoming_files = replaceUpcoming
          ? front_of_card && back_of_card
            ? {
                upcoming_insurance_image_front_base64:
                  insurance_image_front_base64!,
                upcoming_insurance_image_back_base64:
                  insurance_image_back_base64!,
                upcoming_insurance_image_front_original_filename:
                  front_of_card.name,
                upcoming_insurance_image_back_original_filename:
                  back_of_card.name,
              }
            : null
          : null;
        /* eslint-enable no-nested-ternary */

        return fetch
          .json('/api/update_insurance', {
            method: 'PUT',
            body: {
              patientIdentifiers: patientData,
              payload,
              files,
              upcoming_files,
            },
          })
          .then((r) => {
            setAddModalOpen(false);
            if (insurancePageEnabled) {
              dispatch(
                actions.async.setLoading({
                  key: 'insurance',
                  loadingState: 'needed',
                }),
              );
            }
            dispatch(actions.profile.setEditedPaymentMethod(newPaymentMethod));
            trackEvent({
              product_area: 'Profile',
              name: 'Payment_method_updated',
              trigger: 'Interaction',
              metadata: {
                newPaymentMethod,
                ...(effectiveDatesAllowed &&
                  !updatePayMethodOnly && {
                    effectiveDate: effective_date!,
                    effectiveAlready: dayjs().isSameOrAfter(
                      dayjs(effective_date),
                    ),
                  }),
              },
            });
            if (!effectiveDatesAllowed || updatePayMethodOnly) {
              dispatch(
                actions.profile.setInsurancePersisted({
                  network_name: r.payload.network_name,
                }),
              );
            } else {
              if (replaceUpcoming) {
                dispatch(actions.profile.setUpcomingInsurancePersisted());
              } else {
                dispatch(actions.profile.setCurrentInsurancePersisted());
              }
              dispatch(
                actions.setToast({
                  text: (
                    <Text.P>
                      <b>Success!</b> Your insurance has been added.
                    </Text.P>
                  ),
                  variant: 'success',
                  onClose: () => dispatch(actions.setToast(null)),
                }),
              );
            }
          })
          .catch(() => {
            dispatch(
              actions.setToast({
                text: (
                  <Text.P>
                    We're sorry, but that didn't work. Please try again.
                  </Text.P>
                ),
                variant: 'warning',
                onClose: () => dispatch(actions.setToast(null)),
              }),
            );
            setAddModalOpen(false);
            dispatch(actions.profile.setInsurancePersistence('error'));
          });
      },
    );
  };

  const openAddModal = () => {
    dispatch(actions.profile.setInvalid({ key: 'insurance', invalid: false }));
    setAddModalOpen(true);
  };

  const onRemoveUpcoming = () => {
    dispatch(
      actions.setModal({
        children: (
          <ConfirmModal
            dispatch={dispatch}
            onConfirm={async () => {
              try {
                dispatch(actions.profile.setInsurancePersistence('saving'));
                await fetch.json('/api/remove_upcoming_insurance', {
                  method: 'PUT',
                  body: {
                    patientIdentifiers: patientData,
                  },
                });
                dispatch(actions.profile.setUpcomingInsuranceRemoved());
                dispatch(actions.profile.setInsuranceEditMode('read'));
                dispatch(actions.profile.setUpcomingInsurancePersisted());
                dispatch(
                  actions.setToast({
                    text: (
                      <Text.P>
                        <b>Success!</b> Your insurance has been removed.
                      </Text.P>
                    ),
                    variant: 'success',
                    onClose: () => dispatch(actions.setToast(null)),
                  }),
                );
              } catch (e) {
                dispatch(
                  actions.setToast({
                    text: (
                      <Text.P>
                        We're sorry, but that didn't work. Please try again.
                      </Text.P>
                    ),
                    variant: 'warning',
                    onClose: () => dispatch(actions.setToast(null)),
                  }),
                );
                dispatch(actions.profile.setInsurancePersistence('error'));
              }
            }}
            title="Are you sure you want to remove this insurance?"
            description={`This insurance will be removed and no longer go into effect on ${dayjs(upcomingInsurance.effective_date).format('MM/DD/YYYY')}`}
          />
        ),
      }),
    );
  };

  const switchToSelfPay = () => {
    dispatch(
      actions.setModal({
        children: (
          <ConfirmModal
            dispatch={dispatch}
            onConfirm={() =>
              onSave({ replaceUpcoming: false, updatePayMethodOnly: true })
            }
            title="Are you sure you want to use self-pay?"
            description="Setting your payment method to cash pay means your insurance will no longer be used to cover your care, and you will be charged Rula's self-pay rate."
          />
        ),
      }),
    );
  };

  const handleDeeplinks = () => {
    const isDeeplink = !!searchParams.get('deeplink');
    const deeplinkAction = searchParams.get('action');
    if (isDeeplink && deeplinkAction === 'addinsurance') {
      openAddModal();

      // clear search params in case of deeplink
      navigate('?', { replace: true });
    }
  };

  useEffect(() => {
    handleDeeplinks();
  }, [searchParams]);

  return (
    <div id="edit-insurance">
      <EditSection
        validationErrors={
          effectiveDatesAllowed ? [] : validation.map(({ message }) => message)
        }
        onSave={() =>
          onSave({ replaceUpcoming: false, updatePayMethodOnly: false })
        }
        onCancel={() => dispatch(actions.profile.setInsuranceEditMode('read'))}
        label={effectiveDatesAllowed ? 'Insurance' : 'Medical billing'}
        invalid={effectiveDatesAllowed ? false : !!editState.invalid}
        saving={editState.persistence === 'saving'}
        showButtons={!effectiveDatesAllowed}
        fullWidth={fullWidth}
      >
        {effectiveDatesAllowed ? (
          <>
            <Text.P className="text-grey-800">
              Manage your existing coverage or add a new insurance plan
            </Text.P>
            {currentInsurance.carrier || upcomingInsurance.carrier ? (
              <AddSecondaryInsuranceCard />
            ) : null}
          </>
        ) : null}
        <Text.P className="mb-1">
          {!effectiveDatesAllowed
            ? 'Pay with insurance?'
            : 'Select your preferred payment method'}
        </Text.P>
        <div
          className={
            'flex flex-wrap gap-4 ' + (effectiveDatesAllowed ? '!mt-0' : '')
          }
        >
          <RadioButton
            label="Insurance"
            name="paymentMethod"
            selectedValue={payment_method}
            value="Health Insurance"
            onCheck={() => {
              if (payment_method === 'Self Pay' && effectiveDatesAllowed) {
                openAddModal();
                return;
              }
              dispatch(
                actions.profile.setEditedPaymentMethod('Health Insurance'),
              );
            }}
          />
          <RadioButton
            label={effectiveDatesAllowed ? 'Self-pay' : 'Cash pay'}
            name="paymentMethod"
            selectedValue={payment_method}
            value="Self Pay"
            onCheck={() =>
              dispatch(actions.profile.setEditedPaymentMethod('Self Pay'))
            }
          />
        </div>
        {effectiveDatesAllowed ? (
          <>
            {currentInsurance.carrier || upcomingInsurance.carrier ? (
              <>
                <hr />
                <Text.P className="mb-1">Insurance on file</Text.P>
              </>
            ) : null}
            {currentInsurance.carrier ? (
              <>
                <CurrentInsuranceSection
                  insuranceName={currentInsurance.carrier}
                  isEditing
                />
              </>
            ) : null}
            {upcomingInsurance.carrier ? (
              <UpcomingInsuranceSection
                insuranceName={upcomingInsurance.carrier ?? ''}
                effectiveDate={dayjs(upcomingInsurance.effective_date)}
                isEditing
                onRemove={onRemoveUpcoming}
              />
            ) : null}
          </>
        ) : null}
        {/* eslint-disable no-nested-ternary */}
        {effectiveDatesAllowed ? (
          <>
            {mobile ? (
              <Button
                variant="flat"
                onClick={openAddModal}
                sizeClasses="w-full"
              >
                + Add insurance
              </Button>
            ) : null}
            <div className="flex justify-end space-x-2">
              {!mobile ? (
                <>
                  <Button variant="primary-outline" onClick={openAddModal}>
                    + Add insurance
                  </Button>
                  <div className="flex-1"></div>
                </>
              ) : null}
              <Button
                onClick={() =>
                  dispatch(actions.profile.setInsuranceEditMode('read'))
                }
                variant={mobile ? 'primary-outline' : 'flat'}
                sizeClasses={mobile ? 'flex-1' : ''}
              >
                Cancel
              </Button>
              <Button
                onClick={switchToSelfPay}
                state={editState.persistence === 'saving' ? 'waiting' : ''}
                disabled={
                  !(
                    editState.edited.payment_method === 'Self Pay' &&
                    currentInsurance.carrier
                  )
                }
                sizeClasses={mobile ? 'flex-1' : ''}
                variant="primary"
              >
                Save
              </Button>
            </div>
          </>
        ) : payment_method === 'Health Insurance' ? (
          <InsuranceForm
            editState={editState}
            carriers={carriers}
            pageState={pageState}
            validation={validation}
          />
        ) : null}
        {/* eslint-enable no-nested-ternary */}
        <AddInsuranceModal
          carriers={carriers}
          pageState={pageState}
          validation={validation}
          saveInsurance={({ replaceUpcoming }) =>
            onSave({ replaceUpcoming, updatePayMethodOnly: false })
          }
          open={addModalOpen}
          onOpenChange={setAddModalOpen}
        />
      </EditSection>
    </div>
  );
};

const Insurance = ({
  insurance,
  upcomingInsurance,
  carriers,
  patientData,
  pageState,
  fullWidth,
}: InsuranceProps) => {
  const { isAllowed } = useFlagCheck();
  const effectiveDatesAllowed = isAllowed({
    patientPortalEffectiveDates: true,
  });
  const { dispatch } = useContext(StateContext);
  const [searchParams] = useSearchParams();
  const handleDeeplinks = () => {
    const isDeeplink = !!searchParams.get('deeplink');
    const deeplinkAction = searchParams.get('action');
    if (isDeeplink && deeplinkAction === 'addinsurance') {
      dispatch(
        actions.profile.setInsuranceEditMode('edit', effectiveDatesAllowed),
      );
    }
  };

  useEffect(() => {
    handleDeeplinks();
  }, [searchParams]);

  return (
    <div>
      {dayjs().isBefore('2024-01-01') ? (
        <div className="flex gap-x-4 rounded-t items-center max-w-[572px] p-6 bg-grey-400 text-white">
          <div>
            <InfoCircle size={24} stroke="white" />
          </div>
          <p>
            Updating your insurance for 2024? Please wait until January 1st,
            2024 to add your new plan to get the most accurate benefits
            information.
          </p>
        </div>
      ) : null}
      {isEditing(insurance) ? (
        <Edit
          {...insurance}
          currentInsurance={insurance}
          upcomingInsurance={upcomingInsurance}
          carriers={carriers}
          patientData={patientData}
          pageState={pageState}
          fullWidth={fullWidth}
        />
      ) : (
        <Read
          payment_method={insurance.payment_method}
          currentInsurance={insurance}
          upcomingInsurance={upcomingInsurance}
          fullWidth={fullWidth}
        />
      )}
    </div>
  );
};

export default Insurance;
