import InfoCircle from '@/components/icons/InfoCircle';

export const InProgressTemplate = () => {
  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-grey-800 flex items-center gap-2.5">
        <InfoCircle className="min-w-5" />
        Your insurance is being verified
      </div>
      <div className="flex gap-6 flex-col md:flex-row">
        <div className="bg-grey-100 rounded px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-grey-800">COST ESTIMATE</div>
          <hr />
          <div>
            <div className="text-sm text-grey-800">In progress...</div>
          </div>
        </div>
        <div className="text-grey-800 md:pl-9 md:pr-6">
          We're working on verifying your insurance and sharing your cost
          estimate. We'll email you the results as soon as the verification is
          complete.
        </div>
      </div>
    </div>
  );
};
