import { useEffect } from 'react';
import Button from './Button';
import CheckCircle from './icons/CheckCircle';
import InfoCircle from './icons/InfoCircle';
import BangCircle from './icons/BangCircle';
import X from './icons/X';

export interface ToastProps {
  actionText?: string;
  onAction?: () => unknown;
  text: JSX.Element;
  variant: 'info' | 'warning' | 'success';
  onClose: () => unknown;
  timeout?: number;
}

const Toast = ({
  text,
  actionText,
  variant,
  onClose,
  onAction,
  timeout = 10000,
}: ToastProps) => {
  useEffect(() => {
    setTimeout(onClose, timeout);
  }, []);

  return (
    <div className="max-w-[600px] min-h-20 w-full p-5 shadow-xl rounded flex justify-between items-center bg-white">
      <div className="flex gap-5 items-center">
        <span className="w-6 h-6">
          {variant === 'success' && <CheckCircle stroke="#38663a" />}
          {variant === 'warning' && <BangCircle />}
          {variant === 'info' && <InfoCircle />}
        </span>
        {text}
      </div>
      <div className="flex gap-5 items-center">
        {actionText && <Button onClick={onAction}>{actionText}</Button>}
        <X stroke="grey-800" className="cursor-pointer" onClick={onClose} />
      </div>
    </div>
  );
};

export default Toast;
