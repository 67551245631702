import Text from '@/components/Text';
import SupportLink from '../SupportLink';

const AdditionalAssistanceFooter = () => {
  return (
    <div className="my-2 text-grey-800">
      <Text.P>
        For additional assistance contact <SupportLink />
      </Text.P>
    </div>
  );
};

export default AdditionalAssistanceFooter;
