import InfoCircle from '@/components/icons/InfoCircle';
import { BillingEstimates } from '@/utils/types';

interface CashPayTemplateProps {
  billingEstimates: BillingEstimates;
}

export const CashPayTemplate = ({ billingEstimates }: CashPayTemplateProps) => {
  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-grey-800 flex items-center gap-2.5">
        <InfoCircle className="min-w-5" />
        No insurance on file
      </div>
      <div className="flex gap-6 flex-col md:flex-row">
        <div className="bg-grey-100 rounded px-9 py-6 space-y-4 min-w-[328px]">
          <div className="text-xs text-grey-800">COST ESTIMATE</div>
          <div>
            <div className="text-sm text-grey-800">Cash pay</div>
            <div className="text-sky-50 text-2xl font-medium">
              ${billingEstimates.pre_deductible_estimate}
            </div>
          </div>
        </div>
        <div className="text-grey-800 md:pl-9 md:pr-6">
          You can add your insurance above, and our team will update your cost
          estimate if your plan is in-network.
        </div>
      </div>
    </div>
  );
};
