import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import Text from '@/components/Text';
import { providerLicenses } from '@/utils/constants';
import {
  CareTeamPageState,
  ProviderWithFirstAppointmentInfo,
  TherapyType,
} from '@/utils/types';
import ProviderCardActions from './ProviderCardActions';
import { useContext } from 'react';
import { MobileContext } from '@/App';
import useData from '@/state/use-data';
import ProviderUnavailableNotice from '@/components/ProviderUnavailableNotice';
import { getProviderUnavailableMessage } from '@/utils/tools';

interface Props {
  pageState: CareTeamPageState;
  provider: ProviderWithFirstAppointmentInfo;
  active: boolean;
  therapyType: TherapyType;
  canBook?: boolean;
}
export function ProviderLockupV2({
  provider,
  active,
  therapyType,
  canBook = false,
}: Props) {
  const mobile = useContext(MobileContext);
  const providerLicense = providerLicenses[provider.licenses[0]]?.name;
  const { WithData, data } = useData(['profile']);

  const ProviderDetails = () => {
    const NameComponent = mobile ? Text.P : Text.H3;
    const LicenseComponent = mobile ? Text.Small : Text.P;

    return (
      <div
        className={`flex flex-col flex-grow justify-center overflow-hidden md:items-start`}
      >
        <NameComponent className="overflow-hidden text-ellipsis line-clamp-1">
          {provider.first_name} {provider.last_name}
        </NameComponent>
        {providerLicense && (
          <LicenseComponent
            className="
              text-ellipsis text-grey-500
              overflow-hidden whitespace-nowrap
              md:whitespace-normal
            "
          >
            {providerLicense}
          </LicenseComponent>
        )}
      </div>
    );
  };

  return (
    <div
      className="
        flex flex-col gap-3
        md:flex-row md:items-center md:gap-1"
    >
      <div
        className="
          flex items-center
          flex-1 h-10
          gap-x-4
          md:h-20"
      >
        <ProviderHeadshot
          profileImage={provider.profile_image_s3_url}
          className="aspect-square h-10 md:h-20"
        />
        <ProviderDetails />
        <div className="ml-4 md:ml-[unset]">
          <ProviderCardActions
            provider={provider}
            therapyType={therapyType}
            active={active}
            canBook={canBook}
          />
        </div>
      </div>
      {!active ? (
        <WithData data={data}>
          {({ profile }) => {
            const { location } = profile;
            if (
              provider.status !== 'live' ||
              provider.two_letter_state !== location
            ) {
              return (
                <ProviderUnavailableNotice
                  message={getProviderUnavailableMessage(provider, location)}
                ></ProviderUnavailableNotice>
              );
            } else {
              return null;
            }
          }}
        </WithData>
      ) : null}
    </div>
  );
}
