import { useCallback, useEffect, useState } from 'react';

export interface InfoCardProps {
  children: JSX.IntrinsicElements['div']['children'];
}

const InfoCard = ({ children }: InfoCardProps) => {
  const [left, setLeft] = useState<number>(0);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateInnerWidth = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateInnerWidth);
  });

  const measuredRef = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        setLeft(node.getBoundingClientRect().left);
      }
    },
    [innerWidth],
  );

  const maxWidth = innerWidth - left - 24;

  return (
    <div
      className="p-4 rounded-sm border-1 border-grey-200 bg-white shadow-lg"
      style={{
        maxWidth,
      }}
      ref={measuredRef}
    >
      {children}
    </div>
  );
};

export default InfoCard;
