import CheckCircle from '@/components/icons/CheckCircle';
import { BillingEstimates } from '@/utils/types';

interface UnknownCostTemplateProps {
  billingEstimates: BillingEstimates;
}

export const UnknownCostTemplate = ({
  billingEstimates,
}: UnknownCostTemplateProps) => {
  const isCopayCoinsuranceOnly =
    billingEstimates.billing_estimate_template === 'copay_and_coinsurance';

  return (
    <div className="space-y-4">
      <div className="font-medium text-lg text-grey-800 flex items-center gap-2.5">
        <CheckCircle stroke="sky-50" />
        <div className="flex-1 md:flex">
          <div className="flex-1">Rula accepts your insurance!</div>
          <div className="text-sm font-normal text-grey-800">
            Last checked {billingEstimates.last_therapy_vob_completed_at}
          </div>
        </div>
      </div>
      <div className="flex gap-6 flex-col md:flex-row">
        {billingEstimates.visit_limit && (
          <div className="bg-sky-20 rounded px-9 py-6 space-y-4 min-w-[328px]">
            <div className="text-xs text-grey-800">COST ESTIMATE</div>
            <div>
              <div className="text-sm text-grey-800">Visit limit</div>
              <div
                className="text-sky-50 text-2xl font-medium"
                data-testid="visit-limit"
              >
                {Number(billingEstimates.visit_limit).toFixed()}
              </div>
            </div>
          </div>
        )}
        <div
          className={
            'text-grey-800' +
            (billingEstimates.visit_limit ? ' md:pl-9 md:pr-6' : '')
          }
        >
          <p>
            {isCopayCoinsuranceOnly && (
              <>
                You do not have an annual deductible, so your per appointment
                costs will reflect your plan's copay and coinsurance.
                <br />
                <br />
                To verify your per appointment out of pocket costs, please
                contact your insurance company. You can also{' '}
                <a
                  className="text-sky-50 no-underline"
                  href="https://patientsupport.rula.com/hc/en-us/sections/27330239424411-Your-health-insurance-pre-visit"
                >
                  check out our insurance FAQ
                </a>
              </>
            )}
            {!isCopayCoinsuranceOnly && billingEstimates.visit_limit && (
              <>
                Once you have met your annual deductible, your per appointment
                costs will reflect your plan's copay and coinsurance. To verify
                your per appointment out of pocket costs once your deductible
                has been met, please contact your insurance company.
                <br />
                <br />
                <b className="mb-2 font-medium text-grey-800">
                  What your benefits mean
                </b>
                <br />A deductible is a specified annual amount you must pay out
                of pocket, before your insurance company will pay a claim. Once
                you've reached that amount of out of pocket healthcare expenses,
                your per appointment costs will reflect your plan's copay and
                coinsurance. To verify whether your deductible has been met,
                please contact your insurance company.
                <br />
                <br />
                <b className="mb-2 font-medium text-grey-800">
                  This breakdown is just an estimate
                </b>
                <br />
                This estimate is based on the information you provided to Rula.
                If it isn't what you expect, contact your insurer for
                clarification and{' '}
                <a
                  className="text-sky-50-underline"
                  href="https://patientsupport.rula.com/hc/en-us/sections/27330239424411-Your-health-insurance-pre-visit"
                >
                  check out our insurance FAQ
                </a>
                .
              </>
            )}
            {!isCopayCoinsuranceOnly && !billingEstimates.visit_limit && (
              <>
                Once you have met your ${billingEstimates.annual_deductible}{' '}
                annual deductible, your per appointment costs will reflect your
                plan's copay and coinsurance.
                <br />
                <br />
                <b className="mb-2 font-medium text-grey-800">
                  What your benefits mean
                </b>
                <br />A deductible is a specified annual amount you must pay out
                of pocket, before your insurance company will pay a claim. Once
                you've reached that amount of out of pocket healthcare expenses,
                your per appointment costs will reflect your plan's copay and
                coinsurance. Before your deductible is met, we estimate that
                each therapy session is approximately $
                {billingEstimates.pre_deductible_estimate} but may differ based
                on your insurance. To verify whether your deductible has been
                met, please contact your insurance company.
                <br />
                <br />
                <b className="mb-2 font-medium text-grey-800">
                  This breakdown is just an estimate
                </b>
                <br />
                This estimate is based on the information you provided to Rula.
                If it isn't what you expect, contact your insurer for
                clarification and{' '}
                <a
                  className="text-sky-50-underline"
                  href="https://patientsupport.rula.com/hc/en-us/sections/27330239424411-Your-health-insurance-pre-visit"
                >
                  check out our insurance FAQ
                </a>
                .
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
