import { IconProps } from './icons/Icon';
import Text from './Text';

interface NavItemProps {
  label: string;
  Icon: (p: IconProps) => React.ReactElement;
  active: boolean;
  onClick: () => unknown;
  badge?: React.ReactNode;
}

const NavItem = ({ label, Icon, active, onClick, badge }: NavItemProps) => {
  return (
    <li
      className={`${
        active ? 'text-sky-60 bg-sky-10' : 'text-grey-800'
      } mx-2 cursor-pointer flex items-center h-10 rounded`}
      onClick={onClick}
    >
      <Icon className="mr-5 ml-4" stroke={active ? 'sky-60' : 'grey-800'} />
      <Text.P.Bold>{label}</Text.P.Bold>
      {badge ? (
        <div aria-label="badge" className="ml-auto mr-2">
          {badge}
        </div>
      ) : null}
    </li>
  );
};

export default NavItem;
