import { useState } from 'react';
import Text from '../components/Text';
import dayjs from 'dayjs';
import HiddenEye from './icons/HiddenEye';
import ShownEye from './icons/ShownEye';
import useData from '@/state/use-data';
import fetch from '@/utils/fetch';

interface ChargeRowProps {
  payment: {
    date_of_payment: string;
    payment_amount: string;
    external_id: number;
  };
}

const ChargeRow = ({ payment }: ChargeRowProps) => {
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const { WithData, data } = useData(['patientData']);

  return (
    <WithData data={data}>
      {({ patientData }) => {
        const handleEyeClick = async (payment_id: number) => {
          try {
            if (!isTextVisible) {
              const response = await fetch.json(
                '/api/payment_detail?' +
                  new URLSearchParams({
                    payment_external_id: `${payment_id}`,
                    patient_record_uuid: patientData.patient_record_uuid,
                  }),
                { method: 'GET' },
              );
              setDisplayText(response.payload.last_4_digits[payment_id]);
              setIsTextVisible(true);
            } else {
              setIsTextVisible(false);
            }
          } catch (error) {
            console.error('Error fetching payment detail:', error);
          }
        };

        return (
          <div
            className="pt-4 pb-4 w-full flex justify-between border-b-1 border-grey-200"
            key={payment.external_id}
          >
            <div className="w-full flex justify-between items-center flex-wrap-reverse md:flex-nowrap gap-y-1 md:gap-y-0">
              <Text.P className="md:flex-1 md:min-w-max min-w-full">
                {dayjs(payment.date_of_payment).format('MM/DD/YYYY')}
              </Text.P>
              <Text.P className="md:flex-1 font-semibold md:font-normal md:min-w-max min-w-full">
                {payment.payment_amount}
              </Text.P>
            </div>
            <div className="w-1/2 flex items-center">
              <div
                className="cursor-pointer"
                onClick={() => handleEyeClick(payment.external_id)}
              >
                <div>
                  <Text.P.Inline>
                    {isTextVisible ? (
                      <>
                        {' '}
                        {displayText ? (
                          <>Card ending in {displayText} </>
                        ) : (
                          <>Other </>
                        )}{' '}
                        <ShownEye className="inline-block"></ShownEye>
                      </>
                    ) : (
                      <>
                        {' '}
                        <HiddenEye className="inline-block"></HiddenEye>
                      </>
                    )}
                  </Text.P.Inline>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </WithData>
  );
};

export default ChargeRow;
